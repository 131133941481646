import AutoCorrect from './forms/AutoCorrect';
import { Validations } from './forms';
import { GenericFilter } from './tables';

const ComputedColumn = {
  name: (row) => [row.firstname, row.initials, row.surname_prefix, row.surname].join(' '),
  address: (row) => [row.street, [row.house_number, row.house_number_suffix].join('')].join(' ')
};

export const sex = {
  title: 'Geslacht',
  options: {
    M: 'Man',
    V: 'Vrouw',
    O: 'Anders'
  },
  initial: 'O'
};

export const initials = {
  title: 'Voorletters',
  validation: Validations.nameInitials.required(),
  autocorrect: AutoCorrect.upperCaseDots,
  initial: ''
};

export const surname = {
  title: 'Achternaam',
  validation: Validations.name.required(),
  autocorrect: AutoCorrect.firstUppercase,
  initial: '',
  column: { title: 'Naam', filter: GenericFilter, sortable: true },
  value: ComputedColumn.name
};

export const surname_prefix = {
  title: 'Tussenvoegsel',
  validation: Validations.namePrefix,
  autocorrect: AutoCorrect.lowerCase,
  initial: ''
};

export const place = {
  title: 'Woonplaats',
  initial: '',
  validation: Validations.addressCity.required()
};

export const house_number = {
  initial: '',
  validation: Validations.number.required()
};

export const house_number_suffix = {
  initial: '',
  validation: Validations.addressSuffix
};

export const street = {
  validation: Validations.addressStreet.required(),
  column: { title: 'Adres' },
  value: ComputedColumn.address,
  initial: ''
};
