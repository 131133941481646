import React, { Component } from 'react';
import { Form } from 'formik';
import definitions, { ValidationSchema } from './DonatorDefinitions';
import {
  TextField,
  SelectField,
  CheckField,
  DateField,
  IBANField,
  AddressFields,
  FormActions,
  TextAreaField,
  EmailField,
  ResourceField
} from '../../shared/forms';
import StateSelectField from './StateSelectField';
import { setDonatorValidationSchema } from './DonatorValidatedFormView';
import PhoneNumberField from '../../shared/forms/PhoneNumberField';

class DonatorFormLayout extends Component {
  constructor(props) {
    super(props);

    if (this.isExported()) {
      this.props.setStatus({ type: 'warning', text: 'Deze donateur is geëxporteerd.' });
    }
  }

  updateValidationSchema() {
    const isPending = this.props.values.state === 'PENDING';
    if (isPending) {
      setDonatorValidationSchema(
        this.props.update ? ValidationSchema.update : ValidationSchema.create
      );
    } else {
      setDonatorValidationSchema(ValidationSchema.none);
    }
  }

  isExported() {
    return this.props.update && this.props.update.last_export_date;
  }

  setRejectStateOptions() {
    if (this.isExported()) {
      definitions.reject_state.options = definitions.reject_state.options_exported;
    } else {
      definitions.reject_state.options = definitions.reject_state.options_default;
    }
  }

  render() {
    this.updateValidationSchema();
    this.setRejectStateOptions();

    return (
      <Form className="form clearfix pt-3" autoComplete="off">
        {this.props.status && (
          <div className={`alert alert-${this.props.status.type}`}>{this.props.status.text}</div>
        )}

        <div className="form-row">
          <TextField
            className="col-sm-2"
            autoFocus
            name="reference_number"
            definitions={definitions}
            props={this.props}
          />
          <StateSelectField className="col-sm-3" props={this.props} />
          {this.renderRejectStateField()}
          <SelectField
            {...this.props}
            className="col-sm-2"
            name="checked"
            definitions={definitions}
          />
          <SelectField
            {...this.props}
            className="col-sm-2"
            name="call_attempts"
            definitions={definitions}
          />
        </div>

        {this.renderRejectCommentField()}

        <div className="form-row">
          <TextField
            className="col-sm-3"
            name="firstname"
            definitions={definitions}
            props={this.props}
          />
          <TextField
            className="col-sm-2"
            name="initials"
            definitions={definitions}
            props={this.props}
          />
          <SelectField {...this.props} className="col-sm-2" name="sex" definitions={definitions} />
          <TextField
            className="col-sm-2"
            name="surname_prefix"
            definitions={definitions}
            props={this.props}
          />
          <TextField
            className="col-sm-3"
            name="surname"
            definitions={definitions}
            props={this.props}
          />
        </div>

        <hr />
        <AddressFields {...this.props} />

        <div className="form-row">
          <TextField className="col-sm-3" name="tel" definitions={definitions} props={this.props} />
          <PhoneNumberField
            className="col-sm-3"
            name="mobile"
            definitions={definitions}
            {...this.props}
          />
          <EmailField className="col-sm-6" name="email" definitions={definitions} {...this.props} />
        </div>

        <hr />

        <div className="form-row">
          <TextField
            className="col-sm-2"
            name="amount"
            definitions={definitions}
            props={this.props}
          >
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fa fa-eur" /></span>
            </div>
          </TextField>
          <SelectField
            {...this.props}
            className="col-sm-3"
            name="period"
            definitions={definitions}
          />
          <IBANField {...this.props} className="col-sm-5" bicFieldName="bic" />
          <TextField className="col-sm-2" name="bic" definitions={definitions} props={this.props} disabled />
        </div>

        <div className="form-row">
          <DateField className="col-sm-2" name="birth_date" title="Geboortedatum" {...this.props} />
          <DateField className="col-sm-2" name="start_date" title="Startdatum" {...this.props} />
          {this.props.update && (
            <DateField className="col-sm-2" name="stop_date" title="Stopdatum" {...this.props} />
          )}
          <ResourceField
            {...this.props}
            className="col-sm-4"
            name="recruiter"
            definitions={definitions}
          />
          <TextField
            className="col-sm-2"
            name="recruiter_number"
            disabled
            definitions={definitions}
            props={this.props}
          />
        </div>

        <div className="form-row">
          <TextAreaField
            className="col-sm-12"
            rows="2"
            props={this.props}
            name="comment"
            title="Opmerking"
          />
        </div>
        <div className="form-row">
          <CheckField className="col-sm-2" name="receive_mail" title="Post ontvangen" props={this.props} />
          <CheckField className="col-sm-2" name="receive_email" title="E-mail ontvangen" props={this.props} />
        </div>
        <hr />

        <div className="form-row">
          <SelectField {...this.props} className="col-sm-3" name="language" definitions={definitions} />
          {this.renderCampaignFields()}
        </div>
        <hr />

        <FormActions {...this.props} touched={this.props.touched.reference_number} />
      </Form>
    );
  }

  renderRejectCommentField() {
    if (this.props.values.state !== 'REJECTED') {
      return;
    }
    return (
      <TextAreaField
        rows="2"
        props={this.props}
        name="reject_comment"
        title="Afgekeurd opmerking"
      />
    );
  }

  renderRejectStateField() {
    if (this.props.values.state !== 'REJECTED') {
      return;
    }
    return (
      <SelectField
        className="col-sm-2"
        {...this.props}
        definitions={definitions}
        name="reject_state"
      />
    );
  }

  renderCampaignFields() {
    if (!this.props.campaign || this.props.campaign.input_fields.length === 0) {
      return;
    }

    return this.props.campaign.input_fields.map((field) => {
      let options = field.required !== true ? [''] : [];
      options = options.concat(field.options);

      const definitions = {
        [field.name]: {
          title: field.label || field.name,
          options: this.arrayOptionsToKeyValueOptions(options)
        }
      };
      return (
        <SelectField
          {...this.props}
          className="col-sm-3"
          key={field.name}
          name={field.name}
          definitions={definitions}
        />
      );
    });
  }

  arrayOptionsToKeyValueOptions(arrayOptions) {
    let object = {};
    for (var option of arrayOptions) {
      object[option] = option;
    }
    return object;
  }
}

export default DonatorFormLayout;
