import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import Environment from '../shared/Environment';

function parseToken(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const GoogleLoginButton = ({ onSuccess, onFailure }) => (<GoogleLogin
  shape='rectangular'
  theme='filled_blue'
  text='continue_with'
  width='100%'
  size='large'
  hosted_domain={Environment.hosted_domain}
  onSuccess={({ credential }) => {
    const { name, email } = parseToken(credential);
    onSuccess({
      credential,
      name,
      email
    });
  }}
  onError={onFailure}
/>);