import React, { Component } from 'react';
import { connect } from 'react-redux';
import SettingsDefinitions from './form/SettingsDefinitions';
import GridPageView from '../shared/GridPageView';
import { selectAction } from '../store/resources';

class SettingsPageView extends Component {
  render() {
    return (
      <GridPageView
        {...this.props}
        definitions={SettingsDefinitions}
        resourceName="settings"
        resourceIdentifier="key"
        multipleName="Instellingen"
        singleName="Instelling"
        hideCreate
        onClick={(row) => {
          this.props.select(row);
          this.props.history.push(row.type + '/' + row.key + '/');
        }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  select: (resource) => dispatch(selectAction('settings', resource))
});

export default connect(null, mapDispatchToProps)(SettingsPageView);
