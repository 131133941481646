import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingView from '../shared/LoadingView';
import { fetchSelectAction } from '../store/resources';
import { Link } from 'react-router-dom';
import DonatorDefinitions, { isLocked } from './form/DonatorDefinitions';
import DescriptionListView from '../shared/DescriptionListView';
import AccessControl from '../shared/user/AccessControl';
import CallButton from '../shared/CallButton';
import Restrict from '../shared/user/Restrict';
import CallsView from '../calls/CallsView';
import DonatorSignatureView from './DonatorSignatureView';

const descriptionListMain = [
  'start_date', 'amount', 'period', 'recruiter',
  'call_attempts', 'checked', 'signature', 'comment'
];
const descriptionListRejected = ['reject_state', 'stop_date', 'reject_comment'];
const descriptionListPersonal = [
  'surname', 'sex', 'birth_date', 'street', 'postal_code', 'country', 
  'language', 'iban', 'bic', 'mobile', 'phone', 'email', 'receive_mail', 'receive_email'
];
const descriptionRecruitingInfo = [
  'start_date', 'stop_date', 'creation_date', 'last_modify_date', 
  'last_export_date', 'backstage_export_date', 'source', 'recruiter_number'
];

class DonationDetailpageView extends Component {
  accessControl = new AccessControl();

  componentDidMount() {
    this.props.fetch(this.props.match.params.donationId);
  }

  onStateChange() {
    this.props.fetch(this.props.match.params.donationId);
  }

  renderList(names) {
    const props = {
      names,
      className: 'mb-0',
      titleClassName: 'col-sm-4',
      descriptionClassName: 'col-sm-8',
      definitions: DonatorDefinitions,
      entity: this.props.donation
    };
    return <DescriptionListView {...props}/>;
  }

  render() {
    return this.props.donation === null ? <LoadingView /> : this._render();
  }

  _render() {
    const { donation } = this.props;
    const { client, campaign, reference_number } = donation;
    const [ stateIcon, stateName ] = DonatorDefinitions.state.options[donation.state];

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Donateur details</div>
          <div className="ml-auto">
            {isLocked(donation) ?
              <button className="btn btn-warning"><i className="fa fa-lock mr-1"/> Beveiligd</button>
            : <>
              <CallButton prefix="don:" to={this.props.donation.id} />
              <Link to={`update/`} className="btn btn-primary ml-2">
                <i className="fa fa-pencil mr-1" /> Wijzigen
              </Link> 
            </>}
          </div>
        </nav>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-sm-7">

              <h4 className="mb-3">{reference_number} {client.name} {campaign.name}</h4>
              {donation.reject_state && this.renderList(descriptionListRejected)}
              {this.renderList(descriptionListMain)}

              <hr className="my-3" />
              <h4 className="mb-3">Persoonlijke gegevens</h4>
              {this.renderList(descriptionListPersonal)}

              <hr className="my-3" />
              <h4 className="mb-3">Werving informatie</h4>
              {this.renderList(descriptionRecruitingInfo)}

            </div>
            <div className="col-sm-5">
              <h4 className="mb-3">Status</h4>
              <div className="card">
                <div className="card-body text-center">
                  <h5 className="my-3"><i className={`fa fa-${stateIcon} mr-1`}></i> {stateName}</h5>
                </div>
              </div>
            
              <h4 className="mb-3 mt-4">Gesprekshistorie</h4>
              <CallsView callee="donation" identifier={donation.id} />

              <Restrict to="ROLE_ADMIN">
                <h4 className="mb-3 mt-4">Handtekening</h4>
                <div className="card">
                  <div className="card-body text-center text-muted">
                    <DonatorSignatureView signature={donation.signature}/>              
                  </div>
                </div>
              </Restrict>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  donation: state.donations.selected
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => {
    dispatch(fetchSelectAction('donations', id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DonationDetailpageView);
