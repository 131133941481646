import fetcher from './fetcher';

const actions = (resourceName) => {
  const prefix = resourceName.toUpperCase() + '_';
  return {
    results: prefix + 'RESULTS',
    select: prefix + 'SELECT',
    create: prefix + 'CREATE',
    progress: prefix + 'PROGRESS',
    extend: prefix + 'EXTEND',
    end: prefix + 'END',
    filter: prefix + 'FILTER',
    reset: prefix + 'RESET',
    clear: prefix + 'CLEAR'
  };
};

const emptyState = {
  results: null,
  count: null,
  offset: 0,
  selected: null,
  progress: false,
  filters: {},
  end: false
};

const defaultLimit = 50;

const requestPathModifiers = {};
const requestFilterModifiers = {};

export function addRequestPathModifier(resourceName, modifier) {
  requestPathModifiers[resourceName] = modifier;
}
export function addRequestFilterModifier(resourceName, modifier) {
  requestFilterModifiers[resourceName] = modifier;
}

export const getPath = (resourceName, filters, limit, offset = 0) => {
  const computedFilters = requestFilterModifiers[resourceName]
    ? requestFilterModifiers[resourceName](filters)
    : filters;
  const computedPath = requestPathModifiers[resourceName]
    ? requestPathModifiers[resourceName](resourceName)
    : resourceName;
  const params = { limit, offset, ...computedFilters };

  return (
    computedPath +
    '?' +
    Object.keys(params)
      .filter((k) => params[k] !== undefined && params[k] !== '')
      .map((k) => {
        if (typeof params[k] === 'object') {
          return params[k]
            .map((value) => {
              return encodeURIComponent(k) + '[]=' + encodeURIComponent(value);
            })
            .join('&');
        } else {
          return encodeURIComponent(k) + '=' + encodeURIComponent(params[k]);
        }
      })
      .join('&')
  );
};

export const filterAction = (resourceName, filters, limit = defaultLimit) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions(resourceName).filter, filters });
    return fetchAction(resourceName, limit)(dispatch, getState);
  };
};

export const selectAction = (resourceName, resource) => {
  return { type: actions(resourceName).select, resource };
};

export const clearAction = (resourceName) => {
  return { type: actions(resourceName).clear };
};

export const resetAction = (resourceName) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions(resourceName).reset });
    return fetchAction(resourceName)(dispatch, getState);
  };
};

export const extendAction = (resourceName, limit = defaultLimit) => {
  return async (dispatch, getState) => {
    const state = getState()[resourceName];
    if (state.progress || state.end) {
      return;
    }

    dispatch({ type: actions(resourceName).progress });

    return fetcher(getPath(resourceName, state.filters, limit, state.offset), (response) => {
      if (response.results.length > 0) {
        return { type: actions(resourceName).extend, response: response };
      } else {
        return { type: actions(resourceName).end, end: true };
      }
    })(dispatch, getState);
  };
};

export const fetchAction = (resourceName, limit = defaultLimit) => {
  return async (dispatch, getState) => {
    const state = getState()[resourceName];
    return fetcher(getPath(resourceName, state.filters, limit), (response) => {
      return { type: actions(resourceName).results, response: response };
    })(dispatch, getState);
  };
};

export const fetchSelectAction = (resourceName, id) => {
  const defaultPath = resourceName + '/' + id;
  const computedPath = requestPathModifiers[resourceName]
    ? requestPathModifiers[resourceName](defaultPath)
    : defaultPath;
  return fetcher(computedPath, (response) => {
    return selectAction(resourceName, response);
  });
};

function filterEmptyFields(object) {
  return Object.keys(object)
    .filter((key) => object[key])
    .reduce((newObject, key) => {
      newObject[key] = object[key];
      return newObject;
    }, {});
}

export default (resourceName, initialState) => {
  const computedInitialState = { ...emptyState, ...initialState };
  return (state = computedInitialState, action) => {
    const actionTypes = actions(resourceName);
    switch (action.type) {
      case actionTypes.results:
        return {
          ...state,
          results: action.response.results,
          count: action.response.count,
          offset: action.response.results.length,
          end: action.response.count === 0
        };
      case actionTypes.extend:
        return {
          ...state,
          results: state.results.concat(action.response.results),
          count: action.response.count,
          offset: state.offset + action.response.results.length,
          progress: false
        };
      case actionTypes.select:
        const newState = { ...state, selected: action.resource };
        if (action.resource && action.resource.uuid && newState.results) {
          const selectedIndex = newState.results
            .map((result) => result.uuid)
            .indexOf(newState.selected.uuid);
          newState.results[selectedIndex] = action.resource;
        }
        return newState;
      case actionTypes.progress:
        return { ...state, progress: true };
      case actionTypes.end:
        return { ...state, progress: false, end: action.end };
      case actionTypes.filter:
        return { ...state, filters: filterEmptyFields({ ...state.filters, ...action.filters }) };
      case actionTypes.reset:
        return { ...state, filters: {} };
      case actionTypes.clear:
        return computedInitialState;
      default:
        return state;
    }
  };
};
