import securityYaml from './securityYaml';
import store from '../../store';

const forceArray = (object) => (Array.isArray(object) ? object : [object]);
const allMethods = ['OPTIONS', 'GET', 'POST', 'PUT', 'PATCH', 'DELETE'];

export default class AccessControl {
  constructor(yaml = null) {
    this.yaml = yaml || securityYaml;
  }

  getAllowedMethodsPerRole(path) {
    let accessControls = this.yaml.access_control.filter((item) => item.path === '^/api/' + path);
    if (accessControls.length === 0 || !accessControls[0].roles) {
      accessControls = [{ roles: 'ROLE_ADMIN' }];
    }

    let requiredRoles = {};
    for (let accessControl of accessControls) {
      for (let role of forceArray(accessControl.roles)) {
        if (!requiredRoles[role]) {
          requiredRoles[role] = new Set();
        }
        const methods = accessControl.methods || allMethods;
        for (let method of methods) {
          requiredRoles[role].add(method);
        }
      }
    }
    return requiredRoles;
  }

  hasAccess(path, methods = ['GET', 'POST', 'PUT'], roles) {
    if (!roles) {
      roles = store.getState().user && store.getState().user.roles;
    }

    if (!roles) {
      return false;
    }

    const allRoles = this.getAllRoles(roles);
    const allowedMethodsPerRole = this.getAllowedMethodsPerRole(path);
    const allowedMethods = allRoles.reduce((allowedMethods, role) => {
      (allowedMethodsPerRole[role] || []).forEach((method) => allowedMethods.add(method));
      return allowedMethods;
    }, new Set());

    for (let method of forceArray(methods)) {
      if (!allowedMethods.has(method)) {
        return false;
      }
    }

    return true;
  }

  getAllRoles(roles) {
    roles = forceArray(roles);
    var resolved = roles;
    for (let role of roles) {
      const subroles = this.yaml.role_hierarchy[role];
      if (subroles) {
        resolved = resolved.concat(this.getAllRoles(subroles));
      }
    }

    return resolved;
  }

  getAllRolesCurrentUser() {
    return this.getAllRoles(store.getState().user.roles);
  }

  hasAllRoles(requiredRoles) {
    const allRoles = this.getAllRoles(store.getState().user.roles);
    return requiredRoles.reduce((hasAllRoles, requiredRole) => {
      return hasAllRoles && allRoles.indexOf(requiredRole) >= 0;
    }, true);
  }

  hasRole(requiredRole) {
    const allRoles = this.getAllRoles(store.getState().user.roles);
    return allRoles.indexOf(requiredRole) >= 0;
  }
}
