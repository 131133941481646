import { userClearAction } from './user';
import Environment from '../shared/Environment';

export default (path, handler) => {
  return async (dispatch, getState) => {
    const options = {
      method: 'GET',
      headers: { 'Authorization-Token': getState().user.token }
    };

    const request = new Request(Environment.API + path, options);

    return fetch(request)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 401) {
          dispatch(userClearAction());
          alert('Access denied');
        } else {
          throw Error(response.statusText);
        }
      })
      .then((response) => dispatch(handler(response)))
      .catch((error) => {
        console.log(error);
      });
  };
};
