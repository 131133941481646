import React, { Component } from 'react';
import { Field } from 'formik';
import ValidationMessage from '../ValidationMessage';

export default class SelectField extends Component {
  renderOptions() {
    const options = this.props.options || this.props.definitions[this.props.name].options || [];
    return Object.keys(options).map((key) => (
      <option key={key} value={key}>
        {options[key]}
      </option>
    ));
  }

  getValue() {
    const value = this.props.values[this.props.name];
    return Array.isArray(value)
      ? value[0]
      : value;
  }

  render() {
    const inputClassName =
      this.props.errors[this.props.name] && this.props.touched[this.props.name] && 'is-invalid';
    const error = <ValidationMessage>{this.props.errors[this.props.name]}</ValidationMessage>;

    return (
      <div className={`form-group ${this.props.className}`}>
        <label htmlFor={this.props.name}>{this.props.definitions[this.props.name].title}</label>
        <Field
          autoFocus={this.props.autoFocus}
          name={this.props.name}
          id={this.props.name}
          onBlur={this.props.handleBlur}
          onChange={this.props.handleChange}
          value={this.getValue()}
          disabled={this.props.disabled}
          component="select"
          className={'form-control ' + inputClassName}
        >
          {this.renderOptions()}
        </Field>
        {this.props.touched[this.props.name] && this.props.errors[this.props.name] && error}
      </div>
    );
  }
}
