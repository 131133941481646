import EmployeesDefinitions from './EmployeesDefinitions';
import { Validations } from '../../shared/forms/Validations';
import Yup from 'yup';

export const validationSchema = Yup.object().shape(
  Object.keys(EmployeesDefinitions).reduce((result, field) => {
    if (EmployeesDefinitions[field].validation) {
      result[field] = EmployeesDefinitions[field].validation;
    }
    return result;
  }, {})
);

export const newValidationSchema = Yup.object().shape({
  mobile: Validations.phone.required()
});
