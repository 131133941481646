import React, { Component } from 'react';
import { Form } from 'formik';
import { TextAreaField, FormActions } from '../../shared/forms';
import SettingsDefinitions from './SettingsDefinitions';

export default class SettingsFormLayout extends Component {
  render() {
    const definitions = SettingsDefinitions;
    const title = 'Waarde (' + this.props.values.value.length + ')';

    return (
      <Form className="form" autoComplete="off">
        {this.props.status && (
          <div className={`alert alert-${this.props.status.type}`}>{this.props.status.text}</div>
        )}

        <TextAreaField name="value" title={title} definitions={definitions} props={this.props} />

        <FormActions {...this.props} touched={true} />
      </Form>
    );
  }
}
