import React, { Component } from 'react';
import InlinePreviewModalView from './InlinePreviewModalView';
import InlineFormButton from './InlineFormButton';
import InlineFormInput from './InlineFormInput';
import InlineFormFileInput from './InlineFormFileInput';

export default class InlineForm extends Component {
  constructor(props) {
    super(props);

    this.className = 'form-group ' + (props.className || 'col-sm-12');
    this.type = props.type || 'text';
    this.inputComponent = this.type === 'file' ? InlineFormFileInput : InlineFormInput;

    this.state = {
      value: (props.values && props.values[props.name]) || '',
      isLoading: false,
      isEnabled: false
    };
  }

  onChange = (event) => {
    if (this.state.isLoading) {
      return;
    }
    const value = event.target.value;
    this.setState({ value, isEnabled: value !== '' });
  };

  onSubmit = (event) => {
    event.preventDefault();

    if (this.state.isLoading) {
      return;
    }

    this.setLoading(true);
    this.props
      .onSubmit(this.props.name, this.type, this.state.value)
      .then(() => this.setLoading(false))
      .catch((error) => {
        console.error(error);
        this.setLoading(false);
      });
  };

  setLoading(isLoading) {
    this.setState({ isLoading, isEnabled: isLoading });
  }

  render() {
    const Input = this.inputComponent;
    const { value, isLoading, isEnabled } = this.state;
    const { title, name, large } = this.props;

    return (
      <form className={this.className} onSubmit={this.onSubmit}>
        <label htmlFor={name}>{title}</label>
        <div className="input-group">
          <InlinePreviewModalView type={this.type} title={title} value={value} />
          <Input
            value={value}
            name={name}
            type={this.type}
            onChange={this.onChange}
            large={large}
          />
          <InlineFormButton onClick={this.onSubmit} isLoading={isLoading} isEnabled={isEnabled} />
        </div>
      </form>
    );
  }
}
