import store from '../../store';
import Environment from '../Environment';

export default class API {
  submit(path, method, body) {
    return this.fetch(path, method, JSON.stringify(body));
  }

  fetch(path, method = 'GET', body = undefined, contentType = 'application/json') {
    const options = {
      method,
      body,
      redirect: 'follow',
      headers: {}
    };

    if (contentType) {
      options.headers['Content-Type'] = contentType;
    }

    const user = store.getState().user;
    if (user) {
      options.headers['Authorization-Token'] = user.token;
    }

    const request = new Request(Environment.API + path, options);

    return fetch(request);
  }
}
