import React, { Component } from 'react';
import { TextField } from '.';

export default class AddressFields extends Component {
  apiKey = 'ArNLiGNuxa56FOgULWXxj9AlvQD68MHw9ho4RiVu';

  definitions = {
    country: {
      title: 'Land',
      placeholder: 'XX',
      autocorrect: this.correctCountry.bind(this)
    },
    postal_code: {
      title: 'Postcode',
      placeholder: '0000XX',
      autocorrect: this.correctPostalCode.bind(this)
    },
    house_number: {
      title: 'Huisnummer',
      autocorrect: this.attemptAutoCompletion.bind(this)
    },
    house_number_suffix: {
      title: 'Toev.'
    },
    street: {
      title: 'Straatnaam',
      autocorrect: this.correctFirstUpperCase
    },
    place: {
      title: 'Woonplaats',
      autocorrect: this.correctFirstUpperCase
    }
  };

  indicators = {
    waiting: { color: 'secondary', icon: 'ellipsis-h', text: 'Wachten op invoer' },
    progress: { color: 'secondary', icon: 'circle-o-notch fa-spin', text: 'Ophalen..' },
    invalid: { color: 'danger', icon: 'times', text: 'Onbekend' },
    valid: { color: 'success', icon: 'check-circle-o', text: 'Aangevuld' }
  };

  state = {
    indicator: this.indicators.waiting,
    isEnabled: true,
    isPossible: true
  };

  setValues(street, place) {
    this.props.setFieldValue('street', street);
    this.props.setFieldValue('place', place);
  }

  attemptAutoCompletion(value) {
    this.performAutoCompletion(this.props.values.postal_code, this.props.values.house_number);
    return value;
  }

  onChangeEnabled(event) {
    const isEnabled = event.target.checked;
    this.setState((state) => ({ ...state, isEnabled }));
    this.attemptAutoCompletion();
  }

  correctPostalCode(value) {
    this.attemptAutoCompletion();
    return value.toUpperCase().replace(/ /g, '');
  }

  correctCountry(value) {
    const newValue = value.toUpperCase();
    const isEnabled = newValue === 'NL';
    this.setState((state) => ({ ...state, isEnabled, isPossible: isEnabled }));
    if (isEnabled) {
      this.attemptAutoCompletion();
    }
    return newValue;
  }

  correctFirstUpperCase(value) {
    return value.substring(0, 1).toUpperCase() + value.substring(1);
  }

  performAutoCompletion(rawZip, number) {
    const zip = rawZip.replace(/ /g, '');

    if (!this.state.isEnabled || zip.length !== 6 || number * 1 < 1) {
      return;
    }

    this.setIndicator(this.indicators.progress);

    const request = this.getRequest(zip, number);
    fetch(request)
      .then((response) =>
        response.json().then((data) => {
          const address = data._embedded.addresses[0];
          if (!address) {
            this.setIndicator(this.indicators.invalid);
            this.setValues('', '');
          } else {
            this.setIndicator(this.indicators.valid);
            this.setValues(address.street, address.city.label);
          }
        })
      )
      .catch((error) => {
        this.setIndicator(this.indicators.invalid);
        this.setValues('', '');
      });
  }

  getRequest(zip, number) {
    const query = '?postcode=' + encodeURIComponent(zip) + '&number=' + encodeURIComponent(number);

    return new Request('https://postcode-api.apiwise.nl/v2/addresses' + query, {
      method: 'GET',
      headers: { 'X-Api-Key': this.apiKey }
    });
  }

  setIndicator(indicator) {
    this.setState((state) => ({ indicator: indicator }));
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <label className="custom-control custom-checkbox custom-control-inline">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={this.state.isEnabled}
              disabled={!this.state.isPossible}
              onChange={this.onChangeEnabled.bind(this)}
            />
            <span className="custom-control-label">Adres automatisch aanvullen</span>
          </label>
        </div>
        <div className="form-row">
          <TextField
            disabled={this.props.countryDisabled}
            className="col-sm-1"
            props={this.props}
            name="country"
            definitions={this.definitions}
          />
          <TextField
            className="col-sm-2"
            props={this.props}
            name="postal_code"
            title="Postcode"
            definitions={this.definitions}
          />
          <TextField
            className="col-sm-2"
            props={this.props}
            name="house_number"
            title="Huisnummer"
            definitions={this.definitions}
          />
          <TextField
            className="col-sm-1"
            props={this.props}
            name="house_number_suffix"
            definitions={this.definitions}
          />
          <TextField
            className="col-sm-3"
            props={this.props}
            name="street"
            disabled={this.state.isEnabled}
            definitions={this.definitions}
          >
            {this.renderIndicator()}
          </TextField>
          <TextField
            className="col-sm-3"
            props={this.props}
            name="place"
            disabled={this.state.isEnabled}
            definitions={this.definitions}
          />
        </div>
      </div>
    );
  }

  renderIndicator() {
    if (!this.state.isEnabled) {
      return;
    }

    return (
      <div className="input-group-prepend">
        <span className="input-group-text">
          <i
            title={this.state.indicator.text}
            className={`fa fa-${this.state.indicator.icon} text-${this.state.indicator.color}`}
          />
        </span>
      </div>
    );
  }
}
