import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'formik';
import Yup from 'yup';

import createForm, { TextField, CheckField } from '../../shared/forms';
import {TextAreaField} from "../../shared/forms/TextAreaField";

const resourceName = 'campaign';

export default createForm({
  resourceName,

  redirectPath: (props) => `clients/${props.match.params.clientId}`,
  submitPath: (props) => `clients/${props.match.params.clientId}/campaigns`,
  updatePath: (props) =>
    `clients/${props.match.params.clientId}/campaigns/${props.match.params.campaignId}`,

  mapPropsToValues: (props) => {
    const map = props.update || {
      name: '',
      backstage_reference: '',
      enabled: true
    };

    delete map.export_fields;
    delete map.client;
    return map;
  },

  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required()
      .min(3, 'Minimaal 3 karakters')
      .max(100, 'Maximaal 100 karakters')
  }),

  form: (props) => {
    const submit = props.update ? 'Opslaan' : 'Toevoegen';
    return (
      <Form className="form">
        {props.status && (
          <div className={`alert alert-${props.status.type}`}>{props.status.text}</div>
        )}

        <TextField autoFocus props={props} name="name" title="Naam" />
        <TextField props={props} name="backstage_reference" title="Backstage project (slug)"
          definitions={{backstage_reference: {autocorrect: (v) => v.toLowerCase()}}}
        />
        <CheckField props={props} name="enabled" title="Actief" />
        <CheckField props={props} name="available_for_tablet" title="Tablet werving" />

        <TextAreaField
          rows="4"
          props={props}
          name="email_text"
          title="E-mail tekst"
        />

        <TextField props={props} name="address_link" title="Adres link" />

        <button className="btn btn-primary" disabled={props.isSubmitting || !props.isValid}>
          {submit}
        </button>
        <Link
          to={`/clients/${props.match.params.clientId}/`}
          className="btn btn-secondary float-right"
        >
          Annuleren
        </Link>
      </Form>
    );
  }
});
