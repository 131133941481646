import React from 'react';
import { connect } from 'react-redux';
import CallButton from '../shared/CallButton';

const ApplicantsCallButton = ({ selected }) => {
  const uuid = selected && selected.uuid;
  return <CallButton prefix="emp:" to={uuid} />;
};

const mapStateToProps = (state) => ({
  selected: state.applicants.selected
});

export default connect(mapStateToProps, null)(ApplicantsCallButton);
