import React, { Component } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import API from './API';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import ValidationMessage from '../ValidationMessage';

export default class ResourceField extends Component {
  static cache = {};

  defaultSelected = [];
  state = {
    isLoading: false,
    hasInputText: false,
    options: []
  };

  constructor(props) {
    super(props);

    this.definition = props.definitions[props.name];

    if (!ResourceField.cache[this.props.name]) {
      ResourceField.cache[this.props.name] = {};
    }

    const updateValue = this.props.update && this.props.update[this.props.name];
    if (updateValue) {
      this.defaultSelected = [
        {
          identifier: updateValue.uid,
          name: this.definition.resource.value(updateValue)
        }
      ];
    }
  }

  onSearch(query) {
    this.setState({ isLoading: true });
    const params = '?q=' + encodeURIComponent(query) + '&' + this.definition.resource.query;
    new API()
      .fetch('autocomplete/' + this.definition.resource.name + '.json' + params, 'GET')
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          isLoading: false,
          options: this.getOptions(data)
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          options: []
        });
      });
  }

  componentDidUpdate() {
    if (!this.state.hasInputText && this.props.values[this.props.name] === '') {
      this.typeahead.getInstance().clear();
    }
  }

  onChange(selected) {
    let value = '';
    if (selected.length === 1) {
      value = selected[0].identifier;
      ResourceField.cache[this.props.name][value] = selected[0].name;
    }
    this.props.setFieldValue(this.props.name, value);
    this.setState({
      hasInputText: false
    });
  }

  onInputChange(text) {
    this.setState({
      hasInputText: true
    });
  }

  onBlur(event) {
    const value = this.props.values[this.props.name];
    if (value === '' || event.target.value === '') {
      this.typeahead.getInstance().clear();
    }
  }

  getOptions(data) {
    return data.map((resource) => ({
      identifier: resource[this.definition.resource.identifier],
      name: this.definition.resource.value(resource)
    }));
  }

  render() {
    const error = <ValidationMessage>{this.props.errors[this.props.name]}</ValidationMessage>;

    return (
      <div className={`form-group dropdown ${this.props.className}`}>
        <label htmlFor={this.props.name}>{this.definition.title}</label>
        {this.renderInput()}
        {this.props.touched[this.props.name] && this.props.errors[this.props.name] && error}
      </div>
    );
  }

  renderInput() {
    return (
      <AsyncTypeahead
        {...this.state}
        defaultSelected={this.defaultSelected}
        id={this.definition.resource.name}
        className={
          this.props.errors[this.props.name] && this.props.touched[this.props.name] && 'is-invalid'
        }
        autoFocus={this.props.autoFocus}
        filterBy={(option) => true}
        ref={(ref) => (this.typeahead = ref)}
        onSearch={this.onSearch.bind(this)}
        onChange={this.onChange.bind(this)}
        onInputChange={this.onInputChange.bind(this)}
        onBlur={this.onBlur.bind(this)}
        selectHintOnEnter
        labelKey="name"
        allowNew={false}
        onFocus={(event) => event.target.select()}
        minLength={2}
        emptyLabel="Geen resultaten"
        promptText="Typ om te zoeken"
        searchText="Zoeken..."
        placeholder="Zoeken..."
      />
    );
  }
}
