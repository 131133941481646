import React from 'react';
import { Form } from 'formik';
import { TextField, SelectField, DateField, IBANField } from '../../shared/forms';
import ChecksDefinitions from './ChecksDefinitions';

let referenceNumberTextField = null;

export const focusChecksForm = () => referenceNumberTextField.focus();

export default (props) => {
  const definitions = ChecksDefinitions;
  const isSubmitDisabled = !props.touched || props.isSubmitting;
  const submitClassName = !isSubmitDisabled && props.isValid ? 'btn-success' : 'btn-primary';

  return (
    <Form className="form form-static" autoComplete="off">
      {props.status && (
        <div className={`alert alert-${props.status.type}`}>{props.status.text}</div>
      )}

      <div className="form-row">
        <TextField
          autoFocus
          className="col-6 col-lg-4 col-xl-2"
          name="reference_number"
          definitions={definitions}
          props={props}
          reference={(e) => (referenceNumberTextField = e)}
        />
        <DateField
          className="col-6 col-lg-4 col-xl-2"
          name="birth_date"
          title="Geb. datum"
          {...props}
        />
        <TextField
          className="col-6 col-lg-4 col-xl-2"
          name="amount"
          definitions={definitions}
          props={props}
        >
          <div className="input-group-prepend">
            <span className="input-group-text"><i className="fa fa-eur" /></span>
          </div>
        </TextField>
        <SelectField
          {...props}
          className="col-6 col-lg-4 col-xl-2"
          name="period"
          definitions={definitions}
        />
        <IBANField {...props} className="col-10 col-lg-6 col-xl-3" />
        <div className="col-2 col-xl-1 inline-button">
          <button className={'btn ' + submitClassName} disabled={isSubmitDisabled}>
            <i className="fa fa-plus" />
          </button>
        </div>
      </div>
    </Form>
  );
};
