import environment from "../shared/Environment";

export const employeeStateIndicatorClassName = {
  New: 'fa fa-certificate',
  'M&G planned': 'fa fa-handshake-o',
  'M&G canceled': 'fa fa-handshake-o',
  'Trial planned': 'fa fa-comment',
  'Trial canceled': 'fa fa-comment',
  Active: 'fa fa-play',
  Pause: 'fa fa-pause',
  Exit: 'fa fa-times',
  FormerEmployee: 'fa fa-stop',
  GA_TRIAL_PERIOD: 'fa fa-compass'
};

export const employeeStateColorName = {
  New: 'warning',
  'M&G planned': 'secondary',
  'M&G canceled': 'secondary',
  'Trial planned': 'secondary',
  'Trial canceled': 'secondary',
  Active: 'success',
  Pause: 'warning',
  Exit: 'danger',
  FormerEmployee: 'danger',
  GA_TRIAL_PERIOD: 'secondary'
};

export const employeeStateReasonOptions = {
  '': '',
  TOO_BUSY: 'Te druk',
  TOO_YOUNG: 'Te jong',
  ANOTHER_JOB: 'Andere baan',
  EXAMS: 'Examens',
  MOVE: 'Verhuizen',
  PARENTS: 'Ouders',
  INVALID_PHONE_NUMBER: 'Verkeerd telefoonnummer',
  CANCELED: 'Afgezegd',
  UNWILLING: 'Wil niet',
  NO_ANSWER: 'Neemt niet op',
  NO_SHOW: 'Niet op komen dagen',
  NOT_FUN: 'Niet leuk',
  BLACKLIST: 'Blacklist',
  BAD_WRITER: 'Schrijft slecht',
  NON_EU: 'Non-EU',
  CALL_BACK_LATER: 'Later terugbellen',
  NO_VACANCIES: 'Geen vacatures beschikbaar',
};

export { employeeTimeFormat } from './EmployeesConstants';
export { employeeDateFormat } from './EmployeesConstants';
export { employeeDateTimeFormat } from './EmployeesConstants';

export const employeeStateOptions = {
  ...environment.EMPLOYEE_STATES,
  ...environment.APPLICANT_STATES
};

export const employeeApplicantStateOptions = environment.APPLICANT_STATES;
