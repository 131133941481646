export const preprocessAmount = (amount) => {
  if (typeof amount === 'number') {
    return parseFloat(amount / 100)
      .toFixed(2)
      .replace('.', ',');
  } else {
    return '';
  }
};

export const postprocessAmount = (amount) => {
  if (amount === '') {
    return null;
  } else {
    return amount.replace(',', '') * 1;
  }
};
