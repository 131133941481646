import React, { Component } from 'react';
import GridPageView from '../shared/GridPageView';
import LayoutsDefinitions from './form/LayoutsDefinitions';
import { addRequestFilterModifier, addRequestPathModifier } from '../store/resources';

addRequestFilterModifier('layouts', (params) => ({ ...params, return_fields: 1 }));
addRequestPathModifier('layouts', (path) => {
  const isCollection = path.indexOf('/') === -1;
  return !isCollection
    ? path.replace(/layouts\/([0-9]+)/, 'exports/$1/layout?return_fields=1')
    : 'exports/layouts';
});

export default class ShiftsPageView extends Component {
  render() {
    return (
      <GridPageView
        {...this.props}
        definitions={LayoutsDefinitions}
        resourceName="layouts"
        resourceIdentifier="id"
        multipleName="Export layouts"
        singleName="Export layout"
      />
    );
  }
}
