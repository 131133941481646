import React, { Component } from 'react';
import HippoValidation from './HippoValidation';
import ValidationMessage from '../ValidationMessage';

export default class EmailField extends Component {
  hippo = new HippoValidation();

  indicators = {
    waiting: { color: 'secondary', icon: 'ellipsis-h', text: 'Wachten op invoer' },
    progress: { color: 'secondary', icon: 'circle-o-notch fa-spin', text: 'Valideren...' },

    [HippoValidation.BAD]: { color: 'danger', icon: 'times', text: 'Hippo: Onmogelijk' },
    [HippoValidation.UNVERIFIED]: {
      color: 'warning',
      icon: 'warning',
      text: 'Hippo: Ongevalideerd'
    },
    [HippoValidation.OK]: { color: 'success', icon: 'check-circle-o', text: 'Hippo: Gevalideerd' }
  };

  state = {
    indicator: this.indicators.waiting
  };

  constructor(props) {
    super(props);

    this.definition = props.definitions[props.name] || {};
  }

  componentWillMount() {
    const value = this.props.values[this.props.name];
    if (value !== '') {
      this.attemptHippoValidation(value);
    }
  }

  setIndicator(indicator) {
    if (!indicator) {
      console.log('EmailField cannot determine indicator');
      indicator = this.indicators[HippoValidation.BAD];
    }
    this.setState({ indicator: indicator });
  }

  handleBlur(event) {
    this.attemptHippoValidation(event.target.value);
    this.props.handleBlur(event);
  }

  attemptHippoValidation(email) {
    if (email === '') {
      this.setIndicator(this.indicators.waiting);
      this.hippo.clear();
      return;
    }
    this.setIndicator(this.indicators.progress);
    this.hippo
      .validate(email)
      .then((result) => {
        this.setIndicator(this.indicators[result]);
      })
      .catch((error) => {
        this.setIndicator(this.indicators[HippoValidation.UNVERIFIED]);
        console.warn(error);
      });
  }

  renderError() {
    var error = [];

    if (this.props.touched[this.props.name] && this.props.errors[this.props.name]) {
      error.push(this.props.errors[this.props.name]);
    }

    if (this.hippo.lastReason) {
      error.push(this.hippo.lastReason);
    }

    if (error.length > 0) {
      return <ValidationMessage>{error.join(' - ')}</ValidationMessage>;
    }
  }

  render() {
    const inputClassName =
      this.props.errors[this.props.name] && this.props.touched[this.props.name] && 'is-invalid';

    return (
      <div className={`form-group ${this.props.className}`}>
        <label htmlFor={this.props.name}>{this.definition.title}</label>

        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i
                title={this.state.indicator.text}
                className={`fa fa-${this.state.indicator.icon} text-${this.state.indicator.color}`}
              />
            </span>
          </div>
          <input
            name={this.props.name}
            id={this.props.name}
            type="text"
            placeholder={this.definition.placeholder}
            onFocus={(event) => event.target.select()}
            onChange={this.props.handleChange}
            onBlur={this.handleBlur.bind(this)}
            value={this.props.values[this.props.name] || ''}
            className={'form-control ' + inputClassName}
          />
        </div>
        {this.renderError()}
      </div>
    );
  }
}
