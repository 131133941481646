import Yup from 'yup';

export default {
  key: {
    title: 'Naam',
    column: true
  },
  type: {
    title: 'Type',
    column: true
  },
  value: {
    title: 'Waarde',
    initial: '',
    column: true,
    validation: Yup.string().required(),
    value: (row) => {
      if (row.value.length > 100) {
        return row.value.substr(0, 97) + '...';
      } else {
        return row.value;
      }
    }
  }
};
