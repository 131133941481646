export default {
  firstUppercase: (value) => value.substring(0, 1).toUpperCase() + value.substring(1),
  upperCaseDots: (value) =>
    value.length > 0
      ? value
          .toUpperCase()
          .split('')
          .filter((c) => c !== '.')
          .join('.') + '.'
      : '',
  lowerCase: (value) => value.toLowerCase(),
  noSpace: (value) => value.replace(/ /g, ''),
  numeric: (value) => value.replace(/[^0-9]/g, ''),
  phone: (value) => value.replace(/[^+0-9]/g, ''),
  currencyComma: (value) =>
    parseFloat(Math.round(value.replace(',', '.') * 100) / 100)
      .toFixed(2)
      .replace('.', ',')
};
