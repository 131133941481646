import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userClearAction } from '../store/user';
import environment from '../shared/Environment';

class ProfilePageView extends Component {
  render() {
    return (
      <Fragment>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Profiel</div>
          <Link to="/profile/bug-report/" className="btn btn-primary ml-auto mr-2">
            <i className="fa fa-bug mr-1" /> Rapporteren
          </Link>
          <button onClick={(e) => this.props.clearUser()} type="submit" className="btn btn-danger">
            Uitloggen
          </button>
        </nav>
        <div className="grid-container">
          {environment.PROFILE_URL && <iframe src={environment.PROFILE_URL} title="Dashboard" />}
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearUser: () => dispatch(userClearAction())
});

export default connect(null, mapDispatchToProps)(ProfilePageView);
