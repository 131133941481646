import Yup from 'yup';

export default {
  new_password: {
    title: 'Wachtwoord',
    validation: Yup.string()
      .min(6, 'Minimaal 6 tekens.')
      .required()
  }
  // confirm_password: {
  //   title: "Wachtwoord bevestigen",
  //   validation: Yup.string().required()
  // }
};
