import React, { Component } from 'react';
import ClientForm from './ClientForm';

class ClientsCreatePageView extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Klant toevoegen</div>
        </nav>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
              <div className="card">
                <div className="card-body">
                  <ClientForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientsCreatePageView;
