export default {
  role_hierarchy: {
    ROLE_ADMIN: ['ROLE_SUPER_ADMINISTRATION', 'ROLE_SUPER_MANAGER', 'ROLE_CAPTAIN'],
    ROLE_SUPER_MANAGER: 'ROLE_MANAGER',
    ROLE_SUPER_ADMINISTRATION: 'ROLE_ADMINISTRATION',
    ROLE_MANAGER: ['ROLE_COORDINATOR', 'ROLE_RECRUITER'],
    ROLE_ADMINISTRATION: 'ROLE_EMPLOYEE',
    ROLE_COORDINATOR: 'ROLE_CAPTAIN',
    ROLE_CAPTAIN: 'ROLE_EMPLOYEE',
    ROLE_RECRUITER: 'ROLE_EMPLOYEE',
    ROLE_EMPLOYEE: 'ROLE_APPLICANT',
    ROLE_APPLICANT: 'ROLE_USER',
    ROLE_USER: 'ROLE_ANONYMOUS'
  },
  access_control: [
    { path: '^/', methods: ['OPTIONS'], roles: 'ROLE_ANONYMOUS' },
    { path: '^/$', roles: 'ROLE_ANONYMOUS' },
    { path: '^/api/$', roles: 'ROLE_ANONYMOUS' },
    { path: '^/api/public', roles: 'ROLE_ANONYMOUS' },
    { path: '^/api/doc/default', roles: 'ROLE_DOC' },
    { path: '^/api/authenticate', roles: 'ROLE_ANONYMOUS' },
    { path: '^/api/notify.json', roles: 'ROLE_ANONYMOUS' },
    { path: '^/api/logout.json', roles: 'ROLE_USER' },
    { path: '^/api/user.json', roles: 'ROLE_USER' },
    {
      path: '^/api/clients/(\\d+)/campaigns/(\\d+)/settings',
      roles: ['ROLE_ADMIN'],
      methods: ['POST']
    },
    {
      path: '^/api/clients',
      methods: ['OPTIONS', 'GET'],
      roles: ['ROLE_USER']
    },
    { path: '^/api/clients', roles: 'ROLE_SUPER_ADMINISTRATION' },
    {
      path: '^/api/export_donations',
      roles: 'ROLE_SUPER_ADMINISTRATION'
    },
    {
      path: '^/api/export-donations-backstage',
      roles: 'ROLE_ADMIN'
    },
    { path: '^/api/export', roles: 'ROLE_SUPER_ADMINISTRATION' },
    { path: '^/api/donations/.*/anomyzes', roles: 'ROLE_ADMIN' },
    {
      path: '^/api/donations',
      roles: ['ROLE_EMPLOYEE'],
      methods: ['POST', 'PUT']
    },
    {
      path: '^/api/donations',
      roles: ['ROLE_MANAGER', 'ROLE_ADMINISTRATION'],
      methods: ['GET']
    },
    {
      path: '^/api/donations',
      roles: 'ROLE_ADMINISTRATION',
      methods: ['GET', 'POST', 'PUT', 'PATCH']
    },
    {
      path: '^/api/employees/(.*)/password',
      roles: 'ROLE_MANAGER',
      methods: ['PATCH']
    },
    {
      path: '^/api/employees/',
      roles: 'ROLE_ADMIN',
      methods: ['DELETE']
    },
    { path: '^/api/employees/(.*)/anomyze', roles: 'ROLE_ADMIN' },
    {
      path: '^/api/employees',
      roles: ['ROLE_MANAGER', 'ROLE_RECRUITER'],
      methods: ['POST', 'GET', 'PUT']
    },
    {
      path: '^/api/calllogs',
      roles: ['ROLE_MANAGER', 'ROLE_ADMINISTRATION', 'ROLE_RECRUITER'],
      methods: ['GET']
    },
    { 
      path: '^/api/notifications', 
      roles: ['ROLE_MANAGER','ROLE_RECRUITER'] 
    },
    {
      path: '^/api/shifts',
      roles: ['ROLE_CAPTAIN', 'ROLE_ADMINISTRATION']
    },
    {
      path: '^/api/donationcheck',
      roles: ['ROLE_CAPTAIN', 'ROLE_ADMINISTRATION']
    },
    {
      path: '^/api/static/roles-table.html',
      roles: 'ROLE_SUPER_MANAGER'
    },
    { path: '^/api/autocomplete', roles: 'ROLE_USER' },
    {
      path: '^/api/upload/signature',
      roles: 'ROLE_ADMIN',
      methods: ['GET']
    },
    { path: '^/api/upload/signature', roles: 'ROLE_USER' },
    {
      path: '^/api/upload/static',
      roles: ['ROLE_ANONYMOUS'],
      methods: ['GET']
    },
    { path: '^/api/messagebird/proxy', roles: 'ROLE_USER' },
    {
      path: '^/api/twilio/dail_event/.*',
      roles: 'ROLE_ANONYMOUS',
      methods: ['POST']
    },
    { path: '^/api/twilio', roles: 'ROLE_EMPLOYEE' },
    { path: '^/api/dump-donations', roles: 'ROLE_ADMIN' },
    { path: '^/api/update-donations', roles: 'ROLE_ADMIN' },
    { path: '^/', roles: 'ROLE_ADMIN' }
  ]
};
