import React from 'react';
import ValidationMessage from '../ValidationMessage';

export const CheckField = ({ title, name, props, onChange, disabled, className = '' }) => {
  const error = <ValidationMessage>{props.errors[name]}</ValidationMessage>;

  const handleChange = (event) => {
    props.handleChange(event);
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <div className={`form-group ${className}`}>
      <label className="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          name={name}
          className="custom-control-input"
          checked={props.values[name]}
          disabled={disabled}
          onChange={handleChange}
          onBlur={props.handleBlur}
        />
        <span className="custom-control-label">{title}</span>
      </label>
      {props.touched[name] && props.errors[name] && error}
    </div>
  );
};
