import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import Yup from 'yup';
import { filterAction } from '../../store/resources';
import { DateField, Validations } from '../../shared/forms';
import { RadioField } from '../../shared/forms/RadioField';
import Sortable from '../../shared/tables/Sortable';

class _DateFilter extends Component {
  constructor(props) {
    super(props);
    this.prefix = props.prefix || props.name;
  }

  getConfiguration() {
    if (this.configuration === undefined) {
      this.configuration = {
        render: this.renderForm.bind(this),
        onSubmit: this.onSubmit.bind(this),
        validationSchema: Yup.object().shape({
          gte: Validations.date,
          lte: Validations.date
        }),
        initialValues: {
          gte: this.props.values[this.prefix + '_gte'] || '',
          lte: this.props.values[this.prefix + '_lte'] || '',
          flag: this.props.values[this.props.flag] || ''
        },
        isInitialValid: true
      };
    }
    return this.configuration;
  }

  onClear(event) {
    event.preventDefault();
    this.props.filter({
      [this.prefix + '_gte']: undefined,
      [this.prefix + '_lte']: undefined,
      [this.props.flag]: undefined
    });
    this.props.onSubmit(event);
  }

  onSubmit(values) {
    const isUnFlagged = values.flag === 'false';
    this.props.filter({
      [this.prefix + '_gte']: isUnFlagged ? undefined : values.gte,
      [this.prefix + '_lte']: isUnFlagged ? undefined : values.lte,
      [this.props.flag]: values.flag
    });
    this.props.onSubmit();
  }

  renderForm(formProps) {
    const disabled = this.props.flag !== undefined && formProps.values.flag !== 'true';
    return (
      <Form autoComplete="off" className="form">
        <Sortable {...this.props} />

        {this.props.flag && (
          <RadioField name="flag" title={this.props.flagTitle} props={formProps} />
        )}

        <DateField
          {...formProps}
          disabled={disabled}
          autoFocus={!this.props.flag}
          name="gte"
          title="Vanaf"
        />
        <DateField {...formProps} disabled={disabled} name="lte" title="Tot en met" />

        <button className="btn btn-primary" disabled={formProps.isSubmitting || !formProps.isValid}>
          <i className="fa fa-check" />
        </button>
        <button className="btn btn-secondary float-right" onClick={this.onClear.bind(this)}>
          <i className="fa fa-trash" />
        </button>
      </Form>
    );
  }

  render() {
    return <Formik {...this.getConfiguration()} />;
  }
}

const mapStateToProps = (state, props) => ({
  values: state[props.resourceName].filters
});

const mapDispatchToProps = (dispatch, props) => ({
  filter: (filters) => {
    dispatch(filterAction(props.resourceName, filters));
  }
});

const DateFilter = connect(mapStateToProps, mapDispatchToProps)(_DateFilter);
export default DateFilter;

export const StartDateFilter = (props) => (
  <DateFilter prefix="start_date" {...props} />
);

export const StopDateFilter = (props) => (
  <DateFilter flag="stopped" flagTitle="Gestopt" prefix="stop_date" {...props} />
);

export const ExportDateFilter = (props) => (
  <DateFilter flag="exported" flagTitle="Geëxporteerd" prefix="export_date" {...props} />
);

export const BackstageExportFilter = (props) => (
  <DateFilter flag="backstage_exported" flagTitle="Geëxporteerd" prefix="backstage_export_date" {...props} />
);