import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingView from '../shared/LoadingView';
import { fetchSelectAction } from '../store/resources';
import { Link } from 'react-router-dom';
import EmployeesDefinitions from './form/EmployeesDefinitions';
import DescriptionListView from '../shared/DescriptionListView';
import AccessControl from '../shared/user/AccessControl';
import EmployeesStateHistoryView from './transition/EmployeesStateHistoryView';
import EmployeesNotificationsView from './transition/EmployeesNotificationsView';
import EmployeesPasswordButton from './password/EmployeesPasswordButton';
import CallButton from '../shared/CallButton';
import CallsView from '../calls/CallsView';
import QuickLinks from './QuickLinks';
import Restrict from '../shared/user/Restrict';

class EmployeesDetailPageView extends Component {
  accessControl = new AccessControl();

  componentDidMount() {
    this.props.fetch(this.props.match.params.employeeId);
  }

  onStateChange() {
    this.props.fetch(this.props.match.params.employeeId);
  }

  _render() {
    const title = this.props.match.params.resourceName === 'applicants'
      ? 'Sollicitant details'
      : 'Medewerker details';

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">{title}</div>
          <div className="navbar-link" />
          <div className="ml-auto">
            <Restrict to="ROLE_MANAGER">
              <Link to={`roles/`} className="btn btn-primary" href="#">
                <i className="fa fa-user-secret mr-1" /> Rechten
              </Link>
            </Restrict>
            <EmployeesPasswordButton roles={this.props.employee.roles} />
            <CallButton prefix="emp:" to={this.props.match.params.employeeId} />
            <Link to={`update/`} className="btn btn-primary ml-2">
              <i className="fa fa-pencil mr-1" /> Wijzigen
            </Link>
          </div>
        </nav>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-sm-7">
              <h4 className="mb-3">{EmployeesDefinitions.surname.value(this.props.employee)}</h4>
              <QuickLinks employee={this.props.employee}/>

              <DescriptionListView
                entity={this.props.employee}
                names={[
                  'bsn',
                  'birth_date',
                  'email',
                  'second_email',
                  'mobile',
                  'tel2',
                  'street',
                  'postal_code',
                  'iban',
                  'driving_license',
                  'number_of_workdays',
                  'public_transport_card',
                  'resume_url'
                ]}
                titleClassName="col-sm-4"
                descriptionClassName="col-sm-8"
                definitions={EmployeesDefinitions}
              />

              <hr className="my-3" />

              <h4 className="mb-3">Huidige status</h4>
              <DescriptionListView
                entity={this.props.employee}
                names={['employee_status', 'record_status', 'start_date']}
                titleClassName="col-sm-4"
                descriptionClassName="col-sm-8"
                definitions={EmployeesDefinitions}
              />

              <hr className="my-3" />

              <h4 className="mb-3">Organisatie</h4>
              <DescriptionListView
                entity={this.props.employee}
                names={[
                  'employee_code',
                  'backstage_reference',
                  'function',
                  'roles',
                  'hq',
                  'campaign',
                  'manager',
                  'recruited_by',
                  'source',
                ]}
                titleClassName="col-sm-4"
                descriptionClassName="col-sm-8"
                definitions={EmployeesDefinitions}
              />
            </div>
            <div className="col-sm-5">
              <h4 className="mb-3">Procesverloop</h4>

              <EmployeesStateHistoryView
                employee={this.props.employee}
                onStateChange={this.onStateChange.bind(this)}
              />

              <h4 className="notification mt-4 mb-3">Notificaties</h4>
              <EmployeesNotificationsView employee={this.props.employee} />

              <h4 className="mt-4 mb-3">Gesprekshistorie</h4>
              <CallsView callee="employee" identifier={this.props.employee.uuid} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.employee === null ? <LoadingView /> : this._render();
  }
}

const mapStateToProps = (state, props) => ({
  employee: state[props.match.params.resourceName].selected
});

const mapDispatchToProps = (dispatch, props) => ({
  fetch: (id) => {
    dispatch(fetchSelectAction(props.match.params.resourceName, id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesDetailPageView);
