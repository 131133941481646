import React, { Component, Fragment } from 'react';

export default class ModalView extends Component {
  state = {
    open: false
  };

  onClick = (event) => {
    event && event.preventDefault();
    this.setState({ open: !this.state.open });
  };

  render() {
    const Trigger = this.props.trigger;
    return (
      <Fragment>
        <Trigger onClick={this.onClick} />
        {this.state.open && this.renderModal()}
      </Fragment>
    );
  }

  renderModal() {
    const Body = this.props.body;
    return (
      <Fragment>
        <div className="modal-backdrop fade show" />
        <div className="modal fade show">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{this.props.title}</h5>
                <button type="button" className="close" onClick={this.onClick}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Body {...this.props} onClose={this.onClick} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
