import React, { Fragment } from 'react';

export default ({ status }) => {
  if (!status) {
    return (<p className="text-muted">Importeer eerst een bestand...</p>);
  }

  return (<Fragment>
    {status.type && <div className={`alert alert-${status.type}`}>{status.text}</div>}
    {status.results && <table className="table table-bordered">
      <tbody>
        <tr>
          <th width="125">Totaal</th>
          <td colSpan="2">{status.results.total}</td>
        </tr>
        <ResultsRow title="Onbekend" results={status.results.unfound} />
        <ResultsRow title="Duplicaten" results={status.results.double_reference_numbers} />
        <ResultsRow title="Overschreven" results={status.results.overwrites} object={true} />
      </tbody>
    </table>}
  </Fragment>);
};

const ResultsRow = ({ title, results = [], object = false }) => (
  <tr>
    <th>{title}</th>
    <td>{object ? Object.keys(results).length : results.length}</td>
    <td><ul className="m-0 pl-3">{
      object
        ? Object.keys(results).map((key, index) => <li key={index}>{key} ({results[key].join(', ')})</li>)
        : results.map((item, index) => <li key={index}>{item}</li>)
    }</ul></td>
  </tr>
);