import React, { Component } from 'react';
import ValidationMessage from '../ValidationMessage';

export default class TickField extends Component {
  constructor(props) {
    super(props);

    const options = this.props.definitions[this.props.name].options;
    this.renderedOptions = Object.keys(options).map((key) => (
      <option key={key} value={key}>
        {options[key]}
      </option>
    ));
  }

  handleChange(event) {
    const value = event.target.value;
    const values = this.props.values[this.props.name];
    const index = values.indexOf(value);
    if (index >= 0) {
      values.splice(index, 1);
    } else {
      values.push(value);
    }
    this.props.setFieldValue(this.props.name, values);
  }

  renderCheckbox(value, title, disabled) {
    const isChecked = this.props.values[this.props.name].indexOf(value) >= 0;

    return (
      <label key={value} className="custom-control custom-checkbox custom-control-inline">
        <input
          type="checkbox"
          name={`${this.props.name}[0]`}
          value={value}
          className="custom-control-input"
          checked={isChecked}
          onChange={this.handleChange.bind(this)}
          onBlur={this.props.handleBlur}
        />
        <span className="custom-control-label">{title}</span>
      </label>
    );
  }

  render() {
    const inputClassName =
      this.props.errors[this.props.name] && this.props.touched[this.props.name] && 'is-invalid';
    const error = <ValidationMessage>{this.props.errors[this.props.name]}</ValidationMessage>;

    const definition = this.props.definitions[this.props.name];
    const renderedCheckboxes = Object.keys(definition.options).map((key) =>
      this.renderCheckbox(key, definition.options[key])
    );

    return (
      <div className={`form-group ${this.props.className} ${inputClassName}`}>
        <label>{definition.title}</label>

        <div>{renderedCheckboxes}</div>

        {this.props.touched[this.props.name] && this.props.errors[this.props.name] && error}
      </div>
    );
  }
}
