import React, { Component } from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import GridConfigurationItem from './GridConfigurationItem';
import { setPreferenceAction } from '../../store/preferences';

class GridConfigurationContainer extends Component {
  state = {
    columns: []
  };

  moveItem(dragIndex, hoverIndex) {
    const columns = this.props.columnPreferences.slice();
    const dragColumn = columns[dragIndex];

    columns.splice(dragIndex, 1);
    columns.splice(hoverIndex, 0, dragColumn);

    this.props.setColumnPreferences(columns);
  }

  toggleItem(index) {
    const columns = this.props.columnPreferences.slice();
    columns[index].checked = !columns[index].checked;
    this.props.setColumnPreferences(columns);
  }

  getTitle(column) {
    return (
      this.props.definitions[column.name].column.title || this.props.definitions[column.name].title
    );
  }

  render() {
    return (
      <div className="py-2">
        <label className="mx-3">Kolommen</label>
        {this.props.columnPreferences.map((column, index) => (
          <GridConfigurationItem
            key={column.name}
            title={this.getTitle(column)}
            checked={column.checked}
            index={index}
            moveItem={this.moveItem.bind(this)}
            toggleItem={this.toggleItem.bind(this)}
          />
        ))}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  setColumnPreferences: (columns) => dispatch(setPreferenceAction(props.resourceName, columns))
});

const mapStateToProps = (state, props) => ({
  columnPreferences:
    (state.preferences[state.user.email] &&
      state.preferences[state.user.email][props.resourceName]) ||
    []
});

export default DragDropContext(HTML5Backend)(
  connect(mapStateToProps, mapDispatchToProps)(GridConfigurationContainer)
);
