import createForm from '../../shared/forms';
import ChecksFormLayout, { focusChecksForm } from './ChecksFormLayout';
import ChecksDefinitions from './ChecksDefinitions';
import { postprocessAmount, preprocessAmount } from '../../shared/Formats';
import { createYupShape } from '../../shared/forms/Validations';
import getInitialValues from '../../shared/forms/getInitialValues';

const resourceName = 'donation_check';

const formConfiguration = {
  resourceName,

  validationSchema: createYupShape(ChecksDefinitions),

  submitPath: () => 'donationchecks',
  updatePath: (props) => `donationchecks/${props.match.params.uuid}`,

  onSuccess: (bag) => {
    bag.props.onChange();
    bag.resetForm();
    focusChecksForm();
  },

  mapPropsToValues: (props) => {
    const initialValues = getInitialValues(ChecksDefinitions, props.update);
    initialValues.shift = props.shift;
    initialValues.amount = preprocessAmount(props.update && props.update.amount);
    delete initialValues.creation_date;
    delete initialValues.campaign;
    return initialValues;
  },

  submitValues: (values) => {
    values.amount = postprocessAmount(values.amount);
    return values;
  }
};

export default createForm(Object.assign({ form: ChecksFormLayout }, formConfiguration));
