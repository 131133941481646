import { Device } from 'twilio-client';
import store from '.';
import API from '../shared/forms/API';

const initialState = {
  isReady: false,
  isCalling: false,
  to: null,
  time: 0
};

const actions = {
  CONNECTED: 'PHONE_CONNECTED',
  DISCONNECTED: 'PHONE_DISCONNECTED',
  READY: 'PHONE_READY',
  ERROR: 'PHONE_ERROR',
  CONNECTING: 'PHONE_CONNECTING',
  DISCONNECTING: 'PHONE_DISCONNECTING',
  TICK: 'PHONE_TICK'
};

Device.on('ready', () => {
  console.log('Twilio.Device.ready');
  store.dispatch({
    type: actions.READY
  });
  connect();
});

Device.on('error', (error) => {
  alert(error.message);
  console.error(error);
  store.dispatch({
    type: actions.ERROR,
    error
  });
});

Device.on('connect', () => {
  console.log('Twilio.Device.connect');
  store.dispatch({
    type: actions.CONNECTED
  });
});

Device.on('disconnect', () => {
  console.log('Twilio.Device.disconnect');
  store.dispatch({
    type: actions.DISCONNECTED
  });
});

const connect = () => {
  if (Device.status() !== 'ready') {
    console.log('Twilio.Device.status: ' + Device.status() + ' !== ready');
    return;
  }

  const to = store.getState().phone.to;
  if (!to) {
    console.log('Twilio.Device.connect: no "to" number');
    return;
  }

  Device.connect({
    To: to,
    AuthorizationToken: store.getState().user.token
  });
};

const setup = (dispatch) => {
  const api = new API();
  api
    .fetch('twilio/token')
    .then((response) => response.json())
    .then((json) => {
      console.log('Setup');
      Device.setup(json.token);
    })
    .catch((error) => {
      dispatch({
        type: actions.ERROR,
        error
      });
    });
};

let callTimeInterval = null;

const startCallTimeInterval = () => {
  callTimeInterval = setInterval(() => {
    store.dispatch({
      type: actions.TICK
    });
  }, 1000);
};

const stopCallTimeInterval = () => {
  clearInterval(callTimeInterval);
};

export const callAction = (to) => {
  return async (dispatch, getState) => {
    dispatch({ type: actions.CONNECTING, to });

    if (getState().phone.isReady) {
      connect();
    } else {
      setup(dispatch);
    }
  };
};

export const hangupAction = () => {
  Device.disconnectAll();
  return {
    type: actions.DISCONNECTING
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.READY:
      return { ...state, isReady: true };
    case actions.ERROR:
      return initialState;
    case actions.TICK:
      return { ...state, time: state.time + 1 };
    case actions.CONNECTED:
      startCallTimeInterval();
      return { ...state, to: null };
    case actions.DISCONNECTED:
      stopCallTimeInterval();
      return { ...state, isCalling: false, time: 0 };
    case actions.CONNECTING:
      return { ...state, isCalling: true, to: action.to };
    default:
      return state;
  }
};
