import React, { Component } from 'react';
import { Form } from 'formik';
import ShiftsDefinitions from './ShiftsDefinitions';
import { SelectField, DateField, ResourceField } from '../../shared/forms';
import DurationFields from './DurationFields';
import { Link } from 'react-router-dom';
import API from '../../shared/forms/API';

export default class ShiftsFormLayout extends Component {
  isAutofilled = false;

  render() {
    const definitions = ShiftsDefinitions;

    if (!this.props.update && this.isAutofilled === false && this.props.values.employee) {
      this.isAutofilled = true;
      new API()
        .fetch('shifts?limit=1&employee=' + this.props.values.employee)
        .then((response) => response.json())
        .then((response) => {
          const employee = response.results[0];
          if (employee) {
            ResourceField.cache['campaign'][
              employee.campaign.id
            ] = ShiftsDefinitions.campaign.resource.value(employee.campaign);
            ResourceField.cache['sales_coach'][
              employee.sales_coach.uuid
            ] = ShiftsDefinitions.sales_coach.resource.value(employee.sales_coach);

            this.props.setFieldValue('campaign', employee.campaign.id);
            this.props.setFieldValue('sales_coach', employee.sales_coach.uuid);
            this.props.setFieldValue('hq', employee.hq);
          }
        });
    }

    return (
      <Form className="form" autoComplete="off">
        {this.props.status && (
          <div className={`alert alert-${this.props.status.type}`}>{this.props.status.text}</div>
        )}

        <div className="form-row">
          <ResourceField
            autoFocus={!this.props.update}
            {...this.props}
            className="col"
            name="employee"
            definitions={definitions}
          />
          <DateField
            className="col-sm-3 col-md-12"
            name="date"
            definitions={definitions}
            {...this.props}
          />
          <DurationFields definitions={definitions} {...this.props} />
          <ResourceField
            {...this.props}
            className="col-sm-6 col-md-12"
            name="campaign"
            definitions={definitions}
          />

          <SelectField
            {...this.props}
            className="col-sm-6 col-md-12"
            name="hq"
            definitions={definitions}
          />

          <ResourceField
            className="col"
            {...this.props}
            name="sales_coach"
            definitions={definitions}
          />
        </div>

        <CustomFormActions {...this.props} />
      </Form>
    );
  }
}

const CustomFormActions = ({ update, isSubmitting, isValid, initialValues, values, resetForm }) => {
  const noChanges = JSON.stringify(initialValues) === JSON.stringify(values);
  const isSubmitDisabled = noChanges || isSubmitting;
  const submitText = update ? 'Opslaan' : 'Toevoegen';
  const submitClassName = !isSubmitDisabled && isValid ? 'btn btn-success' : 'btn btn-primary';

  const cancelAction = (event) => {
    event.preventDefault();
    resetForm();
  };

  const cancelClassName = 'btn btn-secondary float-right';
  const cancel = update ? (
    <a href="/" onClick={cancelAction} className={cancelClassName}>
      Reset
    </a>
  ) : (
    <Link to="../" className={cancelClassName}>
      Annuleren
    </Link>
  );

  if (update && noChanges) {
    return null;
  }

  return (
    <div>
      <button className={submitClassName} disabled={isSubmitDisabled}>
        {submitText}
      </button>
      {cancel}
    </div>
  );
};
