import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingView from '../../shared/LoadingView';
import { filterAction, clearAction } from '../../store/resources';
import DonatorsExportView from './DonatorsExportView';
import withCampaign from '../../clients/campaigns/withCampaign';

class DonatorsExportPageView extends Component {

  componentDidMount() {
    this.props.clearExportLayouts();
    this.props.fetchExportLayouts({
      ...this.props.filters,
      client: this.props.client.id,
      campaign: this.props.campaign.id
    });
  }

  render() {
    if (!this.props.exportLayouts) {
      return <LoadingView />;
    }

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Donateurs exporteren</div>
        </nav>
        <DonatorsExportView {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  filters: state.donations.filters,
  exportLayouts: state.layouts.results
});

const mapDispatchToProps = (dispatch) => ({
  clearExportLayouts: () => dispatch(clearAction('layouts')),
  fetchExportLayouts: (filters) => dispatch(filterAction('layouts', filters, 500))
});

export default withCampaign(connect(mapStateToProps, mapDispatchToProps)(DonatorsExportPageView));