import React, { Component } from 'react';
import definitions from './form/DonatorDefinitions';
import GridPageView from '../shared/GridPageView';
import DonatorsLinks from './links/DonatorsLinks';

export default class DonatorsPageView extends Component {
  render() {
    return (
      <GridPageView
        {...this.props}
        definitions={definitions}
        resourceName="donations"
        multipleName="Donateurs"
        singleName="Donateur"
      >
        <DonatorsLinks />
      </GridPageView>
    );
  }
}
