import React, { Component } from 'react';
import moment from 'moment';
import { employeeDateTimeFormat } from '../employees/EmployeesState.js';
import API from '../shared/forms/API.js';
import ChecksTableViewRow from './ChecksTableViewRow';

export default class ChecksTableView extends Component {
  onClick = (event, check) => {
    event.preventDefault();
    new API()
      .fetch('donationchecks/' + check.uuid, 'DELETE')
      .then((response) => {
        if (response.ok) {
          this.props.onDelete(check);
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json) {
          alert(json);
        }
      });
  };

  parseDates(check) {
    const source_date = check.source_donation_creation_date 
      && moment(check.source_donation_creation_date);

    return {
      ...check,
      parsed_creation_date: moment(check.creation_date, employeeDateTimeFormat),
      parsed_birth_date: moment(check.birth_date, employeeDateTimeFormat),
      parsed_source_donation_creation_date: source_date,
    };
  }

  renderChecks() {
    const { date, checks, start_time, end_time } = this.props.selected;
    const props = {
      onClick: this.onClick,
      startDate: moment(date + ' ' + start_time, employeeDateTimeFormat),
      endDate: moment(date + ' ' + end_time, employeeDateTimeFormat)
    };
    
    return checks
      .map(check => this.parseDates(check))
      .sort((a, b) => a.parsed_creation_date.diff(b.parsed_creation_date))
      .reverse()
      .map((check, index) => <ChecksTableViewRow key={index} check={check} {...props} />);
  }

  render() {
    return (
      <table className="table m-0">
        <tbody>{this.renderChecks()}</tbody>
      </table>
    );
  }
}