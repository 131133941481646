import React from 'react';
import ModalView from '../../shared/ModalView';
import EmployeesPasswordForm from './EmployeesPasswordForm';

const EmployeesPasswordModalViewTrigger = (props) => (
  <a {...props} href="#modal" className="btn btn-primary ml-2">
    <i className="fa fa-key mr-1" />
    Wachtwoord
  </a>
);

export default () => (
  <ModalView
    title="Wachtwoord wijzigen"
    trigger={EmployeesPasswordModalViewTrigger}
    body={EmployeesPasswordForm}
  />
);
