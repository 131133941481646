export default (definitions, updatingObject) => {
  return Object.keys(definitions).reduce((initialValues, key) => {
    const initialValue = definitions[key].initial || '';
    if (initialValue !== undefined) {
      const hasUpdatingValue = updatingObject && updatingObject[key] !== undefined;
      initialValues[key] = hasUpdatingValue ? updatingObject[key] : initialValue;
    }
    return initialValues;
  }, {});
};
