import React, { Component } from 'react';
import { employeeDateTimeFormat } from '../EmployeesState';
import moment from 'moment';
import EmployeesTransitionToggleView from './EmployeesTransitionToggleView';
import LoadingView from '../../shared/LoadingView';
import EmployeesStateHistoryViewItem from './EmployeesStateHistoryViewItem';

function parseDate(date) {
  return moment(date, employeeDateTimeFormat + ':ss');
}

export default class EmployeesStateHistoryView extends Component {
  getParsedAndSortedTransitions() {
    return this.props.employee.transitions
      .map((transition) => ({
        ...transition,
        parsed_creation_date: parseDate(transition.creation_date)
      }))
      .sort((a, b) => b.parsed_creation_date.diff(a.parsed_creation_date));
  }

  getTransitions() {
    const transitions = this.getParsedAndSortedTransitions();
    const firstTransition = transitions[transitions.length-1];

    if (!firstTransition || firstTransition.new_state !== 'New' || firstTransition.manager) {
      transitions.push({
        new_state: 'New',
        parsed_creation_date: parseDate(this.props.employee.person_creation_date)
      });
    }

    return transitions;
  }

  renderTransition = (transition, key) => {
    return (
      <EmployeesStateHistoryViewItem
        transition={transition}
        key={key}
        onStateChange={this.props.onStateChange}
      />
    );
  };

  render() {
    if (!this.props.employee.transitions) {
      return <LoadingView />;
    }

    const transitions = this.getTransitions();
    return (
      <div className="card">
        <ul className="list-group list-group-flush">
          <li className="list-group-item p-3 pb-0">
            <EmployeesTransitionToggleView
              onStateChange={this.props.onStateChange}
              lastTransition={transitions[0]}
              original={this.props.employee}
            />
          </li>
          {transitions.map(this.renderTransition)}
        </ul>
      </div>
    );
  }
}
