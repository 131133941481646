import EmployeesDefinitions from './EmployeesDefinitions';
import GenericFilter from '../../shared/tables/GenericFilter';
import moment from 'moment';
import {
  employeeDateTimeFormat,
  employeeApplicantStateOptions,
  employeeStateOptions
} from '../EmployeesState';
import DateFilter from '../../donators/filters/DateFilter';

export default {
  record_status: EmployeesDefinitions.record_status,
  place: EmployeesDefinitions.place,
  hq: EmployeesDefinitions.hq,
  employee_status: {
    title: 'Status',
    options: employeeApplicantStateOptions,
    initial: 'New',
    value: (row) => employeeStateOptions[row.employee_status],
    column: { filter: GenericFilter }
  },
  surname: EmployeesDefinitions.surname,
  phone: EmployeesDefinitions.phone,
  person_creation_date: {
    title: 'Inschrijfdatum',
    value: (row) =>
      row.person_creation_date &&
      moment(row.person_creation_date, employeeDateTimeFormat).format('LLL'),
    column: { filter: DateFilter, sortable: true }
  },
  recruited_by: EmployeesDefinitions.recruited_by,
  recruited_by_alternative: EmployeesDefinitions.recruited_by_alternative,
  open_date: EmployeesDefinitions.open_date,
  source: EmployeesDefinitions.source,
  campaign: EmployeesDefinitions.campaign
};
