import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import Yup from 'yup';
import { filterAction } from '../../store/resources';
import { TextField, SelectField, ResourceField } from '../../shared/forms';
import Sortable from './Sortable';

class GenericFilter extends Component {
  constructor(props) {
    super(props);
    this.definition = props.definitions[props.name];
    this.filterName = this.definition.column.name || props.name;
  }

  getConfiguration() {
    if (this.configuration === undefined) {
      this.configuration = {
        render: this.renderForm.bind(this),
        onSubmit: this.onSubmit.bind(this),
        validationSchema: Yup.object().shape(this.getValidations()),
        initialValues: {
          [this.props.name]: this.getInitialValue()
        },
        isInitialValid: this.props.values[this.props.name] !== ''
      };
    }
    return this.configuration;
  }

  getInitialValue() {
    if (this.props.values[this.filterName]) {
      return this.props.values[this.filterName];
    }

    if (typeof this.definition.initial === 'object') {
      return Object.keys(this.definition.options)[0];
    }

    return this.definition.initial || '';
  }

  getValidations() {
    return this.definition.validation
      ? {
          [this.props.name]: this.definition.validation
        }
      : {};
  }

  onClear(event) {
    event.preventDefault();
    this.props.filter({
      [this.filterName]: undefined
    });
    this.props.onSubmit(event);
  }

  onSubmit(values) {
    this.props.filter({
      [this.filterName]: values[this.props.name]
    });
    this.props.onSubmit();
  }

  renderForm(formProps) {
    return (
      <Form autoComplete="off" className="form">
        <Sortable {...this.props} />

        {this.renderField(formProps)}

        <button className="btn btn-primary" disabled={formProps.isSubmitting || !formProps.isValid}>
          <i className="fa fa-check" />
        </button>
        <button className="btn btn-secondary float-right" onClick={this.onClear.bind(this)}>
          <i className="fa fa-trash" />
        </button>
      </Form>
    );
  }

  renderField(formProps) {
    if (this.definition.options) {
      return (
        <SelectField
          autoFocus
          {...formProps}
          name={this.props.name}
          definitions={this.props.definitions}
        />
      );
    } else if (this.definition.resource) {
      return (
        <ResourceField
          autoFocus
          {...formProps}
          name={this.props.name}
          definitions={this.props.definitions}
        />
      );
    } else {
      return (
        <TextField
          autoFocus
          props={formProps}
          name={this.props.name}
          definitions={this.props.definitions}
          title={this.definition.column.title}
        />
      );
    }
  }

  render() {
    return <Formik {...this.getConfiguration()} />;
  }
}

const mapStateToProps = (state, props) => ({
  values: state[props.resourceName].filters
});

const mapDispatchToProps = (dispatch, props) => ({
  filter: (filters) => dispatch(filterAction(props.resourceName, filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(GenericFilter);
