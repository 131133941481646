import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSelectAction, selectAction } from '../store/resources.js';
import LoadingView from '../shared/LoadingView';
import ShiftsForm from './form/ShiftsForm.js';
import ChecksForm from './form/ChecksForm.js';
import ChecksTableView from './ChecksTableView.js';
import { Link } from 'react-router-dom';
import API from '../shared/forms/API.js';
import AccessControl from '../shared/user/AccessControl.js';
import moment from 'moment';
import { employeeDateTimeFormat } from '../employees/EmployeesState.js';

class ShiftsUpdatePageView extends Component {
  accessControl = new AccessControl();

  componentDidMount() {
    if (this.props.selected && this.props.selected.uuid === this.props.match.params.uuid) {
      return;
    }

    this.props.clear();
    this.props.fetch(this.props.match.params.uuid);
  }

  onChange = () => {
    this.props.fetch(this.props.match.params.uuid);
  };

  onDelete = (check) => {
    this.onChange();
  };

  onClickDelete = (event) => {
    event.preventDefault();
    new API()
      .fetch('shifts/' + this.props.match.params.uuid, 'DELETE')
      .then((response) => {
        if (response.ok) {
          this.props.history.push('../');
        } else {
          return response.json();
        }
      })
      .then((json) => {
        if (json) {
          alert(json);
        }
      });
  };

  form() {
    const age = moment().diff(
      moment(this.props.selected.creation_date, employeeDateTimeFormat),
      'days'
    );
    const isDeletable = age === 0 || this.accessControl.hasRole('ROLE_ADMIN');
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Werkbriefjes</div>
          <a
            className={'btn btn-danger ml-auto ' + (!isDeletable && 'disabled')}
            href="#delete"
            onClick={this.onClickDelete.bind(this)}
          >
            <i className="fa fa-trash mr-1" /> Verwijderen
          </a>
        </nav>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-lg-3 col-xl-3">
              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="card-title mb-4">Werkbriefje wijzigen</h4>
                  <ShiftsForm update={this.props.selected} onChange={this.onChange} />
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-xl-9">
              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="card-title mb-4">
                    Geworven donateurs ({this.props.selected.checks.length})
                  </h4>
                  <ChecksForm shift={this.props.match.params.uuid} onChange={this.onChange} />
                  <ChecksTableView selected={this.props.selected} onDelete={this.onDelete} />
                </div>
              </div>
              <Link to={'/shifts/create/'} className="btn btn-primary pull-right">
                <i className="fa mr-1 fa-chevron-right" /> Nieuw werkbriefje
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.selected === null ? <LoadingView /> : this.form();
  }
}

const mapStateToProps = (state) => ({
  selected: state.shifts.selected
});

const mapDispatchToProps = (dispatch) => ({
  clear: () => dispatch(selectAction('shifts', null)),
  fetch: (uuid) => dispatch(fetchSelectAction('shifts', uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftsUpdatePageView);
