import React, { Component } from 'react';
import API from '../shared/forms/API';
import moment from 'moment';
import { employeeDateTimeFormat } from '../employees/EmployeesState';
import Restrict from '../shared/user/Restrict';
import EmployeeName from './EmployeeName';
import TableBody from '../shared/tables/TableBody';

export default class CallsView extends Component {
  identifier = '';
  state = {
    calls: null
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (this.props.identifier === this.identifier) {
      return;
    }

    this.identifier = this.props.identifier;
    this.setState({ calls: null });

    new API()
      .fetch('calllogs?limit=1000&' + this.props.callee + '=' + this.identifier, 'GET')
      .then((response) => response.json())
      .then((response) => this.setState({ calls: response.results }))
      .catch((error) => this.setState({ calls: null }));
  }

  render() {
    return (
      <div className="card">
        <table className="table mb-0">
          <thead>
            <tr>
              <th className="no-border">Gespreksdatum</th>
              <th className="no-border">Duratie</th>
              <th className="no-border">Door</th>
            </tr>
          </thead>
          <TableBody colSpan="3" placeholder="Geen telefoongesprekken">
            {this.state.calls && this.state.calls.map(this.renderCall)}
          </TableBody>
        </table>
      </div>
    );
  }

  renderCall = (call, index) => {
    const date = moment(call.creation_date, employeeDateTimeFormat);
    const duration = moment.duration(call.duration || 0, 'seconds');
    const formattedDuration = duration.format('mm:ss', { trim: false });
    return (
      <tr key={index}>
        <td>{date.format('LLL')}</td>
        <td>
          {formattedDuration}
          <Restrict to="ROLE_ADMIN">
            {call.recording_url && (
              <a target="_blank" href={call.recording_url} className="gray ml-2" rel="noopener noreferrer">
                <i className="fa fa-play" />
              </a>
            )}
          </Restrict>
        </td>
        <td>
          <EmployeeName uuid={call.manager.uuid} />
        </td>
      </tr>
    );
  };
}
