import Environment from '../../shared/Environment';

const arrayToOptions = (array) => array.reduce((options, item) => {
  options[item] = item;
  return options;
}, {});

export default {
  roles: {
    ROLE_ADMIN: 'Administrator',
    ROLE_ADMINISTRATION: 'Administratie',
    ROLE_SUPER_ADMINISTRATION: 'Super administratie',
    ROLE_SUPER_MANAGER: 'Super manager',
    ROLE_MANAGER: 'Manager',
    ROLE_RECRUITER: 'Recruitment manager',
    ROLE_COORDINATOR: 'Coördinator',
    ROLE_CAPTAIN: 'Captain',
    ROLE_EMPLOYEE: 'Medewerker'
  },
  drivingLicense: arrayToOptions(['A', 'A1', 'A2', 'AM', 'B', 'BE', 'B+', 'C', 'CE', 'C1', 'C1E', 'D', 'DE', 'D1', 'D1E', 'T']),
  source: {
    website: 'Website',
    'via medewerker': 'Via medewerker',
    anders: 'Anders',
    onbekend: 'Onbekend',
    import: 'Import'
  },
  hq: {
    active: arrayToOptions(Environment.HQS_ACTIVE),
    inactive: arrayToOptions(Environment.HQS_INACTIVE)
  },
  workdays: { '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6' },
  publicTransportCards: { 'none': 'Geen', 'week': 'Week-student', 'weekend': 'Weekend-student' }
};