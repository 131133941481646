import React from 'react';
import { Form } from 'formik';
import { TextField, CheckField, FileField, ResourceField } from '../../shared/forms';
import SelectField from '../../shared/forms/SelectField';
import defs from './LayoutsDefinitions';
import FormActions from '../../shared/forms/FormActions';
import LayoutsDownloadButton from './LayoutsDownloadButton';

export default  (props) => (
  <Form className="form">

    {props.status && (
      <div className={`alert alert-${props.status.type}`}>
        {props.status.text}
        {props.status.issues && (
          <ul>{props.status.issues.map(issue => <li>{issue}</li>)}</ul>
        )}
      </div>
    )}
    
    <TextField props={props} name="name" definitions={defs} autoFocus />
    <ResourceField {...props} name="campaign" definitions={defs} />
    <FileField {...props} name="layout_body" title="Kolom layout (csv)" text>
      <LayoutsDownloadButton update={props.update} value={props.values.layout_body} />
    </FileField>

    <div className="form-row">
      <SelectField {...props} name="file_type" definitions={defs} className="col-sm-7" />
      <TextField props={props} name="extension" definitions={defs} className="col-sm-5" />
    </div>

    {props.values.file_type === 'csv' && (
      <div className="form-row">
        <SelectField {...props} name="field_delimiter" definitions={defs} className="col-sm-4" />
        <SelectField {...props} name="field_enclosure" definitions={defs} className="col-sm-4" />
        <SelectField {...props} definitions={defs} name="line_ending" className="col-sm-4" />
      </div>
    )}

    <CheckField props={props} name="add_title_row" title="Kolomnamen toevoegen (titelrij)" />

    <FormActions {...props} touched={props.touched.name} />
  </Form>
);