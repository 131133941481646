import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'formik';
import { TextField, CheckField, FileField } from '../../shared/forms';
import SelectField from '../../shared/forms/SelectField';

const defs = {
  csv_encoding: {
    title: 'Encodering',
    options: {
      utf8: 'UTF-8',
      utf16_le: 'UTF-16LE',
      ANSI: 'ANSI'
    }
  }
};

export default (props) => (
  <Form className="form">
    
    <FileField {...props} name="file" title="Bestand (csv, ods, xls, xlsx)" autoFocus />

    {isCSV(props.values.file) && (<Fragment>
      <div className="form-row">
        <TextField props={props} name="csv_delimiter" placeholder="," title="Scheidingsteken" className="col-sm-4" />
        <TextField props={props} name="csv_enclosure" placeholder={"\""} title="Omsluitingsteken" className="col-sm-4" />
        <SelectField {...props} definitions={defs} name="csv_encoding" className="col-sm-4" />
      </div>
    </Fragment>)}

    <TextField props={props} name="columns" placeholder="reference_number,iban,reject_comment,stop_date" title="Afwijkende kolommen/velden" />
    <div className="small mb-4">
      Het te wijzige veld wordt afgeleid van de kolomnamen, mits deze aanwezig is. Gebruik dit veld 
      als de standaard kolommen niet van toepassing zijn en/of de kolomnamen niet 
      overeenkomen met de werkelijke veldnamen in de database. Geef de veld-/kolomnamen komma gescheiden op. 
    </div>

    <CheckField props={props} name="has_title_row" title="Bestand bevat kolomnamen (titelrij)" />
    <CheckField props={props} name="confirmed" title="Het bestand is gecontroleerd" />

    <button className="btn btn-primary" disabled={props.isSubmitting || !props.isValid}>
      <i className="fa mr-1 fa-upload" /> {props.values.confirmed ? 'Importeren' : 'Voorvertoning'}
    </button>
    <Link to="/donations/" className="btn btn-secondary float-right">
      Annuleren
    </Link>
  </Form>
);

const isCSV = (file) => file && file.name.indexOf(".csv") === file.name.length - 4;