import moment from "moment";
import {employeeDateFormat} from "../../../employees/EmployeesConstants";

export default function getEmployeeStateTransitionPresets(currentState) {
  const tomorrowDate = moment()
    .add(1, 'days')
    .format(employeeDateFormat);
  const tomorrowTime = '00:00';

  const notQualifiedShortcut = {
    title: 'Ongekwalif…',
    new_state: 'Exit',
  };

  const shortcuts = {
    New: [
      {
        title: 'Videogesprek',
        new_state: 'M&G planned'
      },
      {
        title: 'Neemt niet op',
        new_state: 'New',
        event_reason: 'NO_ANSWER',
        open_date: tomorrowDate,
        open_date_time: tomorrowTime
      },
      notQualifiedShortcut,
    ],
    'M&G planned': [
      {
        title: 'Sollicitatie plannen',
        new_state: 'Trial planned'
      },
      {
        title: 'Wachtlijst',
        new_state: 'Pause'
      },
      notQualifiedShortcut,
    ],
    'Trial planned': [
      {
        title: 'Proefperiode',
        new_state: 'GA_TRIAL_PERIOD'
      },
      notQualifiedShortcut,
    ],
    Active: [
      {
        title: 'Wachtlijst',
        new_state: 'Pause'
      },
      {
        title: 'Gestopt',
        new_state: 'FormerEmployee'
      }
    ],
    Pause: [
      {
        title: 'Sollicitatie plannen',
        new_state: 'Trial planned'
      }
    ],
    Exit: [],
    FormerEmployee: [],
    GA_TRIAL_PERIOD: [
      {
        title: 'Aangenomen',
        new_state: 'Active',
      },
    ],
  };

  return shortcuts[currentState];
}
