import React, { Component } from 'react';
import RestrictPath from '../../shared/user/RestrictPath';
import API from '../../shared/forms/API';

export default class DonatorsBackstageExportLink extends Component {

  path = 'export-donations-backstage';
  state = {
    className: ''
  }

  onClick = (event) => {
    event.preventDefault();
    this.setState({ className: 'disabled' });

    new API()
      .fetch(this.path, 'PUT')
      .then((response) => response.json())
      .then((json) => {
        this.setState({ className: '' });
        console.log(json);
      });
  };

  render() {
    return (
      <RestrictPath path={this.path}>
        <a href="#backstage" onClick={this.onClick} className={'dropdown-item ' + this.state.className}>
          <i className="fa fa-share mr-1" /> Naar Backstage sturen
        </a>
      </RestrictPath>
    );
  }
}

