import React, { Component, Fragment } from 'react';
import InlineForm from './inlineform/InlineForm';
import API from '../../../shared/forms/API';

export default class ThemeForm extends Component {
  onSubmit = (name, type, value) => {
    const { campaignId, clientId } = this.props.match.params;
    const path = `clients/${clientId}/campaigns/${campaignId}/settings/${name}.json`;
    const body = { theme_setting: { value, type } };

    return new API()
      .fetch(path, 'PUT', JSON.stringify(body))
      .then((response) => response.json())
      .then((json) => console.log(json));
  };

  render() {
    const props = {
      values: this.props.values,
      onSubmit: this.onSubmit
    };
    return (
      <Fragment>
        <div className="form-row">
          <InlineForm {...props} name="logo" type="file" title="Logo" />
          <InlineForm
            {...props}
            className="col-sm-6"
            name="primary"
            type="color"
            title="Primaire kleur"
          />
          <InlineForm
            {...props}
            className="col-sm-6"
            name="secundairy"
            type="color"
            title="Secundaire kleur"
          />
        </div>
        <hr />
        <div className="form-row">
          <InlineForm {...props} name="splash-background" type="file" title="Splash achtergrond" />
          <InlineForm {...props} className="col-md-6" name="splash-title" title="Splash koptekst" />
          <InlineForm
            {...props}
            className="col-md-6"
            name="splash-button"
            title="Splash knoptekst"
          />
          <InlineForm {...props} name="splash-subtitle" title="Splash tekst" large />
        </div>
        <hr />
        <div className="form-row">
          <InlineForm {...props} className="col-md-6" name="thanks-title" title="Bedankt koptekst" />
          <InlineForm {...props} className="col-md-6" name="thanks-background" type="file" title="Afwijkende bedankt achtergrond" />
          <InlineForm {...props} name="thanks-subtitle" title="Bedankt tekst" large />
        </div>
        <hr />
        <div className="form-row">
          <InlineForm {...props} name="legal-confirmation" title="Juridische bevestiging" large />
          <InlineForm {...props} name="receive-mail" title="Afwijkend label post" />
          <InlineForm {...props} name="receive-email" title="Afwijkend label e-mail" />
        </div>
      </Fragment>
    );
  }
}
