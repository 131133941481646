import React, { Component, Fragment } from 'react';
import EmployeesTransitionForm from './EmployeesTransitionForm';
import environment from "../../shared/Environment";

export default class EmployeesTransitionToggleView extends Component {
  state = {
    open: false
  };

  onClick(event, preset) {
    event.preventDefault();
    this.setState({ open: true, preset });
  }

  onStateChange() {
    this.props.onStateChange();
    this.setState({ open: false });
  }

  getApplicablePresets() {
    return environment.EMPLOYEE_STATE_TRANSITION_PRESETS(this.props.original.employee_status);
  }

  render() {
    return (
      <Fragment>
        {this.renderButton()}
        {this.renderForm()}
      </Fragment>
    );
  }

  renderButton() {
    if (this.state.open) {
      return;
    }

    return (
      <div className="btn btn-group p-0">
        {this.getApplicablePresets().map((preset, key) => this.renderPresetButton(preset, key))}
        <a href="/transition/" onClick={this.onClick.bind(this)} className="btn btn-secondary d-flex align-items-center">
          <i className="fa fa-plus" />
        </a>
      </div>
    );
  }

  renderPresetButton(preset, key) {
    return (
      <a
        href="/transition/"
        key={key}
        onClick={(event) => this.onClick(event, preset)}
        className="btn btn-primary d-flex align-items-center"
      >
        {preset.title}
      </a>
    );
  }

  renderForm() {
    if (!this.state.open) {
      return;
    }

    return (
        <EmployeesTransitionForm
          {...this.props}
          preset={this.state.preset}
          onStateChange={this.onStateChange.bind(this)}
        />
    );
  }
}
