import React, { Component } from 'react';
import { Form } from 'formik';
import definitions from './EmployeesDefinitions';
import {
  TextField,
  SelectField,
  DateField,
  IBANField,
  AddressFields,
  FormActions,
  TextAreaField,
  TickField,
  ResourceField,
  PhoneNumberField
} from '../../shared/forms';
import { setEmployeeValidationSchema } from './EmployeesForm';
import AccessControl from '../../shared/user/AccessControl';

export default class EmployeesFormLayout extends Component {
  accessControl = new AccessControl();

  isImmutable(name, exceptionRole) {
    const hasValue =
      this.props.update &&
      this.props.update[name] &&
      this.props.update[name].trim &&
      this.props.update[name].trim() !== '';
    return hasValue && !this.accessControl.hasRole(exceptionRole);
  }

  render() {
    setEmployeeValidationSchema(this.props);
    return (
      <Form className="form" autoComplete="off">
        {this.props.status && (
          <div className={`alert alert-${this.props.status.type}`}>{this.props.status.text}</div>
        )}

        <div className="form-row">
          <TextField
            className="col-sm-2"
            autoFocus
            disabled={this.isImmutable('employee_code', 'ROLE_MANAGER')}
            name="employee_code"
            definitions={definitions}
            props={this.props}
          />
          <TextField
            className="col-sm-2"
            disabled={this.isImmutable('backstage_reference', 'ROLE_MANAGER')}
            name="backstage_reference"
            definitions={definitions}
            props={this.props}
          />
          <SelectField
            {...this.props}
            className="col-sm-2"
            name="record_status"
            definitions={definitions}
          />
          <SelectField {...this.props} className="col-sm-2" name="hq" definitions={definitions} />
          <ResourceField
            {...this.props}
            className="col-sm-4"
            name="campaign"
            definitions={definitions}
          />
        </div>

        <hr />

        <div className="form-row">
          <TextField
            className="col-sm-3"
            name="firstname"
            autoFocus
            definitions={definitions}
            props={this.props}
          />
          <TextField
            className="col-sm-2"
            name="initials"
            definitions={definitions}
            props={this.props}
          />
          <SelectField {...this.props} className="col-sm-2" name="sex" definitions={definitions} />
          <TextField
            className="col-sm-2"
            name="surname_prefix"
            definitions={definitions}
            props={this.props}
          />
          <TextField
            className="col-sm-3"
            name="surname"
            definitions={definitions}
            props={this.props}
          />
        </div>

        <div className="form-row">
          <ResourceField
            {...this.props}
            className="col-sm-4"
            name="manager"
            definitions={definitions}
          />
          <SelectField
            {...this.props}
            className="col-sm-3"
            name="function"
            definitions={definitions}
          />
          <SelectField name="number_of_workdays" className="col-sm-2" definitions={definitions} {...this.props} />
          <SelectField name="public_transport_card" className="col-sm-3" definitions={definitions} {...this.props} />
        </div>

        <TickField name="driving_license" definitions={definitions} {...this.props} />

        <div className="form-row">
          <ResourceField
            {...this.props}
            className="col-sm-4"
            name="recruited_by"
            definitions={definitions}
          />
          <TextField
            className="col-sm-4"
            name="recruited_by_alternative"
            definitions={definitions}
            props={this.props}
          />
          <SelectField
            {...this.props}
            className="col-sm-4"
            name="source"
            definitions={definitions}
          />
        </div>

        <hr />
        <AddressFields {...this.props} countryDisabled={true} />

        <div className="form-row">
          <PhoneNumberField
            className="col-sm-3"
            name="mobile"
            definitions={definitions}
            {...this.props}
          />
          <TextField
            className="col-sm-3"
            name="tel2"
            definitions={definitions}
            props={this.props}
          />
          <TextField className="col-sm-3" name="email" definitions={definitions} props={this.props} />
          <TextField
            className="col-sm-3"
            name="second_email"
            definitions={definitions}
            props={this.props}
          />
        </div>

        <div className="form-row">
            <SelectField
              {...this.props}
              className="col-sm-2"
              name="citizenship"
              definitions={definitions}
            />
          <DateField className="col-sm-2" name="birth_date" title="Geboortedatum" {...this.props} />
          <DateField className="col-sm-2" name="start_date" title="Startdatum" {...this.props} />
          <TextField className="col-sm-2" name="bsn" definitions={definitions} props={this.props} />
          <IBANField {...this.props} className="col-sm-4" />
        </div>

        <hr />

        <div className="form-row">
          <TextAreaField
            className="col-sm-6"
            name="comment"
            title="Opmerkingen"
            definitions={definitions}
            props={this.props}
          />
          <TextAreaField
            className="col-sm-6"
            name="comment_closed"
            title="Gesloten opmerkingen"
            definitions={definitions}
            props={this.props}
          />
        </div>

        <hr />

        <FormActions
          {...this.props}
          touched={this.props.touched.employee_code || this.props.touched.firstname}
        />
      </Form>
    );
  }
}
