import React, { Component } from 'react';
import API from '../../../../shared/forms/API';

export default class InlineFormFileInput extends Component {
  onChange = (event) => {
    const file = event.target.files[0];
    const data = new FormData();
    data.append('upload[upload]', file, file.name);

    new API()
      .fetch('upload/static', 'POST', data, false)
      .then((response) => response.json())
      .then((fileName) => {
        const event = { target: { value: fileName } };
        this.props.onChange(event);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });
  };

  render() {
    return (
      <input name={this.props.name} type="file" className="form-control" onChange={this.onChange} />
    );
  }
}
