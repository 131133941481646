import React, { Component } from 'react';
import API from '../shared/forms/API.js';
import LoadingView from '../shared/LoadingView';

export default class SettingsExampleView extends Component {
  state = { example: null };

  componentDidMount() {
    new API()
      .submit('settings/render-example', 'POST', {
        render_setting: {
          type: 'notification',
          template: this.props.selected.value
        }
      })
      .then((response) => response.json())
      .then((json) => this.setState({ example: json }));
  }

  _render() {
    const content =
      typeof this.state.example !== 'string'
        ? JSON.stringify(this.state.example, null, 2)
        : this.state.example;

    return <div className="pre-wrap">{content}</div>;
  }

  render() {
    return this.state.example === null ? <LoadingView /> : this._render();
  }
}
