import createForm from '../../shared/forms';
import DonatorFormLayout from './DonatorFormLayout';
import Definitions from './DonatorDefinitions';
import { preprocessAmount, postprocessAmount } from '../../shared/Formats';

let donatorValidationSchema = null;

export const setDonatorValidationSchema = (schema) => {
  donatorValidationSchema = schema;
};

export default createForm({
  resourceName: 'donation',

  submitPath: (props) => `donations`,
  updatePath: (props) => `donations/${props.match.params.donatorId}`,

  onSuccess: (bag) => {
    bag.setStatus({ type: 'success', text: 'Donateur succesvol opgeslagen' });
    setTimeout(() => {
      if (bag.props.update) {
        bag.props.history.push(`/donations/${bag.props.match.params.donatorId}`);
      } else {
        bag.resetForm();
        const referenceField = document.getElementById('reference_number');
        if (referenceField) {
          referenceField.focus();
        }
      }
    }, 1500);
  },

  mapPropsToValues: (props) => {
    const update = props.update || {};

    let object = {
      recruiter: (update.recruiter && props.update.recruiter.uuid) || '',
      address_autocompletion: true,
      checked: update.checked || 'NO_ANSWER',
      state: update.state || 'PENDING',
      call_attempts: update.call_attempts || 0,
      reference_number: update.reference_number || '',
      firstname: update.firstname || '',
      initials: update.initials || '',
      sex: update.sex || 'O',
      surname_prefix: update.surname_prefix || '',
      surname: update.surname || '',
      postal_code: update.postal_code || '',
      house_number: update.house_number || '',
      house_number_suffix: update.house_number_suffix || '',
      street: update.street || '',
      place: update.place || '',
      country: update.country || 'NL',
      tel: update.tel || '',
      mobile: update.mobile || '',
      email: update.email || '',
      amount: update.amount || '',
      period: update.period || 'M',
      iban: update.iban || '',
      bic: update.bic || '',
      birth_date: update.birth_date || '',
      start_date: update.start_date || '',
      stop_date: update.stop_date || '',
      recruiter_number: update.recruiter_number || '',
      receive_mail: update.receive_mail === undefined ? true : update.receive_mail,
      receive_email: update.receive_email === undefined ? true : update.receive_email,
      reject_state: update.reject_state || '',
      reject_comment: update.reject_comment || '',
      comment: update.comment || '',
      signature: update.signature || '',
      language: update.language || ''
    };

    object.campaign = props.update
      ? props.update.campaign && props.update.campaign.id
      : props.match.params.campaignId * 1;

    object.tel = object.tel.replace(/ /g, '');
    object.mobile = object.mobile.replace(/ /g, '');
    object.amount = preprocessAmount(object.amount);

    if (props.campaign) {
      for (var field of props.campaign.input_fields) {
        const value = update.extra_fields ? update.extra_fields[field.name] : field.options[0];
        object[field.name] = value;
      }
    }

    return object;
  },

  validationSchema: () => donatorValidationSchema,

  submitValues: (values, props) => {
    values.amount = postprocessAmount(values.amount);

    if (values.reject_state === '' && values.state === 'REJECTED') {
      values.reject_state = Object.keys(Definitions.reject_state.options)[0];
    }

    delete values.address_autocompletion;
    return values;
  },

  form: DonatorFormLayout
});
