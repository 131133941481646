import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'formik';
import Yup from 'yup';

import createForm, { TextField, CheckField } from '../shared/forms';

const resourceName = 'client';

export default createForm({
  resourceName,

  redirectPath: (props) => `clients`,
  submitPath: (props) => `clients`,

  mapPropsToValues: (props) => ({
    name: '',
    enabled: true
  }),

  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required()
      .min(3, 'Minimaal 3 karakters')
      .max(100, 'Maximaal 100 karakters')
  }),

  form: (props) => (
    <Form className="form">
      {props.status && (
        <div className={`alert alert-${props.status.type}`}>{props.status.text}</div>
      )}

      <TextField autoFocus props={props} name="name" title="Naam" />
      <CheckField props={props} name="enabled" title="Actief" />

      <button className="btn btn-primary" disabled={props.isSubmitting || !props.isValid}>
        Toevoegen
      </button>
      <Link to={`/${resourceName}s/`} className="btn btn-secondary float-right">
        Annuleren
      </Link>
    </Form>
  )
});
