import TableView from './TableView';
import { connect } from 'react-redux';
import { fetchAction } from '../../store/resources';
import { setPreferenceAction } from '../../store/preferences';

class ConnectedTableView extends TableView {
  componentDidMount() {
    this.updateColumnPreferences();
    this.props.fetchResources(this.props.configuration.resourceName);
  }

  updateColumnPreferences() {
    this.props.setColumnPreferences(this.getCombinedAndAvailableColumns());
  }

  getCombinedAndAvailableColumns() {
    const definitions = this.props.configuration.definitions;
    const columns = this.props.columns;
    const columnNames = columns.map((column) => column.name);
    const allAvailableColumnNames = Object.keys(definitions).filter(
      (key) => definitions[key].column !== undefined
    );

    for (var name of allAvailableColumnNames) {
      if (columnNames.indexOf(name) < 0) {
        columns.push({ name, checked: true });
      }
    }

    return columns.filter((column) => allAvailableColumnNames.indexOf(column.name) >= 0);
  }
}

const mapStateToProps = (state, props) => ({
  ...state[props.configuration.resourceName],
  columns:
    (state.preferences[state.user.email] &&
      state.preferences[state.user.email][props.configuration.resourceName]) ||
    []
});

const mapDispatchToProps = (dispatch, props) => ({
  fetchResources: (resourceName) =>
    dispatch(fetchAction(resourceName, props.configuration.limit || 500)),
  setColumnPreferences: (columns) =>
    dispatch(setPreferenceAction(props.configuration.resourceName, columns))
});

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedTableView);
