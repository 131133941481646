import React, { Fragment } from 'react';

export default ({ children }) => (
  <Fragment>
    <nav className="navbar navbar-expand navbar-light bg-light">
      <div className="navbar-brand">Export layouts</div>
    </nav>
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-xl-6 offset-xl-3 col-md-8 offset-md-2">
          {children}
        </div>
      </div>
    </div>
  </Fragment>
);