import ApplyDefinitions from './ApplyDefinitions';
import Yup from 'yup';

export const validationSchema = Yup.object().shape(
  Object.keys(ApplyDefinitions).reduce((result, field) => {
    if (ApplyDefinitions[field].validation) {
      result[field] = ApplyDefinitions[field].validation;
    }
    return result;
  }, {})
);
