import React from 'react';
import ApplyForm from './form/ApplyForm.js';

export default function ApplyPageView(props) {
  return (
    <div className="gentle">
      <div className="card m-4">
        <div className="card-body">
          <h4 className="card-title mb-4">Uw gegevens</h4>
          <ApplyForm {...props} />
        </div>
      </div>
    </div>
  );
}
