import React, { Component } from 'react';
import API from '../../shared/forms/API';
import moment from 'moment';
import { employeeDateTimeFormat } from '../EmployeesState';
import TableBody from '../../shared/tables/TableBody';
import environment from "../../shared/Environment";

const types = {
  sms: 'SMS',
  mail: 'E-mail'
};

export default class EmployeesNotificationsView extends Component {
  uuid = null;
  state = {
    notifications: null
  };

  componentDidMount() {
    this.componentDidUpdate();
  }

  componentDidUpdate() {
    if (this.props.employee.uuid === this.uuid) {
      return;
    }

    this.uuid = this.props.employee.uuid;
    this.setState({ notifications: null });

    new API()
      .fetch('notifications?receiver_employee=' + this.props.employee.uuid, 'GET')
      .then((response) => response.json())
      .then((response) => this.setState({ notifications: response.results }));
  }

  render() {
    return (
      <div className="card">
        {' '}
        <table className="table mb-0">
          <thead>
            <tr>
              <th className="no-border">&nbsp;</th>
              <th className="no-border">Bericht</th>
              <th className="no-border">Via</th>
            </tr>
          </thead>
          <TableBody colSpan="3" placeholder="Geen notificaties">
            {this.state.notifications && this.state.notifications.map(this.renderNotification)}
          </TableBody>
        </table>
      </div>
    );
  }
  renderNotification = (notification, index) => {
    const dateSent = moment(notification.send_on, employeeDateTimeFormat);
    const dateScheduled = moment(notification.send_after, employeeDateTimeFormat);

    let icon = null;
    let dateText = null;

    if (dateSent.isValid()) {
      icon =  <i className="fa fa-calendar-check-o" />;
      dateText = dateSent.format('LLL');
    } else if (dateScheduled.isValid()) {
      icon = <i className="fa fa-calendar" />;
      dateText = 'Gepland ' + dateScheduled.fromNow();
    } else {
      dateText = 'Wachtrij';
    }

    return (
      <tr key={index}>
        <td>{icon}</td>
        <td>
          <div>{environment.NOTIFICATION_LABELS[notification.event] || notification.event}</div>
          <div className="text-muted small">{dateText}</div>
        </td>
        <td>{types[notification.type]}</td>
      </tr>
    );
  };
}
