import React, { Component } from 'react';

export default class DescriptionListView extends Component {
  getValue(name, definition) {
    let value = definition.value ? definition.value(this.props.entity) : this.props.entity[name];
    if (!definition.value && definition.options && typeof this.props.entity[name] === 'string') {
      value = definition.options[value] || value;
    }

    if (!value || (typeof value === 'string' && value.trim() === '')) {
      return <span>&nbsp;</span>;
    }
    return value;
  }

  renderItems() {
    const items = [];
    for (var name of this.props.names) {
      const definition = this.props.definitions[name];
      items.push(
        <dt key={'dt-' + name} className={this.props.titleClassName || 'col-sm-6'}>
          {(definition.column && definition.column.title) || definition.title}
        </dt>
      );
      items.push(
        <dd key={'dd-' + name} className={this.props.descriptionClassName || 'col-sm-6'}>
          {this.getValue(name, definition)}
        </dd>
      );
    }
    return items;
  }

  render() {
    return <dl className={`row ${this.props.className || ''}`}>{this.renderItems()}</dl>;
  }
}
