import React from 'react';
import { Form } from 'formik';
import definitions from './ApplyDefinitions';
import { TextField, SelectField, DateField, TextAreaField } from '../../shared/forms';
import { setApplyValidationSchema } from './ApplyForm';
import FormActions from '../../shared/forms/FormActions';

export default function ApplyFormLayout(props) {
  setApplyValidationSchema(props);
  return (
    <Form className="form" autoComplete="off">
      {props.status && (
        <div className={`alert alert-${props.status.type}`}>{props.status.text}</div>
      )}

      <div className="form-row">
        <TextField
          className="col-sm-3"
          autoFocus
          name="firstname"
          definitions={definitions}
          props={props}
        />
        <TextField
          className="col-sm-3"
          name="surname_prefix"
          definitions={definitions}
          props={props}
        />
        <TextField className="col-sm-6" name="surname" definitions={definitions} props={props} />
      </div>
      <div className="form-row">
        <TextField className="col-sm-12" name="mobile" definitions={definitions} props={props} />
      </div>
      <div className="form-row">
        <SelectField {...props} className="col-sm-5" name="sex" definitions={definitions} />
        <DateField {...props} className="col-sm-7" name="birth_date" title="Geboortedatum" />
      </div>
      <div className="form-row">
        <SelectField {...props} className="col-sm-12" name="hq" definitions={definitions} />
      </div>
      <div className="form-row">
        <SelectField {...props} className="col-sm-6" name="public_transport_card" definitions={definitions} />
        <SelectField {...props} className="col-sm-6" name="number_of_workdays" definitions={definitions} />
      </div>
      <div className="form-row">
        <TextField className="col-sm-12" name="email" definitions={definitions} props={props} />
      </div>
      {props.isReferral && (
        <div className="form-row">
          <TextField
            className="col-sm-12"
            name="recruited_by_alternative"
            disabled={props.match.params.employeeCode}
            definitions={definitions}
            props={props}
          />
        </div>
      )}

      <div className="form-row">
        <TextAreaField
          rows="3"
          className="col-sm-12"
          props={props}
          name="comment"
          title="Opmerkingen"
        />
      </div>

      <FormActions {...props} cancel={false} touched={props.touched.firstname}>
        Solliciteren
      </FormActions>
    </Form>
  );
}
