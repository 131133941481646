import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { GridView } from '../shared/tables';
import GridHeaderView from './../shared/GridHeaderView';
import { selectAction, addRequestPathModifier, addRequestFilterModifier } from '../store/resources';
import definitions from './form/ApplicantsDefinitions';
import ApplicantsDetailView from './ApplicantsDetailView';
import ApplicantsUpdateButton from './ApplicantsUpdateButton';
import ApplicantsCallButton from './ApplicantsCallButton';
import environment from "../shared/Environment";

const resourceName = 'applicants';

addRequestPathModifier(resourceName, (path) => path.replace('applicants', 'employees'));
addRequestFilterModifier(resourceName, (filters) => ({
  employee_status: Object.keys(environment.APPLICANT_STATES),
  ...filters
}));

class ApplicantsPageView extends Component {
  constructor(props) {
    super(props);

    this.tableConfiguration = {
      definitions,
      name: 'Sollicitanten',
      resourceName,

      onClick: (row) => {
        this.props.select(row);
      }
    };
  }

  render() {
    return (
      <Fragment>
        <GridHeaderView
          definitions={definitions}
          resourceName={resourceName}
          hideCreate
          {...this.props}
          configuration={this.tableConfiguration}
        >
          <ApplicantsCallButton />
          <ApplicantsUpdateButton />
        </GridHeaderView>
        <div className="split d-flex">
          <div className="split-grid">
            <GridView configuration={this.tableConfiguration} />
          </div>
          <ApplicantsDetailView />
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  select: (resource) => dispatch(selectAction(resourceName, resource))
});

export default connect(null, mapDispatchToProps)(ApplicantsPageView);
