import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSelectAction } from '../store/resources';
import LoadingView from '../shared/LoadingView';
import { TableView } from '../shared/tables';

class ClientsDetailPageView extends Component {
  componentDidMount() {
    this.props.fetchSelect('clients', this.props.match.params.clientId);
  }

  renderClient(client) {
    const tableConfiguration = {
      onClick: (row) => {
        const clientId = this.props.match.params.clientId;
        this.props.history.push('/clients/' + clientId + '/campaigns/' + row.id + '/update/');
      },
      definitions: {
        name: {
          title: 'Campagne naam',
          column: {}
        },
        backstage_reference: {
          title: 'Backstage project (slug)',
          column: true
        },
        enabled: {
          title: 'Actief',
          column: true
        },
        available_for_tablet: {
          title: 'Tablet werving',
          column: true
        }
      }
    };

    const campaignCreatePath = '/clients/' + client.id + '/campaigns/create/';
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Campagnes van {client.name}</div>
          <Link to={campaignCreatePath} className="btn btn-primary ml-auto">
            <i className="fa fa-plus mr-1" /> Campagne toevoegen
          </Link>
        </nav>
        <div className="container-fluid mt-4">
          <TableView configuration={tableConfiguration} results={client.campaigns} />
        </div>
      </div>
    );
  }

  render() {
    if (this.props.client === null) {
      return <LoadingView />;
    }
    return this.renderClient(this.props.client);
  }
}

const mapStateToProps = (state) => ({
  client: state.clients.selected
});

const mapDispatchToProps = (dispatch) => ({
  fetchSelect: (resourceName, id) => {
    dispatch(fetchSelectAction(resourceName, id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientsDetailPageView);
