import React, { Component } from 'react';
import { connect } from 'react-redux';
import { callAction, hangupAction } from '../store/phone';
import moment from 'moment';

class CallButton extends Component {
  onClick = (event) => {
    event.preventDefault();

    if (this.props.phone.isCalling) {
      this.props.hangup();
    } else {
      this.props.call(this.props.prefix + this.props.to);
    }
  };

  renderTime() {
    if (this.props.phone.time === 0) {
      return null;
    }

    const duration = moment.duration(this.props.phone.time, 'seconds');
    const formatted = duration.format('mm:ss', { trim: false });

    return ' (' + formatted + ')';
  }

  render() {
    const buttonColor = this.props.phone.isCalling ? 'btn-danger' : 'btn-success';
    const buttonText = this.props.phone.isCalling ? 'Ophangen' : 'Bellen';
    const classNames = ['btn', 'ml-2', buttonColor];

    if (!this.props.to && !this.props.phone.isCalling) {
      classNames.push('disabled');
    }

    return (
      <a className={classNames.join(' ')} href="#call" onClick={this.onClick}>
        <i className={`mr-1 fa fa-phone`} />
        {buttonText}
        {this.renderTime()}
      </a>
    );
  }
}

const mapStateToProps = (state) => ({
  phone: state.phone
});

const mapDispatchToProps = (dispatch) => ({
  hangup: () => dispatch(hangupAction()),
  call: (to) => dispatch(callAction(to))
});

export default connect(mapStateToProps, mapDispatchToProps)(CallButton);
