export default {
  employee: {
    name: 'employees',
    value: (row) =>
      [row.employee_code, row.firstname, row.initials, row.surname_prefix, row.surname]
        .filter((e) => e)
        .join(' '),
    identifier: 'uuid',
    query: ['Active', 'Pause', 'FormerEmployee']
      .map((state) => 'employee_status[]=' + state)
      .join('&')
  },
  campaign: {
    name: 'campaigns',
    value: (row) =>
      [row.client.name, row.name].join(' ') + (row.enabled === false ? ' (inactief)' : ''),
    identifier: 'id'
  }
};
