import React, { Fragment } from 'react';
import Yup from 'yup';
import Resources from '../../shared/forms/Resources';
import GenericFilter from '../../shared/tables/GenericFilter';

export default {
  campaign: {
    title: 'Campagne',
    column: { filter: GenericFilter },
    value: (row) => row.campaign && Resources.campaign.value(row.campaign),
    resource: Resources.campaign,
    validation: Yup.string().required()
  },
  name: {
    title: 'Export naam',
    column: { filter: GenericFilter, sortable: true },
  },
  file_type: {
    title: 'Bestandstype',
    column: true,
    options: {
      csv: 'Commagescheiden (csv)', 
      ods: 'OpenDocument Spreadsheet (ods)',
      xlsx: 'Microsoft Excel (xlsx)',
      google_sheet: 'Google Sheets'
    },
    initial: 'csv'
  },
  layout_body: {
    title: 'Kolom layout',
    column: true,
    value: (row) => (
      <Fragment>
        {row.export_fields.slice(0, 2).map(field => field.col_name).join(', ')}, ... ({row.export_fields.length})
      </Fragment>  
    )
  },
  add_title_row: {
    title: 'Kolomnamen',
    initial: true,
    column: true
  },
  field_delimiter: {
    title: 'Scheidingsteken',
    initial: ',',
    column: true,
    options: {
      ',': ',',
      ';': ';',
      '\t': 'Tab'
    }
  },
  field_enclosure: {
    title: 'Omsluitingsteken',
    initial: '"',
    column: true,
    options: ['\'', '"']
  },
  line_ending: {
    title: 'Regeleinde',
    column: true,
    options: {
      '\n': 'LF',
      '\n\r': 'LF-CR',
      '\r': 'CR',
      '\r\n': 'CR-LF',
      '\r\r\n': 'CR-CR-LF',
    },
    initial: '\n'
  },
  extension: {
    title: 'Extensie',
    placeholder: 'Automatisch',
    column: true
  }
};
