import React from 'react';
import LayoutsForm from './form/LayoutsForm.js';
import LayoutsWrapperView from './LayoutsWrapperView.js';

export default () => (
  <LayoutsWrapperView>
    <div className="card mb-4">
      <div className="card-body">
        <h4 className="card-title mb-4">Export layout maken</h4>
        <LayoutsForm />
      </div>
    </div>
  </LayoutsWrapperView>
);
