import store from '../../store';
import { getPath } from '../../store/resources';
import download from 'downloadjs';
import Environment from '../../shared/Environment';

export const fetchAndDownloadExport = (filters, post = false) => {
  const path = getPath('export-donations', filters);
  return fetchAndDownload(path, post ? 'POST' : 'GET');
};

export const fetchAndDownloadDump = (filters) => {
  const path = getPath('dump-donations', filters);
  return fetchAndDownload(path, 'GET');
};

export const fetchAndDownloadExportLayout = (id) => {
  const path = `exports/${id}/layout/csv`;
  return fetchAndDownload(path, 'GET');
};

const fetchAndDownload = (path, method) => {
  const token = store.getState().user.token;
  const options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization-Token': token
    }
  };
  const request = new Request(Environment.API + path, options);

  return fetch(request).then((response) => {
    if (response.ok) {
      const fileName = response.headers.get('Content-Disposition').split('filename=')[1];
      const mimeType = response.headers.get('Content-Type');
      return response.blob().then((blob) => {
        download(blob, fileName, mimeType);
        return Promise.resolve();
      });
    } else {
      return response.json().then((json) => {
        return Promise.reject(json);
      });
    }
  });
};