import React from 'react';
import { Validations } from '../../shared/forms';
import AutoCorrect from '../../shared/forms/AutoCorrect';
import GenericFilter from '../../shared/tables/GenericFilter';
import DateFilter, { StartDateFilter } from '../../donators/filters/DateFilter';
import {
  sex,
  initials,
  surname_prefix,
  surname,
  place,
  street,
  house_number,
  house_number_suffix
} from '../../shared/CommonDefinitions';
import { employeeStateOptions, employeeDateFormat } from '../EmployeesState';
import moment from 'moment';
import Resources from '../../shared/forms/Resources';

import Options from './EmployeesOptions';
export { Options };
console.log(Options);

const ComputedColumn = {
  recruiterLookup: (row) => row.recruited_by && Resources.employee.value(row.recruited_by),
  managerLookup: (row) => row.manager && Resources.employee.value(row.manager),
  name: (row) => [row.firstname, row.surname_prefix, row.surname].filter((e) => e).join(' '),
  record_status: (row) =>
    row.record_status === 'Open' ? (
      <i className="fa text-primary fa-certificate" />
    ) : (
      <i className="fa text-ultra-muted fa-certificate" />
    ),
  campaign: (row) => (row.client && row.campaign ? row.client.name + ' ' + row.campaign.name : ''),
  roles: (row) =>
    Object.values(row.roles || [])
      .map((key) => Options.roles[key])
      .join(' / '),
  drivingLicense: (row) =>
    row.driving_license &&
    Object.values(row.driving_license)
      .map((key) => Options.drivingLicense[key])
      .join(' / '),
  date: (row) =>
    row.start_date &&
    row.start_date
      .split('T')[0]
      .split('-')
      .reverse()
      .join('/'),
  address: (row) => [row.street, [row.house_number, row.house_number_suffix].join('')].join(' '),
  area: (row) => [row.postal_code, row.place].join(' '),
  phone: (row) => [row.mobile, row.tel2].filter((e) => e).join(' / '),
  birth_date: (row) => {
    if (!row.birth_date) {
      return '';
    }
    const date = moment(row.birth_date, employeeDateFormat);
    const age = moment().diff(date, 'years');
    return date.format('LL') + ' (' + age + ' jaar)';
  },
  openDate: (row) => row.open_date && moment(row.open_date, moment.ISO_8601).fromNow()
};

export default {
  record_status: {
    title: 'Actie',
    options: {
      Open: 'Open',
      Gesloten: 'Gesloten'
    },
    initial: 'Open',
    value: ComputedColumn.record_status,
    column: { filter: GenericFilter }
  },
  employee_status: {
    title: 'Status',
    options: employeeStateOptions,
    initial: 'New',
    value: (row) => employeeStateOptions[row.employee_status],
    column: { filter: GenericFilter }
  },
  employee_code: {
    title: 'Medewerker ID',
    column: { title: 'ID', filter: GenericFilter },
    initial: '',
    validation: Validations.employeeCode
  },
  hq: {
    title: 'Vestiging',
    options: { ...Options.hq.active, ...Options.hq.inactive },
    initial: Object.keys(Options.hq.active)[0],
    column: { filter: GenericFilter }
  },
  surname: {
    ...surname,
    column: { title: 'Naam', filter: GenericFilter, name: 'name' },
    value: ComputedColumn.name
  },
  phone: {
    title: 'Telefoonnr.',
    column: { filter: GenericFilter },
    value: ComputedColumn.phone
  },
  email: {
    title: 'Email',
    initial: '',
    placeholder: 'naam@domein.nl',
    validation: Validations.email.required(),
    column: {}
  },
  second_email: {
    title: 'Tweede e-mail',
    initial: '',
    placeholder: 'naam@domein.nl',
    validation: Validations.email,
    column: {}
  },
  function: {
    title: 'Functie',
    options: {
      Trainee: 'Trainee',
      Junior: 'Junior',
      Held: 'Held',
      Superheld: 'Superheld',
      'Senior Held': 'Senior Held',
      'Captain in Opleiding': 'Captain in Opleiding',
      Captian: 'Captian',
      ' Senior Captain': ' Senior Captain',
      Sidekick: 'Sidekick',
      'Sales Coach': 'Sales Coach',
      'Vestigings Manager': 'Vestigings Manager',
      'Senior Manager': 'Senior Manager',
      'Administratief Medewerker': 'Administratief Medewerker',
      Coordinator: 'Coordinator',
      'R&D': 'R&D',
      Recruiter: 'Recruiter',
      'Junior Trainer': 'Junior Trainer',
      Trainer: 'Trainer',
      'Senior Trainer': 'Senior Trainer'
    },
    initial: 'Trainee',
    column: { filter: GenericFilter }
  },
  start_date: {
    title: 'Startdatum',
    column: { filter: StartDateFilter, sortable: true },
    value: ComputedColumn.date
  },
  campaign: {
    title: 'Campagne',
    initial: '',
    column: { filter: GenericFilter },
    value: ComputedColumn.campaign,
    resource: Resources.campaign
  },
  manager: {
    title: 'Manager',
    initial: '',
    resource: Resources.employee,
    column: true,
    value: ComputedColumn.managerLookup
  },
  roles: {
    title: 'Rollen',
    options: Options.roles,
    initial: [],
    column: { filter: GenericFilter },
    value: ComputedColumn.roles
  },
  driving_license: {
    title: 'Rijbewijs',
    initial: [],
    options: Options.drivingLicense,
    column: { filter: GenericFilter },
    value: ComputedColumn.drivingLicense
  },
  source: {
    title: 'Bron',
    options: Options.source,
    column: { filter: GenericFilter },
    value: (row) => Options.source[row.source],
    initial: 'website'
  },
  recruited_by: {
    title: 'Recruiter',
    initial: '',
    resource: Resources.employee,
    value: ComputedColumn.recruiterLookup,
    column: { filter: GenericFilter, strictActive: true }
  },
  recruited_by_alternative: {
    title: 'Recruiter (alternatief)',
    initial: '',
    column: { filter: GenericFilter }
  },
  firstname: {
    title: 'Voornaam',
    validation: Validations.name.required(),
    autocorrect: AutoCorrect.firstUppercase,
    initial: ''
  },
  surname_prefix,
  sex,
  mobile: {
    title: 'Mobiel',
    placeholder: '0000000000',
    validation: Validations.phone,
    autocorrect: AutoCorrect.noSpace,
    initial: ''
  },
  tel2: {
    title: 'Andere telefoon',
    placeholder: '0000000000',
    validation: Validations.phoneSimple,
    autocorrect: AutoCorrect.noSpace,
    initial: ''
  },
  birth_date: {
    title: 'Geboortedatum',
    validation: Validations.dateIs14YearsOld,
    value: ComputedColumn.birth_date,
    initial: ''
  },
  bsn: {
    title: 'BSN',
    initial: '',
    validation: Validations.bsn
  },
  iban: {
    title: 'IBAN',
    initial: '',
    validation: Validations.iban,
    autocorrect: AutoCorrect.noSpace,
    column: true
  },
  comment: { title: 'Opmerkingen', initial: '' },
  comment_closed: { initial: '' },
  postal_code: {
    title: 'Postcode',
    validation: Validations.zipCode.required(),
    initial: '',
    column: true,
  },
  house_number,
  house_number_suffix,
  street,
  place: {
    ...place,
    column: { filter: GenericFilter },
  },
  initials: { ...initials, column: true },
  open_date: {
    title: 'Datum actie ondernemen',
    value: ComputedColumn.openDate,
    column: { filter: DateFilter, sortable: true }
  },
  backstage_reference: {
    title: 'Backstage ID',
    validation: Validations.number,
    initial: ''
  },
  public_transport_card: {
    title: 'OV-abonnement',
    initial: '',
    options: {'': '', ...Options.publicTransportCards}
  },
  number_of_workdays: {
    title: 'Werkdagen',
    initial: '',
    options: {'': '', ...Options.workdays}
  },
  citizenship: {
    title: 'Burgerschap',
    initial: '',
    options: {
      '': '',
      'EU': 'Europees',
      'NON_EU': 'non-Europees',
    },
  },
  resume_url: {
    title: 'Curriculum Vitae',
    value: (row) => row.resume_url && <a href={row.resume_url} rel="noreferrer noopener" target="_blank">Download</a>,
  },
};
