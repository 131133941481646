import React from 'react';
import ChecksDefinitions from './form/ChecksDefinitions.js';
import { employeeTimeFormat } from '../employees/EmployeesState.js';

export default ({ check, startDate, endDate, onClick }) => (
  <tr>
    <td><i className="fa fa-star"></i> {check.points}</td>
    <td className="pl-0">{check.reference_number}</td>

    <ChecksTableViewTimeCell 
      date={check.parsed_source_donation_creation_date} 
      startDate={startDate} 
      endDate={endDate}
    />

    <td>{endDate.diff(check.parsed_birth_date, 'years')} jaar</td>
    <td>{ChecksDefinitions.amount.value(check)}</td>
    <td>{ChecksDefinitions.period.value(check)}</td>
    <td>{check.iban}</td>

    <td className="table-action-cell pr-0">
      <a href="/delete/" className="gray" onClick={(event) => onClick(event, check)}>
        <i className="fa fa-trash" />
      </a>
    </td>
  </tr>
);

const ChecksTableViewTimeCell = ({ date, startDate, endDate }) => {
  if (date === null) {
    return <td />;
  }

  const inRange = date.isAfter(startDate) && date.isBefore(endDate);
  const className = !inRange ? 'text-danger' : undefined;
  return <td className={className}>{date.format(employeeTimeFormat)}</td>
};