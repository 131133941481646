import React, { Component } from 'react';
import IBAN from 'iban';
import ValidationMessage from '../ValidationMessage';
import AutoCorrect from './AutoCorrect';
import API from './API';

export default class IBANField extends Component {
  title = 'IBAN nummer';
  name = 'iban';
  placeholder = 'NL00XXXX0000000000';
  openDataAuthorization = 'Basic bGF1cmVuc0BjcmVkb2Z1bmRyYWlzaW5nLm5sOkNyM2Qwb3BlbmRhdGE=';

  indicators = {
    waiting: { color: 'secondary', icon: 'ellipsis-h', text: 'Wachten op invoer' },
    progress: { color: 'secondary', icon: 'circle-o-notch fa-spin', text: 'Valideren...' },
    invalid: { color: 'warning', icon: 'warning', text: 'Ongevalideerd' },
    valid: { color: 'success', icon: 'check-circle-o', text: 'Gevalideerd' }
  };

  state = {
    indicator: this.indicators.waiting,
  };

  componentWillMount() {
    const value = this.props.values.iban;
    const isBICMissing = this.props.bicFieldName && this.props.values[this.props.bicFieldName] === '';
    if (value !== '' && isBICMissing) {
      this.attemptAutoCompletion(value);
    }
  }

  attemptAutoCompletion(bban) {
    if (bban.trim() === '') {
      this.setBICValue('');
      this.setIndicator(this.indicators.waiting);
      return;
    }

    this.setIndicator(this.indicators.progress);
    if (IBAN.isValid(bban)) {
      this.performAutoCompletionIBAN(bban);
    } else {
      this.performAutoCompletionBBAN(bban);
    }
  }

  performAutoCompletionBBAN(bban) {
    console.log(bban);
    const request = this.getBBANRequest(bban);
    fetch(request)
      .then((response) =>
        response.json().then((data) => {
          if (data.iban) {
            this.setIndicator(this.indicators.valid);
            this.props.setFieldValue('iban', data.iban);
            this.setBICValue(data.bic);
          } else {
            this.setIndicator(this.indicators.invalid);
            this.props.setFieldValue('iban', bban);
            this.setBICValue('');
          }
        })
      )
      .catch((error) => {
        this.setIndicator(this.indicators.invalid);
        this.props.setFieldValue('iban', bban);
        this.setBICValue('');
      });
  }

  performAutoCompletionIBAN(iban) {
    new API().fetch('validation/iban/' + encodeURIComponent(iban))
      .then(response => response.json())
      .then((data) => {
        if (data.bank_data.bic) {
          this.setIndicator(this.indicators.valid);
          this.setBICValue(data.bank_data.bic);
        } else {
          this.setIndicator(this.indicators.invalid);
          this.setBICValue('');
        }
      })
      .catch((error) => {
        this.setIndicator(this.indicators.invalid);
        this.setBICValue('');
      });
  }

  setBICValue(value) {
    if (this.props.bicFieldName) {
      this.props.setFieldValue(this.props.bicFieldName, value);
    }
  }

  setIndicator(indicator) {
    this.setState({ indicator: indicator });
  }

  getBBANRequest(bban) {
    const query = '?bban=' + encodeURIComponent(bban);

    return new Request('https://opendata.siteworkers.nl/openiban' + query, {
      method: 'GET',
      headers: { Authorization: this.openDataAuthorization }
    });
  }

  handleBlur(event) {
    event.target.value = AutoCorrect.noSpace(event.target.value);
    this.props.handleChange(event);
    this.attemptAutoCompletion(event.target.value);
    this.props.handleBlur(event);
  }

  render() {
    const inputClassName =
      this.props.errors[this.name] && this.props.touched[this.name] && 'is-invalid';
    const error = <ValidationMessage>{this.props.errors[this.name]}</ValidationMessage>;

    return (
      <div className={`form-group ${this.props.className}`}>
        <label htmlFor={this.name}>{this.title}</label>

        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i
                title={this.state.indicator.text}
                className={`fa fa-${this.state.indicator.icon} text-${this.state.indicator.color}`}
              />
            </span>
          </div>
          <input
            name={this.name}
            id={this.name}
            type="text"
            placeholder={this.placeholder}
            onFocus={(event) => event.target.select()}
            onChange={this.props.handleChange}
            onBlur={this.handleBlur.bind(this)}
            value={this.props.values[this.name] || ''}
            className={'form-control ' + inputClassName}
          />
        </div>
        {this.props.touched[this.name] && this.props.errors[this.name] && error}
      </div>
    );
  }
}
