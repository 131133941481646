import React from 'react';
import Yup from 'yup';
import { Validations } from '../../shared/forms';
import StateFilter from '../filters/StateFilter';
import CampaignFilter from '../filters/CampaignFilter';
import { StartDateFilter, ExportDateFilter, StopDateFilter, BackstageExportFilter } from '../filters/DateFilter';
import AutoCorrect from '../../shared/forms/AutoCorrect';
import { GenericFilter } from '../../shared/tables';
import {
  sex,
  initials,
  surname_prefix,
  surname,
  place,
  street,
  house_number,
  house_number_suffix
} from '../../shared/CommonDefinitions';
import moment from 'moment';
import { employeeDateFormat } from '../../employees/EmployeesState';
import Resources from '../../shared/forms/Resources';

const lockDate = new Date('2020-09-28T00:00:00+01:00');
export function isLocked(row) {
  return (new Date(row.recruited_date).getTime() > lockDate.getTime());
} 

const ComputedColumn = {
  amount: (row) => {
    if (row.amount === null) {
      return;
    }
    return (
      '€ ' +
      parseFloat(row.amount / 100)
        .toFixed(2)
        .replace('.', ',')
    );
  },
  area: (row) => [row.postal_code, row.place].join(' '),
  state: (row) => {
    isLocked(row)
    return (<>
      <i className={`fa fa-${Definitions.state.options[row.state][0]}`} />
      {isLocked(row) && <i className="fa fa-lock ml-2 text-warning" />}
    </>);
  },
  campaign: (row) => row.campaign && `${row.client.name} ${row.campaign.name}`,
  phone: (row) => [row.mobile, row.tel].filter((e) => e).join(' / '),
  age: (row) =>
    row.birth_date &&
    moment(row.start_date, employeeDateFormat).diff(
      moment(row.birth_date, employeeDateFormat),
      'years'
    ),
  recruiterLookup: (row) => row.recruiter && Resources.employee.value(row.recruiter),
  name: (row) =>
    [row.firstname, row.initials, row.surname_prefix, row.surname].filter((e) => e).join(' ')
};

const Definitions = {
  reference_number: {
    title: 'Volgnummer',
    placeholder: '0000',
    validation: Validations.referenceNumber.required(),
    column: { title: '#', filter: GenericFilter }
  },
  state: {
    options: {
      CONCEPT: ['pencil text-secondary', 'Concept'],
      PENDING: ['clock-o text-secondary', 'In afwachting'],
      APPROVED: ['check text-success', 'Goedgekeurd'],
      REJECTED: ['times text-danger', 'Afgekeurd']
    },
    column: { title: 'S', filter: StateFilter },
    value: ComputedColumn.state
  },
  recruiter_number: {
    title: 'Medewerker ID',
    placeholder: '0000',
    validation: Validations.number,
    column: { title: 'MW', filter: GenericFilter, sortable: true }
  },
  recruiter: {
    title: 'Medewerker',
    initial: '',
    resource: Resources.employee,
    value: (row) => row.recruiter && Resources.employee.value(row.recruiter)
  },
  recruiter_employee_code: {
    title: 'Medewerker ID',
    placeholder: '0000',
    validation: Validations.number,
    column: { title: 'Medewerker', filter: GenericFilter, sortable: 'recruiter.employee_code' },
    value: (row) => row.recruiter && Resources.employee.value(row.recruiter)
  },
  campaign: {
    title: 'Campagne',
    column: { filter: CampaignFilter },
    value: ComputedColumn.campaign
  },
  period: {
    title: 'Periode',
    initial: 'M',
    options: {
      M: 'Maandelijks',
      K: 'Kwartaal',
      H: 'Halfjaar',
      J: 'Jaar',
      E: 'Eenmalig'
    },
    column: { filter: GenericFilter },
    value: (row) => Definitions.period.options[row.period]
  },
  start_date: {
    title: 'Startdatum',
    validation: Validations.date.required(),
    column: { filter: StartDateFilter, sortable: true }
  },
  last_export_date: {
    title: 'Export datum',
    column: { filter: ExportDateFilter, activeTerm: 'export', sortable: true }
  },
  surname,
  postal_code: {
    title: 'Postcode',
    validation: Validations.zipCode.required(),
    column: { title: 'Woonplaats', filter: GenericFilter },
    value: ComputedColumn.area
  },
  street,
  iban: {
    title: 'IBAN nummer',
    validation: Validations.iban.required(),
    autocorrect: AutoCorrect.noSpace,
    column: { filter: GenericFilter }
  },
  bic: { title: 'BIC code' },
  stop_date: {
    title: 'Stopdatum',
    validation: Validations.date,
    column: { filter: StopDateFilter }
  },
  call_attempts: {
    title: 'Belpogingen',
    options: [0, 1, 2, 3, 4, 5],
    column: { filter: GenericFilter }
  },
  checked: {
    title: 'Bereikt',
    options: {
      ANSWER: 'Ja',
      NO_ANSWER: 'Nee'
    },
    column: { filter: GenericFilter }
  },
  firstname: {
    title: 'Voornaam',
    validation: Validations.name,
    autocorrect: AutoCorrect.firstUppercase
  },
  initials,
  surname_prefix,
  sex,
  country: {
    title: 'Land',
    validation: Validations.countryCode.required()
  },
  place,
  house_number,
  house_number_suffix,
  tel: {
    title: 'Telefoon',
    placeholder: '0000000000',
    validation: Validations.phoneSimple,
    autocorrect: AutoCorrect.noSpace
  },
  mobile: {
    title: 'Mobiel',
    placeholder: '0000000000',
    validation: Validations.phone,
    autocorrect: AutoCorrect.noSpace
  },
  email: {
    title: 'Email',
    placeholder: 'naam@domein.nl',
    validation: Validations.email,
    column: true
  },
  amount: {
    title: 'Bedrag',
    placeholder: '0,00',
    validation: Validations.currency.required(),
    autocorrect: AutoCorrect.currencyComma,
    column: true,
    value: ComputedColumn.amount
  },
  birth_date: {
    column: {
      title: 'Leeftijd'
    },
    value: ComputedColumn.age,
    validation: Validations.dateAdult.required()
  },
  reject_state: {
    title: 'Afgekeurd reden',
    options_exported: {
      UNKNOWN: 'Onbekend',
      UNSUBSCRIBE: 'Afgemeld',
      STORNO: 'Storno'
    },
    options_default: {
      ERRONEOUS: 'Onjuist',
      REGRET: 'Spijt'
    }, 
    value: (row) => {
      const { options_exported, options_default }  = Definitions.reject_state;
      const options = { ...options_exported, ...options_default };
      return options[row.reject_state];
    }
  },
  phone: {
    title: 'Telefoonnr.',
    column: { filter: GenericFilter },
    value: ComputedColumn.phone
  },
  source: {
    title: 'Bron',
    column: { filter: GenericFilter }
  },
  language: {
    title: 'Taalvoorkeur',
    initial: 'nl',
    options: {
      nl: 'Nederlands',
      en: 'English'
    },
  },
  receive_mail: {
    title: 'Post ontvangen',
    value: (row) => row.receive_mail ? 'Ja' : 'Nee'
  },
  receive_email: {
    title: 'E-mail ontvangen',
    value: (row) => row.receive_email ? 'Ja' : 'Nee'
  },
  comment: {
    title: 'Opmerking'
  },
  reject_comment: {
    title: 'Afgekeurd opmerking'
  },
  last_modify_date: {
    title: 'Wijzigingsdatum'
  },
  creation_date: {
    title: 'Creatiedatum'
  },
  backstage_export_date: {
    title: 'Backstage export datum',
    column: { filter: BackstageExportFilter, activeTerm: 'backstage_export', sortable: true }
  },
  signature: {
    title: 'Handtekening',
    value: (row) => row.signature ? 'Ja' : 'Nee'
  }
};

const rawValidationSchema = Object.keys(Definitions).reduce((result, field) => {
  if (Definitions[field].validation) {
    result[field] = Definitions[field].validation;
  }
  return result;
}, {});

export const ValidationSchema = {
  update: Yup.object().shape(rawValidationSchema),
  create: Yup.object().shape({
    ...rawValidationSchema,
    start_date: Validations.dateRecent.required()
  }),
  none: Yup.object().shape({})
};

export default Definitions;
