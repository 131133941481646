import React from 'react';
import ShiftsForm from './form/ShiftsForm.js';

export default () => {
  return (
    <div>
      <nav className="navbar navbar-expand navbar-light bg-light">
        <div className="navbar-brand">Werkbriefjes</div>
      </nav>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-lg-4 col-xl-3">
            <div className="card mb-4">
              <div className="card-body">
                <h4 className="card-title mb-4">Werkbriefje toevoegen</h4>
                <ShiftsForm />
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xl-9">
            <div className="card mb-4">
              <p className="m-4 text-muted">
                Voeg werkbriefje toe om geworven donateurs in te voeren.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
