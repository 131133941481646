import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSelectAction } from '../store/resources.js';
import LoadingView from '../shared/LoadingView';
import EmployeesForm from './form/EmployeesForm.js';
import DeleteButton from '../shared/DeleteButton.js';
import RestrictPath from '../shared/user/RestrictPath.js';

class EmployeesUpdatePageView extends Component {
  componentDidMount() {
    if (this.props.selected === null) {
      this.props.fetch(this.props.match.params.employeeId);
    }
  }

  form() {
    let title = 'Medewerkers';
    let subtitle = 'Medewerker wijzigen';

    if (this.props.match.params.resourceName === 'applicants') {
      title = 'Sollicitanten';
      subtitle = 'Sollicitant wijzigen';
    }

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">{title}</div>
          <RestrictPath path="employees/" methods="DELETE">
            <DeleteButton
              path={`employees/${this.props.selected.uuid}.json`}
              returnPath="../../"
              className="ml-auto"
            />
          </RestrictPath>
        </nav>
        <div className="container-fluid my-4">
          <div className="card card-oversized">
            <div className="card-body">
              <h4 className="card-title mb-4">{subtitle}</h4>
              <EmployeesForm update={this.props.selected} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.selected === null ? <LoadingView /> : this.form();
  }
}

const mapStateToProps = (state, props) => ({
  selected: state[props.match.params.resourceName].selected
});

const mapDispatchToProps = (dispatch, props) => ({
  fetch: (id) => {
    dispatch(fetchSelectAction(props.match.params.resourceName, id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesUpdatePageView);
