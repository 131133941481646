import React, { Component, Fragment } from 'react';
import { filterAction } from '../../store/resources';
import { connect } from 'react-redux';

const content = {
  asc: (
    <Fragment>
      <i className="fa fa-caret-up mr-1" /> Oplopend
    </Fragment>
  ),
  desc: (
    <Fragment>
      <i className="fa fa-caret-down mr-1" /> Aflopend
    </Fragment>
  )
};

class SortButton extends Component {
  constructor(props) {
    super(props);
    this.definition = props.definitions[props.name];
  }

  onClick() {
    if (this.isActive()) {
      this.props.filter({ sort_by: undefined, sort: undefined });
    } else {
      this.props.filter({
        sort_by: this.getSortBy(),
        sort: this.props.direction
      });
    }
  }

  getSortBy() {
    if (this.definition.column.sortable === true) {
      return this.props.name;
    } else {
      return this.definition.column.sortable;
    }
  }

  isActive() {
    const isSortedBy = this.props.sort_by === this.getSortBy();
    const isSortedDirection = this.props.sort === this.props.direction;
    return isSortedBy && isSortedDirection;
  }

  render() {
    const className = this.isActive() ? 'secondary' : 'light';
    return (
      <button onClick={this.onClick.bind(this)} type="button" className={`btn btn-${className}`}>
        {content[this.props.direction]}
      </button>
    );
  }
}

const mapStateToProps = (state, props) => ({
  sort_by: state[props.resourceName].filters.sort_by,
  sort: state[props.resourceName].filters.sort
});

const mapDispatchToProps = (dispatch, props) => ({
  filter: (filters) => dispatch(filterAction(props.resourceName, filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(SortButton);
