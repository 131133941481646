import React, { Component } from 'react';
import TableHead from './TableHead';
import LoadingView from '../LoadingView';

export default class TableView extends Component {
  constructor(props) {
    super(props);

    this.resourceName = props.configuration.resourceName;
    this.onClick = props.configuration.onClick || function() {};
    this.columnNames = Object.keys(props.configuration.definitions).filter(
      (key) => props.configuration.definitions[key].column !== undefined
    );
  }

  getColumnNames() {
    const dynamicColumns =
      this.props.columns &&
      this.props.columns.filter((column) => column.checked === true).map((column) => column.name);
    return dynamicColumns || this.columnNames;
  }

  renderRows(columnNames) {
    return this.props.results.map((row, index) => {
      const cells = columnNames.map((name, index) => {
        return <td key={index}>{this.getValue(row, name)}&nbsp;</td>;
      });
      const isSelected =
        this.props.selected &&
        ((this.props.selected.uuid && this.props.selected.uuid === row.uuid) ||
          (this.props.selected.id && this.props.selected.id === row.id));
      const className = isSelected ? 'bg-light' : '';
      return (
        <tr className={className} onClick={this.onClick.bind(this, row)} key={index}>
          {cells}
        </tr>
      );
    });
  }

  getValue(row, name) {
    const definition = this.props.configuration.definitions[name];

    if (definition.value) return definition.value(row);
    if (typeof row[name] === 'boolean') return this.getBooleanIndicator(row[name]);
    if (definition.options) return definition.options[row[name]];

    return row[name];
  }

  getBooleanIndicator(value) {
    return value ? (
      <i className="fa fa-check text-success" />
    ) : (
      <i className="fa fa-times text-danger" />
    );
  }

  renderColumns(columnNames) {
    return columnNames.map((name, index) => {
      const definition = this.props.configuration.definitions[name];
      return definition.column.filter ? (
        <TableHead
          resourceName={this.resourceName}
          key={index}
          name={name}
          definitions={this.props.configuration.definitions}
        />
      ) : (
        <th key={index}>{definition.column.title || definition.title}</th>
      );
    });
  }

  renderExtendingRow(span) {
    if (!this.props.progress && !this.props.end) {
      return;
    }

    const icon = this.props.progress ? 'fa fa-circle-o-notch fa-spin' : 'fa fa-dot-circle-o';
    return (
      <tr key={this.props.results.length + 1} className="table-extending h2">
        <td colSpan={span}>
          <i className={icon} />
        </td>
      </tr>
    );
  }

  render() {
    if (this.props.results === null) {
      return <LoadingView />;
    }

    const columnNames = this.getColumnNames();
    return (
      <table className={`table table-hover ${this.props.className}`}>
        <thead>
          <tr>{this.renderColumns(columnNames)}</tr>
        </thead>
        <tbody>
          {this.renderRows(columnNames)}
          {this.renderExtendingRow(columnNames.length)}
        </tbody>
      </table>
    );
  }
}
