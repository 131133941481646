import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { filterAction } from '../../store/resources';
import StateSelectField from '../form/StateSelectField';

class StateFilter extends Component {
  getConfiguration() {
    if (this.configuration === undefined) {
      this.configuration = {
        render: this.renderForm.bind(this),
        onSubmit: this.onSubmit.bind(this),
        initialValues: {
          state: this.props.values.state || 'PENDING'
        },
        isInitialValid: true
      };
    }
    return this.configuration;
  }

  onClear(event) {
    event.preventDefault();
    this.props.filter({
      state: undefined
    });
    this.props.onSubmit(event);
  }

  onSubmit(values) {
    this.props.filter({
      state: values.state
    });
    this.props.onSubmit();
  }

  renderForm(formProps) {
    formProps.update = true;
    return (
      <Form autoComplete="off" className="form">
        <StateSelectField autoFocus props={formProps} />

        <button className="btn btn-primary" disabled={formProps.isSubmitting || !formProps.isValid}>
          <i className="fa fa-check" />
        </button>
        <button className="btn btn-secondary float-right" onClick={this.onClear.bind(this)}>
          <i className="fa fa-trash" />
        </button>
      </Form>
    );
  }

  render() {
    return <Formik {...this.getConfiguration()} />;
  }
}

const mapStateToProps = (state, props) => ({
  values: state.donations.filters
});

const mapDispatchToProps = (dispatch, props) => ({
  filter: (filters) => dispatch(filterAction('donations', filters))
});

export default connect(mapStateToProps, mapDispatchToProps)(StateFilter);
