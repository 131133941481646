import React, { Fragment } from 'react';
import EmployeesDefinitions from '../form/EmployeesDefinitions.js';
import { Form } from 'formik';
import { SelectField, CheckField, Validations } from '../../shared/forms/index.js';
import { DateField } from '../../shared/forms/DateField.js';
import { TextAreaField } from '../../shared/forms/TextAreaField.js';
import { employeeStateReasonOptions } from '../EmployeesState.js';
import { setTransitionValidationSchema } from './EmployeesTransitionForm.js';
import environment from "../../shared/Environment";

export default (_props) => {
  const props = {
    ..._props,
    definitions: {
      new_state: {
        ...EmployeesDefinitions.employee_status,
        title: 'Nieuwe status'
      },
      open_date: null,
      event_date: null,
      event_reason: {
        title: 'Reden',
        options: employeeStateReasonOptions
      },
      event_comment: {
        title: 'Opmerking'
      }
    }
  };

  const isPreEmployee = Object.keys(environment.APPLICANT_STATES).indexOf(props.values.new_state) >= 0;
  const isBad = ['Exit', 'Passief', 'M&G canceled', 'Trial canceled', 'Pause'].indexOf(props.values.new_state) >= 0;
  const isDateVisible = props.values.new_state.indexOf('planned') >= 0;
  const isOpenDateVisible = (!isDateVisible || !props.values.open) && isPreEmployee;
  const isReasonVisible = isBad || props.original.employee_status === props.values.new_state;
  const eventDateTitle =
    'Datum ' + EmployeesDefinitions.employee_status.options[props.values.new_state].toLowerCase();

  const isEventDateOutOfRange =
    Validations.date.isValidSync(props.values.event_date) &&
    !Validations.dateNearFuture.isValidSync(props.values.event_date);

  setTransitionValidationSchema(isDateVisible, props.values.force);

  const isSubmitDisabled = !props.touched || props.isSubmitting;
  const submitClassName =
    !isSubmitDisabled && props.isValid ? 'btn btn-success' : 'btn btn-primary';

  return (
    <Form className="form" autoComplete="off">
      {props.status && (
        <div className={`alert alert-${props.status.type}`}>{props.status.text}</div>
      )}

      <SelectField
        autoFocus
        name="new_state"
        {...props}
        disabled={props.preset && props.preset.new_state}
      />
      {isReasonVisible && (
        <SelectField
          name="event_reason"
          {...props}
          disabled={props.preset && props.preset.event_reason}
        />
      )}

      {isDateVisible && (
        <Fragment>
          <DateField
            isTime
            title={eventDateTitle}
            name="event_date"
            {...props}
            disabled={props.preset && props.preset.event_date}
          />
          {isEventDateOutOfRange && (
            <CheckField
              disabled={props.preset && props.preset.open_date}
              name="force"
              title="Datum forceren"
              props={props}
            />
          )}
          <CheckField
            disabled={props.preset && props.preset.open_date}
            name="open"
            title={`Actie ondernemen op ${props.values.event_date || 'datum'}`}
            props={props}
          />
        </Fragment>
      )}

      {isOpenDateVisible && (
        <DateField
          isTime
          title="Datum actie ondernemen"
          name="open_date"
          showPresetsFrom={props.values.event_date || true}
          {...props}
          disabled={props.preset && props.preset.open_date}
        />
      )}

      <TextAreaField rows="3" title="Opmerking" name="event_comment" props={props} />

      <button className={submitClassName} disabled={isSubmitDisabled}>
        Toevoegen
      </button>
    </Form>
  );
};
