import createForm from '../../shared/forms';
import SettingsFormLayout from './SettingsFormLayout';
import { createYupShape } from '../../shared/forms/Validations';
import SettingsDefinitions from './SettingsDefinitions';
import getInitialValues from '../../shared/forms/getInitialValues';

const resourceName = 'setting';

const formConfiguration = {
  resourceName,

  validationSchema: createYupShape(SettingsDefinitions),

  redirectPath: (props) => `${resourceName}s/${props.match.params.type}/${props.match.params.key}`,
  submitPath: () => resourceName + 's',
  updatePath: (props) => `${resourceName}s/${props.match.params.type}/${props.match.params.key}`,

  mapPropsToValues: (props) => {
    const initialValues = getInitialValues(SettingsDefinitions, props.update);
    delete initialValues.key;
    delete initialValues.type;
    return initialValues;
  }
};

export default createForm(Object.assign({ form: SettingsFormLayout }, formConfiguration));
