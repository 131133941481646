import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import NavigationView from '../NavigationView';
import {
  DonatorsCreatePageView,
  DonatorsExportPageView,
  DonatorsImportPageView,
  DonatorsPageView,
  DonatorsSelectCampaignPageView,
  DonatorsUpdatePageView,
  DonatorsDetailPageView
} from '../donators';
import {
  EmployeesCreatePageView,
  EmployeesDetailPageView,
  EmployeesPageView,
  EmployeesUpdatePageView,
  EmployeesUpdateRolesPageView,
  ApplicantsPageView
} from '../employees';
import { BugReportView, LoginPageView, ProfilePageView } from '../user';
import { ClientsCreatePageView, ClientsDetailPageView, ClientsPageView } from '../clients';
import {
  CampaignCreatePageView,
  CampaignUpdatePageView,
  ThemePageView
} from '../clients/campaigns';
import { ShiftsPageView, ShiftsCreatePageView, ShiftsUpdatePageView } from '../shifts';
import { SettingsPageView, SettingsDetailPageView, SettingsUpdatePageView } from '../settings';
import { LayoutsPageView } from '../layouts';
import LayoutsUpdatePageView from '../layouts/LayoutsUpdatePageView';
import LayoutsCreatePageView from '../layouts/LayoutsCreatePageView';

const isAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  authenticatedSelector: (state) => (state.user && state.user.token) != null,
  wrapperDisplayName: 'isAuthenticated'
});

const locationHelper = locationHelperBuilder({});
const isNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/profile/',
  allowRedirectBack: false,
  authenticatedSelector: (state) => (state.user && state.user.token) == null,
  wrapperDisplayName: 'isNotAuthenticated'
});

export function PrivateRoutes() {
  return (
    <Fragment>
      <NavigationView />

      <Route exact path="/" component={isNotAuthenticated(LoginPageView)} />

      <Route exact path="/donations/" component={isAuthenticated(DonatorsPageView)} />
      <Route
        exact
        path="/donations/create/"
        component={isAuthenticated(DonatorsSelectCampaignPageView)}
      />
      <Route exact path="/donations/import/" component={isAuthenticated(DonatorsImportPageView)} />
      <Route
        exact
        path="/donations/create/:clientId([0-9]+)/campaigns/:campaignId([0-9]+)/"
        component={isAuthenticated(DonatorsCreatePageView)}
      />
      <Route
        exact
        path="/donations/:donatorId([0-9]+)/update/"
        component={isAuthenticated(DonatorsUpdatePageView)}
      />
      <Route
        exact
        path="/donations/:donationId([0-9]+)/"
        component={isAuthenticated(DonatorsDetailPageView)}
      />

      <Route exact path="/clients/" component={isAuthenticated(ClientsPageView)} />
      <Route
        exact
        path="/clients/:clientId([0-9]+)/campaigns/create/"
        component={isAuthenticated(CampaignCreatePageView)}
      />
      <Route
        exact
        path="/clients/:clientId([0-9]+)/campaigns/:campaignId([0-9]+)/update/"
        component={isAuthenticated(CampaignUpdatePageView)}
      />
      <Route
        exact
        path="/clients/:clientId([0-9]+)/campaigns/:campaignId([0-9]+)/theme/"
        component={isAuthenticated(ThemePageView)}
      />
      <Route
        exact
        path="/clients/:clientId([0-9]+)/campaigns/:campaignId([0-9]+)/export/"
        component={isAuthenticated(DonatorsExportPageView)} />
      <Route
        exact
        path="/clients/:clientId([0-9]+)/"
        component={isAuthenticated(ClientsDetailPageView)}
      />
      <Route exact path="/clients/create/" component={isAuthenticated(ClientsCreatePageView)} />

      <Route exact path="/employees/" component={isAuthenticated(EmployeesPageView)} />
      <Route exact path="/employees/create/" component={isAuthenticated(EmployeesCreatePageView)} />
      <Route
        exact
        path="/:resourceName(employees|applicants)/:employeeId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/"
        component={isAuthenticated(EmployeesDetailPageView)}
      />
      <Route
        exact
        path="/:resourceName(employees|applicants)/:employeeId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/update/"
        component={isAuthenticated(EmployeesUpdatePageView)}
      />
      <Route
        exact
        path="/employees/:employeeId([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/roles/"
        component={isAuthenticated(EmployeesUpdateRolesPageView)}
      />

      <Route exact path="/applicants/" component={isAuthenticated(ApplicantsPageView)} />

      <Route exact path="/profile/" component={isAuthenticated(ProfilePageView)} />
      <Route exact path="/profile/bug-report/" component={isAuthenticated(BugReportView)} />

      <Route exact path="/shifts/" component={isAuthenticated(ShiftsPageView)} />
      <Route
        exact
        path="/shifts/:uuid([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})/"
        component={isAuthenticated(ShiftsUpdatePageView)}
      />
      <Route exact path="/shifts/create/" component={isAuthenticated(ShiftsCreatePageView)} />

      <Route exact path="/settings/" component={isAuthenticated(SettingsPageView)} />
      <Route
        exact
        path="/settings/:type([a-zA-Z_. \-]+)/:key([a-zA-Z_. \-]+)/"
        component={isAuthenticated(SettingsDetailPageView)}
      />
      <Route
        exact
        path="/settings/:type([a-zA-Z_. \-]+)/:key([a-zA-Z_. \-]+)/update/"
        component={isAuthenticated(SettingsUpdatePageView)}
      />

      <Route exact path="/layouts/" component={isAuthenticated(LayoutsPageView)} />
      <Route exact path="/layouts/create/" component={isAuthenticated(LayoutsCreatePageView)} />
      <Route
        exact
        path="/layouts/:id([0-9]+)/"
        component={isAuthenticated(LayoutsUpdatePageView)}
      />

    </Fragment>
  );
}
