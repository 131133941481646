import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import version from './version.json';
import Dropdown from './shared/Dropdown';
import RestrictPath from './shared/user/RestrictPath';
import Restrict from './shared/user/Restrict';
import Environment from './shared/Environment';

const NavItem = ({ path, title, accessPath = path, className = 'nav-item nav-link' }) => (
  <RestrictPath path={accessPath}>
    <NavLink to={`/${path}/`} className={className}>
      {title}
    </NavLink>
  </RestrictPath>
);

const NavItemAdmin = () => (
  <Dropdown
    renderContent={() => (
      <Fragment>
        <NavItem path="clients" title="Klanten" className="dropdown-item" />
        <NavItem path="settings" title="Instellingen" className="dropdown-item" />
        {/*<NavItem path="layouts" title="Export layouts" className="dropdown-item" />*/}
      </Fragment>
    )}
    renderButton={() => (
      <a className="nav-link dropdown-toggle" href="/toggle">Beheer</a>
    )}
  />
);

const NavBar = ({ userFullName }) => (
  <div className="collapse navbar-collapse">
    <div className="navbar-nav mr-auto">
      {/*<NavItem path="donations" title="Donateurs" />*/}
      {/*<NavItem path="shifts" title="Werkbriefjes" />*/}
      <NavItem path="employees" title="Medewerkers" />
      <NavItem path="applicants" title="Sollicitanten" accessPath="employees" />
      <Restrict to="ROLE_SUPER_ADMINISTRATION">
        <NavItemAdmin />
      </Restrict>
    </div>
    <div className="navbar-nav">
      <NavLink to="/profile/" className="nav-link">
        <i className="fa fa-user-circle-o mr-1" /> {userFullName}
      </NavLink>
    </div>
    <small className="navbar-text ml-2">{version}</small>
  </div>
);

const NavBarLocked = () => (
  <div className="collapse navbar-collapse">
    <div className="navbar-nav ml-auto">
      <i className="fa fa-lock text-white" />
    </div>
  </div>
);

const NavigationView = ({ user }) => (
  <nav className={`navbar navbar-expand navbar-dark ${Environment.NAVIGATION_CLASS}`} style={Environment.NAVIGATION_STYLE}>
    <div className="navbar-brand">{Environment.TITLE}</div>
    <div className="collapse navbar-collapse">
      {user && user.token !== null
        ? <NavBar userFullName={user.fullName} />
        : <NavBarLocked />
      }
    </div>
  </nav>
);

const mapStateToProps = (state) => ({
  user: state.user
});

export default connect(mapStateToProps, null, null, { pure: false })(NavigationView);
