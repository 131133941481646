import EmployeesDefinitions, { Options } from '../../employees/form/EmployeesDefinitions';
import Yup from 'yup';
import { Validations } from '../../shared/forms';
import Resources from '../../shared/forms/Resources';
import GenericFilter from '../../shared/tables/GenericFilter';
import DateFilter from '../../donators/filters/DateFilter';
import moment from 'moment';
import { employeeDateTimeFormat, employeeDateFormat } from '../../employees/EmployeesState';

export default {
  creation_date: {
    title: 'Invoerdatum',
    column: { filter: DateFilter, sortable: true },
    value: (row) => moment(row.creation_date, employeeDateTimeFormat).fromNow()
  },
  date: {
    title: 'Datum',
    column: { filter: DateFilter, sortable: true },
    initial: moment().format(employeeDateFormat),
    validation: Validations.date.required()
  },
  start_time: {
    title: 'Starttijd',
    column: true,
    validation: Validations.time.required()
  },
  end_time: {
    title: 'Eindtijd',
    column: true,
    validation: Validations.time.required()
  },
  duration: {
    title: 'Duur',
    column: { sortable: true, filter: GenericFilter },
    value: (row) => moment.duration(row.duration, 'hours').format('HH:mm', { trim: false }),
    options: {
      0: '00:00',
      3: '03:00',
      4: '04:00',
      5: '05:00',
      6: '06:00'
    },
    initial: 3
  },
  checks: {
    title: 'Geworven donateurs',
    column: true,
    value: (row) => row.checks.length
  },
  hq: {
    ...EmployeesDefinitions.hq,
    options: Options.hq.active
  },
  campaign: {
    title: 'Campagne',
    column: { filter: GenericFilter },
    value: (row) => row.campaign && Resources.campaign.value(row.campaign),
    resource: { ...Resources.campaign, query: 'enabled=1' },
    validation: Yup.string().required()
  },
  employee: {
    title: 'Medewerker',
    column: { filter: GenericFilter },
    value: (row) => row.employee && Resources.employee.value(row.employee),
    resource: {
      ...Resources.employee,
      query: Resources.employee.query + '&employee_status[]=Trial planned'
    },
    validation: Yup.string().required()
  },
  sales_coach: {
    title: 'Sales coach',
    column: { filter: GenericFilter },
    value: (row) => row.sales_coach && Resources.employee.value(row.sales_coach),
    resource: Resources.employee,
    validation: Yup.string().required()
  },
  shift_creator: {
    title: 'Ingevoerd door',
    column: { filter: GenericFilter },
    value: (row) => row.shift_creator && Resources.employee.value(row.shift_creator),
    resource: Resources.employee
  },
  last_modify_date: {
    title: 'Wijzigingsdatum',
    column: true,
    value: (row) => moment(row.last_modify_date, employeeDateTimeFormat).fromNow()
  }
};
