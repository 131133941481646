import React, { Component } from 'react';
import { connect } from 'react-redux';
import { extendAction } from '../../store/resources';
import { ConnectedTableView } from '.';

const extendTriggerArea = 500;

class GridView extends Component {
  onScroll(event) {
    const position = event.target.scrollTop + event.target.clientHeight;
    const bottom = event.target.scrollHeight;
    if (bottom - position < extendTriggerArea) {
      this.props.extend();
    }
  }

  render() {
    this.props.configuration.limit = 100;
    return (
      <div className="scrollable" onScroll={this.onScroll.bind(this)}>
        <ConnectedTableView configuration={this.props.configuration} />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  count: state[props.configuration.resourceName].count
});

const mapDispatchToProps = (dispatch, props) => ({
  extend: () => {
    dispatch(extendAction(props.configuration.resourceName, props.configuration.limit));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(GridView);
