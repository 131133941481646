import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAction, selectAction } from '../store/resources.js';
import LoadingView from '../shared/LoadingView';

class DonatorsSelectCampaignPageView extends Component {
  state = {
    client: null,
    campaignId: null
  };

  componentDidMount() {
    this.props.fetchResources();
  }

  onClientChange(event) {
    const id = event.target.value * 1;
    const client = this.props.clients.filter((client) => client.id === id)[0];

    if (client) {
      this.setState({
        client: client,
        campaignId: client.campaigns[0].id,
        enabled: true
      });
    } else {
      this.setState({ client: null, campaignId: null });
    }
  }

  onCampaignChange(event) {
    const id = event.target.value * 1;
    this.setState((state) => ({
      ...state,
      campaignId: id
    }));
  }

  onSubmit(event) {
    event.preventDefault();
    const { client, campaignId } = this.state;
    if (client !== null && campaignId !== null) {
      this.props.selectAction(client);
      this.props.history.push(`/donations/create/${client.id}/campaigns/${campaignId}/`);
    }
  }

  form() {
    const clients = this.props.clients
      .filter(
        (client) =>
          client.enabled && client.campaigns.filter((campaign) => campaign.enabled).length > 0
      )
      .map((client) => {
        return (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        );
      });

    const campaigns =
      this.state.client &&
      this.state.client.campaigns.filter((campaign) => campaign.enabled).map((campaign) => {
        return (
          <option key={campaign.id} value={campaign.id}>
            {campaign.name}
          </option>
        );
      });

    return (
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-sm-4 offset-sm-4">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title mb-4">Kies een campagne</h4>
                <form className="form" onSubmit={this.onSubmit.bind(this)}>
                  <div className="form-group">
                    <label htmlFor="client">Klant</label>
                    <select
                      autoFocus
                      id="client"
                      onChange={this.onClientChange.bind(this)}
                      className="form-control"
                    >
                      <option />
                      {clients}
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="campaign">Campagne</label>
                    <select
                      id="client"
                      onChange={this.onCampaignChange.bind(this)}
                      disabled={this.state.campaignId === null}
                      className="form-control"
                    >
                      {campaigns}
                    </select>
                  </div>
                  <button
                    className="btn btn-primary"
                    disabled={this.state.campaignId === null}
                    type="submit"
                  >
                    Donateurs toevoegen
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const isContentUnavailable = this.props.clients === null;
    const contentView = isContentUnavailable ? <LoadingView /> : this.form();

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Donateur toevoegen</div>
        </nav>
        {contentView}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.clients.results
});

const mapDispatchToProps = (dispatch) => ({
  fetchResources: () => {
    dispatch(fetchAction('clients'));
  },
  selectAction: (client) => {
    dispatch(selectAction('clients', client));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DonatorsSelectCampaignPageView);
