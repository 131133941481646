import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSelectAction } from '../store/resources.js';
import LoadingView from '../shared/LoadingView';
import SettingsForm from './form/SettingsForm.js';

class SettingsUpdatePageView extends Component {
  componentDidMount() {
    if (this.props.selected) {
      return;
    }

    this.props.fetch(this.getIdentifier());
  }

  getIdentifier() {
    return this.props.match.params.type + '/' + this.props.match.params.key;
  }

  form() {
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Instellingen</div>
        </nav>
        <div className="container-fluid my-4">
          <div className="card card-oversized">
            <div className="card-body">
              <h4 className="card-title mb-4">Instelling wijzigen</h4>
              <SettingsForm update={this.props.selected} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.selected === null ? <LoadingView /> : this.form();
  }
}

const mapStateToProps = (state) => ({
  selected: state.settings.selected
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (uuid) => dispatch(fetchSelectAction('settings', uuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsUpdatePageView);
