import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { resetAction } from '../store/resources';
import Clipboard from 'react-clipboard.js';
import GridConfigurationButton from './tables/GridConfigurationButton';

class GridHeaderView extends Component {
  onClickClearFilters(event) {
    this.props.reset();
    event.preventDefault();
  }

  isFiltered() {
    return (
      Object.keys(this.props.filters).filter((key) => {
        const value = this.props.filters[key];
        return value !== undefined && value !== '';
      }).length > 0
    );
  }

  renderCreate() {
    return (
      <Link to={`/${this.props.resourceName}/create/`} className="btn btn-primary ml-2">
        <i className="fa fa-plus mr-1" /> {this.props.singleName} toevoegen
      </Link>
    );
  }

  render() {
    const resetDisabled = this.isFiltered() ? '' : 'disabled';
    const results = this.props.count !== null ? this.props.count + ' resultaten' : '';

    return (
      <nav className="navbar navbar-expand navbar-light bg-light">
        <div className="navbar-brand">{this.props.configuration.name}</div>
        <span className="navbar-text">{results}</span>

        <a
          className={'btn btn-secondary ml-auto ' + resetDisabled}
          href="#reset"
          onClick={this.onClickClearFilters.bind(this)}
        >
          <i className="fa fa-filter mr-1" /> Reset filters
        </a>
        <Clipboard data-clipboard-target="tbody" className="btn btn-secondary ml-2">
          <i className="fa fa-paste" />
        </Clipboard>
        <GridConfigurationButton
          definitions={this.props.definitions}
          resourceName={this.props.resourceName}
        />
        {this.props.children}
        {!this.props.hideCreate && this.renderCreate()}
      </nav>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  reset: () => dispatch(resetAction(props.resourceName))
});

const mapStateToProps = (state, props) => ({
  count: state[props.configuration.resourceName].count,
  filters: state[props.configuration.resourceName].filters,
  results: state[props.configuration.resourceName].results
});

export default connect(mapStateToProps, mapDispatchToProps)(GridHeaderView);
