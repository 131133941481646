import createForm, { Validations } from '../../shared/forms';
import EmployeesTransitionFormLayout from './EmployeesTransitionFormLayout';
import Yup from 'yup';

let transitionValidationSchema = Yup.object().shape({});

export const setTransitionValidationSchema = (isEventDateVisible, isForced) => {
  const shape = {};
  if (isEventDateVisible) {
    shape.event_date = isForced
      ? Validations.date.required()
      : Validations.dateNearFuture.required();
  }
  transitionValidationSchema = Yup.object().shape(shape);
};

export default createForm({
  resourceName: 'employee_state_transition',
  submitPath: (props) => `employees/${props.original.uuid}/transitions`,
  form: EmployeesTransitionFormLayout,

  onSuccess(bag) {
    bag.props.onStateChange();
  },

  validationSchema: () => transitionValidationSchema,

  mapPropsToValues(props) {
    props.preset && delete props.preset.title;

    return {
      new_state: props.original.employee_status,
      open_date: '',
      open_date_time: '',
      event_date: '',
      event_date_time: '',
      event_reason: '',
      event_comment: '',
      force: props.preset ? true : false,
      open: true,
      ...props.preset
    };
  },

  submitValues(values) {
    const newValues = { ...values, close_record: true };
    newValues.open_date_time =
      values.open_date_time === '' ? '00:00:00' : values.open_date_time + ':00';
    newValues.event_date_time =
      values.event_date_time === '' ? '00:00:00' : values.event_date_time + ':00';
    newValues.open_date =
      newValues.open_date !== '' ? newValues.open_date + ' ' + newValues.open_date_time : null;
    newValues.event_date =
      newValues.event_date !== '' ? newValues.event_date + ' ' + newValues.event_date_time : null;

    if (newValues.event_date && values.open) {
      newValues.open_date = newValues.event_date;
    }

    delete newValues.open;
    delete newValues.force;
    delete newValues.open_date_time;
    delete newValues.event_date_time;
    return newValues;
  }
});
