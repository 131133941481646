import { Component } from 'react';
import EmployeesDefinitions from '../employees/form/EmployeesDefinitions';
import API from '../shared/forms/API';

export default class EmployeeName extends Component {
  state = {
    name: '...'
  };

  componentDidMount() {
    new API()
      .fetch('employees/' + this.props.uuid)
      .then((data) => data.json())
      .then((employee) => {
        const name = EmployeesDefinitions.surname.value(employee);
        this.setState({ name });
      })
      .catch((error) => this.setState({ name: '(niet beschikbaar)' }));
  }

  render() {
    return this.state.name;
  }
}
