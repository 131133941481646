import { compose, createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import userReducer from './user';
import phoneReducer from './phone';
import fetchReducer from './resources';
import preferencesReducer from './preferences';
import persistState from 'redux-localstorage';
import environment from '../shared/Environment';

const initialApplicantStates = Object.keys(environment.APPLICANT_STATES);

const combinedReducers = combineReducers({
  user: userReducer,
  phone: phoneReducer,
  preferences: preferencesReducer,
  clients: fetchReducer('clients'),
  donations: fetchReducer('donations'),
  employees: fetchReducer('employees', { filters: { employee_status: 'Active' } }),
  applicants: fetchReducer('applicants', { filters: { employee_status: initialApplicantStates } }),
  shifts: fetchReducer('shifts'),
  settings: fetchReducer('settings'),
  layouts: fetchReducer('layouts')
});

const store = createStore(
  combinedReducers,
  compose(applyMiddleware(thunk), persistState('user'), persistState('preferences'))
);

if (environment.REDUX_CONSOLE) {
  store.subscribe(() => console.log(store.getState()));
}

export default store;
