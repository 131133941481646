import React, { Component } from 'react';
import { connect } from 'react-redux';
import DonatorValidatedFormView from './form/DonatorValidatedFormView';
import { fetchSelectAction } from '../store/resources.js';
import LoadingView from '../shared/LoadingView';
import CallButton from '../shared/CallButton';
import Restrict from '../shared/user/Restrict';
import DeleteButton from '../shared/DeleteButton';

class DonatorsCreatePageView extends Component {
  componentDidMount() {
    if (this.props.donator === null) {
      this.props.fetchDonator(this.props.match.params.donatorId);
    }
  }

  getSelectedCampaign() {
    return this.props.donator.campaign;
  }

  form() {
    const campaign = this.getSelectedCampaign();
    const clientName = this.props.donator.client && this.props.donator.client.name;
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Donateur wijzigen</div>
          <div className="ml-auto">
            <Restrict to="ROLE_ADMIN">
              <DeleteButton
                path={`donations/${this.props.match.params.donatorId}.json`}
                returnPath="../"
              />
            </Restrict>
            <CallButton prefix="don:" to={this.props.match.params.donatorId} />
          </div>
        </nav>
        <div className="container-fluid my-4">
          <div className="card card-oversized">
            <div className="card-body">
              <img
                onError={(event) => (event.target.style.display = 'none')}
                className="campaign-logo"
                alt={clientName}
                src={`${process.env.PUBLIC_URL}/${clientName}.jpg`}
              />
              <h4 className="card-title mb-1">
                {clientName} {campaign && campaign.name}
              </h4>
              <small className="text-muted mb-4">
                Toegevoegd op {this.props.donator.creation_date}
              </small>
              <DonatorValidatedFormView campaign={campaign} update={this.props.donator} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.donator === null ? <LoadingView /> : this.form();
  }
}

const mapStateToProps = (state) => ({
  donator: state.donations.selected
});

const mapDispatchToProps = (dispatch) => ({
  fetchDonator: (id) => {
    dispatch(fetchSelectAction('donations', id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DonatorsCreatePageView);
