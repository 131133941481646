import React from 'react';
import createForm from '../../shared/forms';
import ApplyFormLayout from './ApplyFormLayout';
import ApplyDefinitions from './ApplyDefinitions';
import Yup from 'yup';
import { validationSchema } from './ApplyValidations';

const resourceName = 'person';
let applyValidationSchema = Yup.object().shape({});

export const setApplyValidationSchema = () => {
  applyValidationSchema = validationSchema;
};

const formConfiguration = {
  resourceName,

  submitPath: () => 'public/applicants',

  validationSchema: () => applyValidationSchema,

  redirectPath: () => 'public/apply/success',

  mapPropsToValues: (props) => {
    const initialValues = Object.keys(ApplyDefinitions).reduce((initialValues, key) => {
      const initialValue = ApplyDefinitions[key].initial;
      if (initialValue !== undefined) {
        initialValues[key] = initialValue;
      }
      return initialValues;
    }, {});

    if (props.match.params.employeeCode) {
      initialValues.recruited_by_alternative = props.match.params.employeeCode;
    }

    return {
      ...initialValues
    };
  },

  submitValues: (values) => ({
    ...values,
    source: values.recruited_by_alternative ? 'via medewerker' : 'website'
  })
};

export default function ApplyForm(props) {
  return React.createElement(
    createForm(Object.assign({ form: ApplyFormLayout }, formConfiguration)),
    { ...props }
  );
}
