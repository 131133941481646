import React, { Component } from 'react';
import { Form } from 'formik';
import definitions from './EmployeesDefinitions';
import { TickField } from '../../shared/forms';
import FormActions from '../../shared/forms/FormActions';
import AccessControl from '../../shared/user/AccessControl';

export default class EmployeesRolesFormLayout extends Component {
  constructor(props) {
    super(props);
    this.limitRolesToAccessControl();
  }

  limitRolesToAccessControl() {
    const accessControl = new AccessControl();
    let limitedRolesOptions = {};
    for (let role of accessControl.getAllRolesCurrentUser()) {
      let name = definitions.roles.options[role];
      if (name) {
        limitedRolesOptions[role] = name;
      }
    }
    definitions.roles.options = limitedRolesOptions;
  }

  render() {
    return (
      <Form className="form form-roles" autoComplete="off">
        {this.props.status && (
          <div className={`alert alert-${this.props.status.type}`}>{this.props.status.text}</div>
        )}

        <TickField name="roles" definitions={definitions} {...this.props} />

        <FormActions {...this.props} />
      </Form>
    );
  }
}
