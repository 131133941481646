import React, { Component } from 'react';
import API from '../../shared/forms/API';
import Restrict from '../../shared/user/Restrict';

export default class EmployeesStateHistoryDelete extends Component {
  onClick = (event) => {
    event.preventDefault();

    const transition = this.props.transition;
    const path = `employees/${transition.employee}/transitions/${transition.uuid}.json`;

    new API()
      .fetch(path, 'DELETE')
      .then(this.onSuccess)
      .catch(this.onError);
  };

  onSuccess = (response) => {
    if (!response.ok) {
      response.json().then((json) => alert(json));
    } else {
      this.props.onStateChange();
    }
  };

  onError = (error) => {
    alert('Kan transitie niet verwijderen');
    console.error(error);
  };

  render() {
    if (!this.props.transition.employee || !this.props.transition.uuid) {
      return null;
    }
    return (
      <Restrict to="ROLE_ADMIN">
        <a className="pull-right gray" href="/delete/" onClick={this.onClick}>
          <i className="fa fa-trash" />
        </a>
      </Restrict>
    );
  }
}
