import React from 'react';
import DonatorsImportFormLayout from './DonatorsImportFormLayout';
import DonatorsImportResult from './DonatorsImportResult';

export default (props) => (
  <div className="row">
    <div className="col-lg-6">
      <div className="card mb-4">
        <div className="card-body">
          <h4 className="card-title mb-4">Wijzigingen importeren</h4>
          <DonatorsImportFormLayout {...props} />
        </div>
      </div>
    </div>
    <div className="col-lg-6">
      <div className="card mb-4">
        <div className="card-body">
          <h4 className="card-title mb-4">Resultaat</h4>
          <DonatorsImportResult {...props} />
        </div>
      </div>
    </div>
  </div>
);