import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSelectAction } from '../store/resources.js';
import LayoutsForm from './form/LayoutsForm.js';
import LayoutsWrapperView from './LayoutsWrapperView.js';

const resourceName = 'layouts';

class LayoutsUpdatePageView extends Component {
  
  componentDidMount() {
    if (!this.props.selected) {
      this.props.fetch(this.props.match.params.id);
    }
  }
  
  render () {
    return (
      <LayoutsWrapperView>
        {this.props.selected && 
          <div className="card mb-4">
            <div className="card-body">
              <h4 className="card-title mb-4">{this.props.selected.name} wijzigen</h4>
              <LayoutsForm update={this.props.selected} />
            </div>
          </div>
        }
      </LayoutsWrapperView>
    );
  }
}

const mapStateToProps = (state) => ({
  selected: state[resourceName].selected
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(fetchSelectAction(resourceName, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(LayoutsUpdatePageView);
