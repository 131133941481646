import React, { Fragment } from 'react';
import withCampaign from '../withCampaign';
import ThemeView from './ThemeView';

const ThemePageView = ({ client, campaign }) => (
  <Fragment>
    <nav className="navbar navbar-expand navbar-light bg-light">
      <div className="navbar-brand">
        Thema van {client.name} {campaign.name} wijzigen
      </div>
    </nav>
    <div className="container-fluid my-4">
      <div className="card">
        <div className="card-body">
          <ThemeView />
        </div>
      </div>
    </div>
  </Fragment>
);

export default withCampaign(ThemePageView);
