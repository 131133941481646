import Environment from '../shared/Environment';
import * as Sentry from '@sentry/browser';

const actions = {
  userLogin: 'USER_LOGIN',
  userLogout: 'USER_LOGOUT',
  userLoginFailure: 'USER_LOGIN_FAILRE',
  userLoginFailureClear: 'USER_LOGIN_FAILURE_CLEAR'
};

const userLoginAction = (user) => {
  return { type: actions.userLogin, user };
};

const userLoginFailureAction = (message) => {
  return { type: actions.userLoginFailure, message };
};

export const userLoginFailureClearAction = () => {
  return { type: actions.userLoginFailureClear };
};

export const userClearAction = () => {
  return async (dispatch, getState) => {
    const options = {
      method: 'POST',
      headers: { 'Authorization-Token': getState().user.token }
    };
    const request = new Request(Environment.API + 'logout.json', options);

    fetch(request)
      .then((data) => data.json(), (error) => console.log(error))
      .then((response) => {
        dispatch({ type: actions.userLogout });
      });
  };
};

export const userAuthenticateAction = ({ credential, name, email }) => {
  return async (dispatch) => {
    const options = {
      method: 'POST',
      body: JSON.stringify({ token: credential }),
      headers: { 'Content-Type': 'application/json' }
    };
    const request = new Request(Environment.API + 'authenticate', options);

    fetch(request)
      .then((data) => data.json())
      .then((response) => {
        Sentry.configureScope((scope) => {
          scope.setUser({ "email": email });
        });

        if (response.status === 'error') {
          dispatch(userLoginFailureAction(response.message));
          return; 
        }
        
        if (response.error) {
          dispatch(userLoginFailureAction(response.error.message));
          return;
        }

        dispatch(
          userLoginAction({
            fullName: name,
            token: response.token,
            roles: response.roles,
            email: email
          })
        );
      });
  };
};

export default (state = null, action) => {
  switch (action.type) {
    case actions.userLogin:
      return action.user;
    case actions.userLoginFailure:
      return { errorMessage: action.message };
    case actions.userLogout:
      return null;
    case actions.userLoginFailureClear:
      return null;
    default:
      return state;
  }
};
