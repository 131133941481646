import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Dropdown from '../Dropdown';
import SortOnlyFilter from './SortOnlyFilter';

class TableHead extends Component {
  getDefinition() {
    return this.props.definitions[this.props.name];
  }

  renderIcons() {
    const column = this.getDefinition().column;

    return (
      <Fragment>
        {column.filter !== SortOnlyFilter && <i className="fa fa-filter ml-1" />}
        {column.sortable && <i className="fa fa-sort ml-1" />}
      </Fragment>
    );
  }

  renderFilter(hide) {
    const definition = this.getDefinition();
    const Filter = definition.column.filter;
    return (
      <>
        <a className="dropdown-title" onClick={hide.bind(this)} href="#close-dropdown">
          {definition.column.title || definition.title}
          {this.renderIcons()}
        </a>
        <Filter {...this.props} onSubmit={hide} />
      </>
    );
  }

  getClassName() {
    return this.isFilterEnabled(this.props.filters, this.props.name) ? 'text-primary' : '';
  }

  isFilterEnabled() {
    const columnDefinition = this.getDefinition().column;
    const activeTerm = columnDefinition.activeTerm || this.props.name;
    
    if (columnDefinition.strictActive) {
      return Object.keys(this.props.filters).indexOf(activeTerm) >= 0;
    } else {
      const matches = Object.keys(this.props.filters)
        .filter((key) => this.props.filters[key] && key.indexOf(activeTerm) === 0);
      const sortableTerm =
        columnDefinition.sortable === true ? this.props.name : columnDefinition.sortable;
      const isSorted =
        this.props.filters.sort_by !== undefined && this.props.filters.sort_by === sortableTerm;
      return matches.length > 0 || isSorted;
    }
  }

  renderButton() {
    const className = this.getClassName();
    return (
      <a className={className} href="#open-dropdown">
        {this.getDefinition().column.title || this.getDefinition().title}
        {this.renderIcons()}
      </a>
    );
  }

  render() {
    return (
      <Dropdown
        {...this.props}
        tagName="th"
        renderContent={this.renderFilter.bind(this)}
        renderButton={this.renderButton.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state, props) => ({
  filters: state[props.resourceName].filters
});

const mapDispatchToProps = (dispatch, props) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TableHead);
