import React, { Component } from 'react';
import MessageBirdValidation from './MessageBirdValidation';
import ValidationMessage from '../ValidationMessage';
import AutoCorrect from './AutoCorrect';

export default class phoneField extends Component {
  messageBird = new MessageBirdValidation();

  indicators = {
    waiting: { color: 'secondary', icon: 'ellipsis-h', text: 'Wachten op invoer' },
    progress: { color: 'secondary', icon: 'circle-o-notch fa-spin', text: 'Valideren...' },

    [MessageBirdValidation.ABSENT]: {
      color: 'warning',
      icon: 'warning',
      text: 'MessageBird: Afwezig'
    },
    [MessageBirdValidation.FAILED]: {
      color: 'info',
      icon: 'question-circle',
      text: 'MessageBird: Kan niet uitvoeren'
    },
    [MessageBirdValidation.UNKNOWN]: {
      color: 'danger',
      icon: 'times',
      text: 'MessageBird: Bestaat niet'
    },
    [MessageBirdValidation.ACTIVE]: {
      color: 'success',
      icon: 'check-circle-o',
      text: 'MessageBird: Actief'
    }
  };

  state = {
    indicator: this.indicators.waiting
  };

  constructor(props) {
    super(props);

    this.definition = props.definitions[props.name] || {};
  }

  componentWillMount() {
    const value = this.props.values[this.props.name];
    if (value !== '') {
      this.attemptValidation(value);
    }
  }

  setIndicator(indicator) {
    if (!indicator) {
      console.log('PhoneNumberField cannot determine indicator');
      indicator = this.indicators[MessageBirdValidation.FAILED];
    }
    this.setState({ indicator: indicator });
  }

  handleBlur(event) {
    event.target.value = AutoCorrect.phone(event.target.value);
    this.props.handleChange(event);
    this.attemptValidation(event.target.value);
    this.props.handleBlur(event);
  }

  attemptValidation(phone) {
    if (phone === '') {
      this.setIndicator(this.indicators.waiting);
      return;
    }
    this.setIndicator(this.indicators.progress);
    this.messageBird
      .validate(phone)
      .then((result) => {
        this.setIndicator(this.indicators[result]);
      })
      .catch((error) => {
        this.setIndicator(this.indicators[MessageBirdValidation.UNKNOWN]);
        console.warn(error);
      });
  }

  render() {
    const inputClassName =
      this.props.errors[this.props.name] && this.props.touched[this.props.name] && 'is-invalid';
    const error = <ValidationMessage>{this.props.errors[this.props.name]}</ValidationMessage>;

    return (
      <div className={`form-group ${this.props.className}`}>
        <label htmlFor={this.props.name}>{this.definition.title}</label>

        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i
                title={this.state.indicator.text}
                className={`fa fa-${this.state.indicator.icon} text-${this.state.indicator.color}`}
              />
            </span>
          </div>
          <input
            name={this.props.name}
            id={this.props.name}
            type="text"
            placeholder={this.definition.placeholder}
            onFocus={(event) => event.target.select()}
            onChange={this.props.handleChange}
            onBlur={this.handleBlur.bind(this)}
            value={this.props.values[this.props.name] || ''}
            className={'form-control ' + inputClassName}
          />
        </div>

        {this.props.touched[this.props.name] && this.props.errors[this.props.name] && error}
      </div>
    );
  }
}
