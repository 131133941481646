import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import { ApplyPageView } from '../apply';
import ApplySuccess from '../apply/success';

export function PublicRoutes() {
  return (
    <Fragment>
      <Route exact path="/public/apply" component={ApplyPageView} />
      <Route
        exact
        path="/public/apply/referral"
        component={() => ApplyPageView({ isReferral: true })}
      />
      <Route
        exact
        path="/public/apply/:employeeCode([0-9]+)/"
        component={() => ApplyPageView({ isReferral: true })}
      />
      <Route exact path="/public/apply/success" component={ApplySuccess} />
    </Fragment>
  );
}
