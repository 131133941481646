import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'formik';
import Yup from 'yup';

import createForm from '../shared/forms';
import { TextAreaField } from '../shared/forms/TextAreaField';

export default class BugReportView extends Component {
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Probleem rapporteren</div>
        </nav>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <div className="card">
                <div className="card-body">
                  <BugReportForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const BugReportForm = createForm({
  resourceName: 'bug',

  redirectPath: (props) => `profile`,
  submitPath: (props) => `notify.json`,

  mapPropsToValues: (props) => ({
    text: ''
  }),

  validationSchema: Yup.object().shape({
    text: Yup.string()
      .required()
      .min(3, 'Minimaal 3 karakters')
  }),

  form: (props) => (
    <Form className="form">
      {props.status && (
        <div className={`alert alert-${props.status.type}`}>{props.status.text}</div>
      )}

      <TextAreaField autoFocus props={props} name="text" title="Omschrijving" />
      <button className="btn btn-primary" disabled={props.isSubmitting || !props.isValid}>
        Verzenden
      </button>
      <Link to="/profile" className="btn btn-secondary float-right">
        Annuleren
      </Link>
    </Form>
  )
});
