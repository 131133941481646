import React from 'react';
import ValidationMessage from '../ValidationMessage';
import moment from "moment";
import {employeeDateFormat} from "../../employees/EmployeesConstants";

export const DateField = ({
  title,
  definitions,
  name,
  isTime,
  disabled,
  autoFocus,
  className,
  showPresetsFrom = false,
  ...props
}) => {
  const actualTitle = title || definitions[name].title;
  const inputClassName = props.errors[name] && props.touched[name] && 'is-invalid';
  const error = <ValidationMessage>{props.errors[name]}</ValidationMessage>;

  const format = 'mm/dd/yyyy';
  const match = new RegExp(
    format
      .replace(/(\w+)\W(\w+)\W(\w+)/, '^\\s*($1)\\W*($2)?\\W*($3)?([0-9]*).*')
      .replace(/m|d|y/g, '\\d')
  );
  const replace = '$1/$2/$3$4'.replace(/\//g, format.match(/\W/));

  const applyFormat = (value) => {
    return value
      .replace(/(^|\W)(?=\d\W)/g, '$10')
      .replace(match, replace)
      .replace(/(\W)+/g, '$1');
  };

  const handleChange = (event) => {
    if (event.target.value.length > props.values[name].length) {
      event.target.value = applyFormat(event.target.value);
    }
    props.handleChange(event);
  };

  const timeFormat = 'hh:mm';
  const timeMatch = new RegExp(
    timeFormat.replace(/(\w+)\W(\w+)/, '^\\s*($1)\\W*($2)').replace(/h|m/g, '\\d')
  );
  const timeReplace = '$1:$2'.replace(/\//g, timeFormat.match(/\W/));

  const applyTimeFormat = (value) => {
    return value
      .replace(/(^|\W)(?=\d\W)/g, '$10')
      .replace(timeMatch, timeReplace)
      .replace(/(\W)+/g, '$1');
  };

  const handleTimeChange = (event) => {
    if (event.target.value.length > props.values[name + '_time'].length) {
      event.target.value = applyTimeFormat(event.target.value);
    }
    props.handleChange(event);
  };

  const onPresetClick = (event) => {
    const base = typeof showPresetsFrom === 'string'
      ? moment(showPresetsFrom, employeeDateFormat)
      : moment();

    const value = base.add(event.target.value, 'days').format(employeeDateFormat);
    props.setFieldValue(name, value);
  }

  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={name}>{actualTitle}</label>
      <div className="input-group">
        <input
          autoFocus={autoFocus}
          name={name}
          id={name}
          disabled={disabled}
          type="text"
          placeholder="dd/mm/jjjj"
          onChange={handleChange}
          onFocus={(event) => event.target.select()}
          onBlur={props.handleBlur}
          value={props.values[name] || ''}
          className={'form-control ' + inputClassName}
        />
        {isTime && (
          <input
            name={name + '_time'}
            type="text"
            disabled={disabled}
            placeholder="uu:mm"
            onChange={handleTimeChange}
            value={props.values[name + '_time'] || ''}
            className={'form-control border-left-0 ' + inputClassName}
          />
        )}
        {showPresetsFrom && <div className="input-group-append">
          <button type="button" value="1" onClick={onPresetClick} className="btn btn-outline-secondary">+1d</button>
          <button type="button" value="7" onClick={onPresetClick} className="btn btn-outline-secondary">+1w</button>
        </div>}
      </div>
      {props.touched[name] && props.errors[name] && error}
    </div>
  );
};
