import React, { Component } from 'react';
import {
  employeeStateOptions,
  employeeStateIndicatorClassName,
  employeeStateReasonOptions,
  employeeStateColorName
} from '../EmployeesState';
import { employeeDateTimeFormat } from '../EmployeesState';
import moment from 'moment';
import EmployeesDefinitions from '../form/EmployeesDefinitions';
import EmployeesTransitionDelete from './EmployeesTransitionDelete';

function parseDate(date) {
  return moment(date, employeeDateTimeFormat);
}

export default class EmployeesStateHistoryViewItem extends Component {
  renderComment() {
    return (
      this.props.transition.event_comment && (
        <div className="state-transition-info-comment">{this.props.transition.event_comment}</div>
      )
    );
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  renderSubtitle() {
    const eventDate =
      this.props.transition.event_date &&
      this.capitalizeFirstLetter(
        parseDate(this.props.transition.event_date).calendar(null, { sameElse: 'LLL' })
      );

    const subtitle = [employeeStateReasonOptions[this.props.transition.event_reason], eventDate]
      .filter((e) => e)
      .join(' - ');

    return <div>{subtitle}</div>;
  }

  render() {
    const title = employeeStateOptions[this.props.transition.new_state];

    let iconClassName = 'state-transition-icon ';
    iconClassName += employeeStateIndicatorClassName[this.props.transition.new_state];
    iconClassName += ' text-' + employeeStateColorName[this.props.transition.new_state];

    const name =
      this.props.transition.manager &&
      ' door ' + EmployeesDefinitions.surname.value(this.props.transition.manager);
    const footer =
      this.capitalizeFirstLetter(this.props.transition.parsed_creation_date.fromNow()) +
      (name || '');

    return (
      <li className="list-group-item p-3">
        <i className={iconClassName} />
        <div className="state-transition-info">
          <div>
            <b>{title}</b>
          </div>
          {this.renderSubtitle()}
          {this.renderComment()}
          <div className="state-transition-info-footer">
            <small className="text-muted">{footer}</small>
            <EmployeesTransitionDelete
              onStateChange={this.props.onStateChange}
              transition={this.props.transition}
            />
          </div>
        </div>
      </li>
    );
  }
}
