import React from 'react';
import { connect } from 'react-redux';
import RestrictPath from '../../shared/user/RestrictPath';
import { fetchAndDownloadDump } from '../export/DonatorsExport';

const DonatorsDumpLink = ({ results, filters }) => {
  const hasResults = results && results.length > 0;
  const className = hasResults ? '' : 'disabled';

  const onClick = (event) => {
    event.preventDefault();
    fetchAndDownloadDump(filters).catch(error => {
      console.error(error);
      alert(error);
    });
  };

  return (
    <RestrictPath path={'dump-donations'}>
      <a href="#dump" onClick={onClick} className={'dropdown-item ' + className}>
        <i className="fa fa-table mr-1" /> Dumpen
      </a>
    </RestrictPath>
  );
}

const mapStateToProps = (state) => ({
  filters: state.donations.filters,
  results: state.donations.results
});

export default connect(mapStateToProps, null)(DonatorsDumpLink);
