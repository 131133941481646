import { Validations } from '../../shared/forms';
import AutoCorrect from '../../shared/forms/AutoCorrect';
import { surname_prefix, sex } from '../../shared/CommonDefinitions';
import EmployeesDefinitions, { Options } from '../../employees/form/EmployeesDefinitions';

export default {
  firstname: {
    title: 'Voornaam',
    validation: Validations.name,
    autocorrect: AutoCorrect.firstUppercase,
    initial: ''
  },
  surname_prefix,
  surname: {
    title: 'Achternaam',
    validation: Validations.name,
    autocorrect: AutoCorrect.firstUppercase,
    initial: ''
  },
  sex,
  birth_date: EmployeesDefinitions.birth_date,
  hq: {
    ...EmployeesDefinitions.hq,
    options: {
      '': 'Kies een vestiging...',
      ...Options.hq.active
    },
    initial: '',
    validation: Validations.hq.required()
  },
  mobile: {
    title: 'Mobiel telefoonnummer',
    validation: Validations.phoneSimple.required(),
    autocorrect: AutoCorrect.noSpace,
    initial: ''
  },
  email: {
    title: 'Email',
    initial: '',
    placeholder: 'naam@domein.nl',
    validation: Validations.email.required()
  },
  recruited_by_alternative: {
    title: 'Medewerker referentie',
    initial: '',
    validation: Validations.recruitedByAlternative,
  },
  comment: {
    title: 'Opmerkingen',
    initial: ''
  },
  public_transport_card: {
    title: 'Heb je een OV-abonnement?',
    initial: '',
    options: {...Options.publicTransportCards}
  },
  number_of_workdays: {
    title: 'Hoeveel dagen kun je werken?',
    initial: '',
    options: {...Options.workdays}
  }
};
