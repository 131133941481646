import React from 'react';
import SortButton from './SortButton';

export default (props) => {
  const isSortable = props.definitions[props.name].column.sortable;
  if (!isSortable) {
    return null;
  } else {
    return (
      <div className="btn-group btn-group-sm">
        <SortButton {...props} direction="asc" />
        <SortButton {...props} direction="desc" />
      </div>
    );
  }
};
