import React from 'react';
import LoadingView from '../LoadingView';

export default ({ children, colSpan, placeholder }) => {
  if (children && children.length > 0) {
    return <tbody>{children}</tbody>;
  }

  return (
    <tbody>
      <tr>
        <td colSpan={colSpan} className="py-3 text-center text-secondary table-placeholder">
          {children ? placeholder : <LoadingView className="my-0" />}
        </td>
      </tr>
    </tbody>
  );
};
