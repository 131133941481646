import React, { Component } from 'react';
import { connect } from 'react-redux';
import DonatorValidatedFormView from './form/DonatorValidatedFormView';
import { fetchSelectAction } from '../store/resources.js';
import LoadingView from '../shared/LoadingView';

class DonatorsCreatePageView extends Component {
  componentDidMount() {
    if (this.props.client === null) {
      this.props.fetchClient(this.props.match.params.clientId);
    }
  }

  getSelectedCampaign() {
    const campaignId = this.props.match.params.campaignId * 1;
    return this.props.client.campaigns.filter((campaign) => campaign.id === campaignId)[0];
  }

  form() {
    const campaign = this.getSelectedCampaign();
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Donateur toevoegen</div>
        </nav>
        <div className="container-fluid my-4">
          <div className="card card-oversized">
            <div className="card-body">
              <img
                onError={(event) => (event.target.style.display = 'none')}
                className="campaign-logo"
                alt={this.props.client.name}
                src={`${process.env.PUBLIC_URL}/${this.props.client.name}.jpg`}
              />
              <h4 className="card-title mb-4">
                {this.props.client.name} {campaign.name}
              </h4>
              <DonatorValidatedFormView campaign={campaign} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.client === null ? <LoadingView /> : this.form();
  }
}

const mapStateToProps = (state) => ({
  client: state.clients.selected
});

const mapDispatchToProps = (dispatch) => ({
  fetchClient: (id) => {
    dispatch(fetchSelectAction('clients', id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DonatorsCreatePageView);
