import React from 'react';

export default function ApplySuccess() {
  return (
    <div className="col-md-8 offset-md-2 offset-lg-3 col-lg-6">
      <div className="card mt-3">
        <div className="card-body">
          <h4 className="card-title mb-4">Uw aanvraag is verzonden</h4>
          <p>We nemen zo snel mogelijk contact met u op</p>
        </div>
      </div>
    </div>
  );
}
