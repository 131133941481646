import API from './API';
import environment from '../Environment';

export default class MessageBirdValidation {
  static cache = {};

  static ACTIVE = 'active';
  static ABSENT = 'absent';
  static FAILED = 'failed';
  static UNKNOWN = 'unknown';

  api = new API();
  hlr = environment.HLR === true;

  getUrl(phoneNumber) {
    const postfix =
      phoneNumber[0] === '0' && phoneNumber[1] !== '0'
        ? phoneNumber.replace('0', '31')
        : phoneNumber;
    const url = 'validation/messagebird/lookup/' + encodeURIComponent(postfix);
    return this.hlr ? url + '/hlr' : url;
  }

  validate(phoneNumber) {
    const response = MessageBirdValidation.cache[phoneNumber];
    if (response) {
      console.log('MessageBirdValidation ' + response + '[ cached = true ]');
      return Promise.resolve(response);
    }

    return this.fetch(phoneNumber);
  }

  fetch(phoneNumber) {
    const url = this.getUrl(phoneNumber);
    return this.api
      .fetch(url, this.hlr ? 'POST' : 'GET')
      .then((response) => {
        if (response.ok) {
          return this.hlr ? this.poll(url) : MessageBirdValidation.ACTIVE;
        } else {
          return MessageBirdValidation.FAILED;
        }
      })
      .then((status) => {
        MessageBirdValidation.cache[phoneNumber] = status;
        console.log('MessageBirdValidation ' + status);
        return status;
      });
  }

  poll(url, timeout = 1000) {
    console.log('MessageBirdValidation polling in ' + timeout + 'ms');
    return new Promise((resolve) => setTimeout(resolve, timeout)).then(() => {
      return this.api
        .fetch(url, 'GET')
        .then((response) => response.json())
        .then((response) => {
          if (response.status !== 'sent') {
            return response.status;
          } else {
            return this.poll(url, timeout * 2);
          }
        });
    });
  }

  static isAlreadyValidated(phoneNumber) {
    if (!phoneNumber || phoneNumber === '') {
      return true;
    }

    const response = MessageBirdValidation.cache[phoneNumber];
    return !response || response !== MessageBirdValidation.UNKNOWN;
  }
}
