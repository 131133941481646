import createForm from '../../shared/forms';
import EmployeesPasswordFormLayout from './EmployeesPasswordFormLayout';
import EmployeesPasswordDefinitions from './EmployeesPasswordDefinitions';
import { createYupShape } from '../../shared/forms/Validations';

export default createForm({
  resourceName: 'overwrite_password',
  method: 'PATCH',
  form: EmployeesPasswordFormLayout,
  isInitialValid: false,
  validationSchema: createYupShape(EmployeesPasswordDefinitions),

  submitPath: (props) => `employees/${props.match.params.employeeId}/password.json`,

  mapPropsToValues: () => ({ new_password: '', confirm_password: '' }),

  submitValues: (values) => {
    const newValues = values;
    delete newValues.confirm_password;
    return newValues;
  },

  onSuccess: (bag) => {
    bag.setStatus({ type: 'success', text: 'Wachtwoord succesvol opgeslagen.' });
    setTimeout(() => {
      bag.props.onClose();
    }, 500);
  }
});
