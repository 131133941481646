import { withRouter } from 'react-router';
import { withFormik } from 'formik';
import { setLocale } from 'yup/lib/customLocale';

import API from './API';

setLocale({
  mixed: { required: 'Vereist veld' }
});

const unspecifiedError = 'Server kan het record niet opslaan, controleer het formulier.';

export default ({
  resourceName,
  submitPath,
  updatePath,
  onSuccess,
  redirectPath,
  mapPropsToValues,
  validationSchema,
  customSubmitHandler,
  submitValues,
  form,
  method,
  enableReinitialize,
  handleFailure
}) => {
  const handleSuccess = (bag, response, values) => {
    if (onSuccess) {
      onSuccess(bag, response, values);
    } else {
      bag.props.history.push('/' + redirectPath(bag.props) + '/');
    }
  };

  const handleErrors = (bag, response) => {
    response.json().then((data) => {
      
      if (data.errors) {
        let errors = {};
        for (var key in data.errors.children) {
          const fieldErrors = data.errors.children[key].errors;
          if (fieldErrors) {
            errors[key] = fieldErrors[0];
          }
        }
        bag.setErrors(errors);
        const warningText = data.errors.errors && data.errors.errors.join(',');
        bag.setStatus({ type: 'warning', text: warningText || unspecifiedError });
      }

      if (handleFailure) {
        handleFailure(bag, data);
      }
    });
  };

  const handleSubmit = (values, bag) => {
    const path = bag.props.update ? updatePath(bag.props) : submitPath(bag.props);
    const resolvedMethod = method || (bag.props.update ? 'PUT' : 'POST');
    const newValues = submitValues ? submitValues(Object.assign({}, values), bag.props) : values;

    new API()
      .submit(path, resolvedMethod, { [resourceName]: newValues })
      .then((response) => {
        if (response.ok) {
          handleSuccess(bag, response, values);
        } else {
          if (response.status === 400) {
            handleErrors(bag, response);
          } else {
            bag.setStatus({
              type: 'danger',
              text: response.statusText + ': Controleer het formulier en bijbehorende rechten.'
            });
          }
        }
        bag.setSubmitting(false);
      })
      .catch((error) => {
        console.error(error);
        bag.setStatus({ type: 'danger', text: 'Intern server probleem' });
      });
  };

  const deleteIdProperty = (mapPropsToValues) => {
    return (props) => {
      const map = Object.assign({}, mapPropsToValues(props));
      delete map.id;
      return map;
    };
  };

  return withRouter(
    withFormik({
      isInitialValid: true,
      mapPropsToValues: deleteIdProperty(mapPropsToValues),
      validationSchema,
      handleSubmit: customSubmitHandler || handleSubmit,
      enableReinitialize
    })(form)
  );
};

export const TextField = require('./TextField').TextField;
export const SelectField = require('./SelectField').default;
export const CheckField = require('./CheckField').CheckField;
export const DateField = require('./DateField').DateField;
export const RadioField = require('./RadioField').RadioField;
export const TextAreaField = require('./TextAreaField').TextAreaField;
export const IBANField = require('./IBANField').default;
export const AddressFields = require('./AddressFields').default;
export const Validations = require('./Validations').Validations;
export const FormActions = require('./FormActions').default;
export const TickField = require('./TickField').default;
export const ResourceField = require('./ResourceField').default;
export const EmailField = require('./EmailField').default;
export const PhoneNumberField = require('./PhoneNumberField').default;
export const FileField = require('./FileField').default;