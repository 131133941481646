import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';

class GridConfigurationItem extends Component {
  _render() {
    const opacity = this.props.isDragging ? 0 : 1;

    return (
      <div style={{ opacity }} className="grid-configuration-item px-3 py-1">
        <label className="custom-control custom-checkbox m-0">
          <input
            type="checkbox"
            name={this.props.name}
            checked={this.props.checked}
            onChange={(e) => this.props.toggleItem(this.props.index)}
            className="custom-control-input"
          />
          <span className="custom-control-label">{this.props.title}</span>
        </label>
      </div>
    );
  }

  render() {
    const { connectDragSource, connectDropTarget } = this.props;
    return connectDragSource(connectDropTarget(this._render()));
  }
}

const itemSource = {
  beginDrag(props) {
    return {
      id: props.name,
      index: props.index
    };
  }
};

const itemTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveItem(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  }
};

const GridConfigurationItemTarget = DropTarget('GridConfigurationItem', itemTarget, (connect) => ({
  connectDropTarget: connect.dropTarget()
}))(GridConfigurationItem);

const GridConfigurationItemSource = DragSource(
  'GridConfigurationItem',
  itemSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(GridConfigurationItemTarget);

export default GridConfigurationItemSource;
