import React, { Component } from 'react';

var openedDropdown = null;

window.onclick = (event) => {
  openedDropdown && openedDropdown.hide(event);
};

export default class Dropdown extends Component {
  state = {
    dropdown: false
  };

  componentWillUnmount() {
    openedDropdown = null;
  }

  hide(event) {
    event && event.stopPropagation();
    event && event.preventDefault();
    
    openedDropdown = null;
    this.setState((state) => ({ ...state, dropdown: false }));
  }

  toggle(event) {
    event && event.preventDefault();
    event && event.stopPropagation();

    if (this.state.dropdown) {
      return this.hide(event);
    }

    if (openedDropdown && openedDropdown !== this) {
      openedDropdown.hide();
    }

    this.setState((state) => ({ ...state, dropdown: true }));
  }

  renderContent() {
    if (this.state.dropdown) {
      openedDropdown = this;
      const className = `dropdown-menu ${this.props.right && 'dropdown-menu-right'}`;
      return (
        <div className={className} onClick={(e) => e.stopPropagation()}>
          {this.props.renderContent(this.hide.bind(this))}
        </div>
      );
    }
  }

  render() {
    const TagName = this.props.tagName || 'div';
    const className = [this.props.className, 'dropdown', this.state.dropdown ? 'show' : null]
      .filter((e) => e)
      .join(' ');
    return (
      <TagName onClick={this.toggle.bind(this)} className={className}>
        {this.props.renderButton()}
        {this.renderContent()}
      </TagName>
    );
  }
}
