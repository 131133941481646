import React, { Component } from 'react';
import { Field } from 'formik';
import definitions from './DonatorDefinitions';
import ValidationMessage from '../../shared/ValidationMessage';

export default class StateSelectField extends Component {
  render() {
    const { props, className } = this.props;
    const name = 'state';
    const inputClassName = props.errors[name] && props.touched[name] && 'is-invalid';
    const error = <ValidationMessage>{props.errors[name]}</ValidationMessage>;

    const options = props.update ? Object.keys(definitions.state.options) : ['CONCEPT', 'PENDING'];
    const children = options.map((key) => (
      <option key={key} value={key}>
        {definitions.state.options[key][1]}
      </option>
    ));

    const icon = definitions.state.options[props.values.state][0];

    return (
      <div className={`form-group ${className}`}>
        <label htmlFor={name}>Status</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className={`fa fa-${icon}`} />
            </span>
          </div>
          <Field
            name={name}
            id={name}
            value={props.values.state}
            component="select"
            className={'form-control custom-select ' + inputClassName}
          >
            {children}
          </Field>
        </div>
        {props.touched[name] && props.errors[name] && error}
      </div>
    );
  }
}
