import Yup from 'yup';

import createForm from '../../shared/forms';
import DonatorsImportLayout from './DonatorsImportLayout';
import API from '../../shared/forms/API'

export default createForm({

  form: DonatorsImportLayout,
  mapPropsToValues: () => ({
    file: null,
    columns: null,
    csv_delimiter: null,
    csv_enclosure: null,
    csv_encoding: 'utf8',
    has_title_row: 1,
    confirmed: 0
  }),
  validationSchema: Yup.object().shape({
    file: Yup.mixed().required()
  }),

  customSubmitHandler: (values, bag) => {
    new API()
      .fetch('update-donations', 'POST', getFormData(values), false)
      .then(response => {
        bag.setSubmitting(false);
        response.ok
          ? handleSuccess(bag, values, response)
          : handleErrors(bag, response);
      })
      .catch(error => bag.setStatus({ type: 'danger', text: error }));
  },
});

const getFormData = (values) => {
  const data = new FormData();

  data.append(`donation_import[file]`, values.file);
  data.append(`donation_import[action]`, values.confirmed ? 'import' : 'check');

  values.csv_encoding && data.append(`donation_import[csv_encoding]`, values.csv_encoding);
  values.csv_enclosure && data.append(`donation_import[csv_enclosure]`, values.csv_enclosure);
  values.csv_delimiter && data.append(`donation_import[csv_delimiter]`, values.csv_delimiter);
  values.has_title_row && data.append(`donation_import[has_title_row]`, values.has_title_row);
  values.columns && values.columns.split(',').forEach(column => {
    data.append(`donation_import[columns][]`, column.trim());
  });
  return data;
};

const handleSuccess = (bag, values, response) => {
  return response.json().then(results => {
    const text = values.confirmed
    ? `Het bestand "${values.file.name}" is succesvol geïmporteerd.`
    : `Voorvertoning van "${values.file.name}".`;

    bag.setStatus({ type: values.confirmed ? 'success' : 'info', text, results });
  });
};

const handleErrors = (bag, response) => {
  response.json().then(({ error }) => {
    bag.setStatus({
      type: 'danger', 
      text: error
        ? `${error.message} (${error.code}): ${error.exception && error.exception[0].message}`
        : 'Kan dit bestand niet importeren'
    });
  });
};