import React from 'react';
import { Link } from 'react-router-dom';

export default ({
  touched,
  update,
  isSubmitting,
  isValid,
  cancel,
  children,
  cancelPath
}) => {
  const isSubmitDisabled = !touched || isSubmitting;
  const submitText = children || (update ? 'Opslaan' : 'Toevoegen');
  const submitClassName = !isSubmitDisabled && isValid ? 'btn btn-success' : 'btn btn-primary';

  return (
    <div>
      <button className={submitClassName} disabled={isSubmitDisabled}>
        {submitText}
      </button>
      {cancel !== false && (
        <Link to={cancelPath || `../`} className="btn btn-secondary float-right">
          Annuleren
        </Link>
      )}
    </div>
  );
};
