import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSelectAction } from '../store/resources.js';
import LoadingView from '../shared/LoadingView';
import { EmployeesRolesForm } from './form/EmployeesForm.js';
import API from '../shared/forms/API.js';

class EmployeesUpdateRolesPageView extends Component {
  state = {};

  componentDidMount() {
    if (this.props.employee === null) {
      this.props.fetch(this.props.match.params.employeeId);
    }

    const api = new API();
    api
      .fetch('static/roles-table.html')
      .then((response) => response.text())
      .then((htmlText) => this.setState({ rolesHtml: htmlText }));
  }

  form() {
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Medewerkers</div>
        </nav>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-body">
                  <h4 className="card-title mb-4">Rechten wijzigen</h4>
                  <EmployeesRolesForm update={this.props.employee} />
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title mb-4">Overzicht rollen &amp; rechten</h4>
                  {this.state.rolesHtml && (
                    <div dangerouslySetInnerHTML={{ __html: this.state.rolesHtml }} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.employee === null ? <LoadingView /> : this.form();
  }
}

const mapStateToProps = (state) => ({
  employee: state.employees.selected
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => {
    dispatch(fetchSelectAction('employees', id));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesUpdateRolesPageView);
