import createForm from '../../shared/forms';
import ShiftsFormLayout from './ShiftsFormLayout';
import ShiftsDefinitions from './ShiftsDefinitions';
import { createYupShape } from '../../shared/forms/Validations';
import getInitialValues from '../../shared/forms/getInitialValues';

const resourceName = 'shift';

const formConfiguration = {
  resourceName,
  enableReinitialize: true,

  validationSchema: createYupShape(ShiftsDefinitions),

  submitPath: () => resourceName + 's',
  updatePath: (props) => `${resourceName}s/${props.match.params.uuid}`,

  onSuccess: (bag, response, values) => {
    const isNew = !bag.props.update;

    if (isNew) {
      response.json().then((body) => {
        bag.props.history.push('../' + body.uuid + '/');
      });
    } else {
      bag.props.onChange();
    }
  },

  mapPropsToValues: (props) => {
    const initialValues = getInitialValues(ShiftsDefinitions, props.update);

    if (props.update) {
      initialValues.campaign = (props.update.campaign && props.update.campaign.id) || '';
      initialValues.employee = (props.update.employee && props.update.employee.uuid) || '';
      initialValues.sales_coach = (props.update.sales_coach && props.update.sales_coach.uuid) || '';
    }

    delete initialValues.creation_date;
    delete initialValues.shift_creator;
    delete initialValues.checks;
    delete initialValues.last_modify_date;

    return initialValues;
  },

  submitValues: (values) => {
    delete values.duration;
    return values;
  }
};

export default createForm(Object.assign({ form: ShiftsFormLayout }, formConfiguration));
