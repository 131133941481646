import moment from "moment";
import {employeeDateFormat} from "../../../employees/EmployeesConstants";

export default function getEmployeeStateTransitionPresets(currentState) {
  const tomorrowDate = moment()
    .add(1, 'days')
    .format(employeeDateFormat);
  const tomorrowTime = '00:00';

  const noShowShortcut = {
    title: 'Niet op komen dagen',
    event_reason: 'NO_SHOW',
    open_date: tomorrowDate,
    open_date_time: tomorrowTime
  };

  const shortcuts = {
    New: [
      {
        title: 'M&G',
        new_state: 'M&G planned'
      },
      {
        title: 'Neemt niet op',
        new_state: 'New',
        event_reason: 'NO_ANSWER',
        open_date: tomorrowDate,
        open_date_time: tomorrowTime
      }
    ],
    'M&G planned': [
      {
        title: 'Training',
        new_state: 'Trial planned'
      },
      {
        new_state: 'M&G canceled',
        ...noShowShortcut
      }
    ],
    'M&G canceled': [
      {
        title: 'M&G',
        new_state: 'M&G planned'
      }
    ],
    'Trial planned': [
      {
        title: 'Actief',
        new_state: 'Active'
      },
      {
        new_state: 'Trial canceled',
        ...noShowShortcut
      }
    ],
    'Trial canceled': [
      {
        title: 'Training',
        new_state: 'Trial planned'
      }
    ],
    Active: [
      {
        title: 'Pauze',
        new_state: 'Pause'
      }
    ],
    Pause: [],
    Exit: [],
    FormerEmployee: []
  };

  return shortcuts[currentState];
}
