import React, { Fragment } from 'react';
import Dropdown from '../../shared/Dropdown';
import RestrictPath from '../../shared/user/RestrictPath';
import DonatorsDumpLink from './DonatorsDumpLink';
import DonatorsExportLink from './DonatorsExportLink';
import DonatorsImportLink from './DonatorsImportLink';
import DonatorsBackstageExportLink from './DonatorsBackstageExportLink';

export default (props) => (
  <RestrictPath path="export_donations">
    <Dropdown
      right
      className="ml-2"
      renderContent={() => (
        <Fragment>
          <DonatorsDumpLink />
          <DonatorsImportLink />
          <DonatorsExportLink />
          <DonatorsBackstageExportLink />
        </Fragment>
      )}
      renderButton={() => (
        <a className="btn btn-secondary dropdown-toggle" href="#donators">
          <i className="fa fa-share mr-1" />
        </a>
      )}
    />
  </RestrictPath>
);
