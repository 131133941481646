import React from 'react';
import ValidationMessage from '../ValidationMessage';

export const TextAreaField = ({ title, name, props, disabled, autoFocus, className, rows }) => {
  const inputClassName = props.errors[name] && props.touched[name] && 'is-invalid';
  const error = <ValidationMessage>{props.errors[name]}</ValidationMessage>;

  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={name}>{title}</label>
      <div className="input-group">
        <textarea
          rows={rows || 8}
          autoFocus={autoFocus}
          name={name}
          id={name}
          disabled={disabled}
          onFocus={(event) => event.target.select()}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values[name] || ''}
          className={'form-control ' + inputClassName}
        />
      </div>
      {props.touched[name] && props.errors[name] && error}
    </div>
  );
};
