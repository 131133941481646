import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ConnectedTableView } from '../shared/tables';
import { Link } from 'react-router-dom';
import { selectAction } from '../store/resources';

class ClientsPageView extends Component {
  render() {
    const tableConfiguration = {
      resourceName: 'clients',
      onClick: (row) => {
        this.props.selectAction('clients', row);
        this.props.history.push('/clients/' + row.id + '/');
      },
      definitions: {
        name: {
          title: 'Klantnaam',
          column: true
        },
        enabled: {
          title: 'Actief',
          column: true
        }
      }
    };

    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Klanten</div>
          <Link to="/clients/create/" className="btn btn-primary ml-auto">
            <i className="fa fa-plus mr-1" /> Klant toevoegen
          </Link>
        </nav>
        <div className="container-fluid mt-4">
          <ConnectedTableView configuration={tableConfiguration} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  selectAction: (resourceName, resource) => {
    dispatch(selectAction(resourceName, resource));
  }
});

export default connect(null, mapDispatchToProps)(ClientsPageView);
