import React from 'react';
import DonatorsImportForm from './DonatorsImportForm';

export default () => (
  <div>
    <nav className="navbar navbar-expand navbar-light bg-light">
      <div className="navbar-brand">Donateurs wijzigingen importeren</div>
    </nav>
    <div className="container-fluid my-4">
      <DonatorsImportForm />
    </div>
  </div>
);