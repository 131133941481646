import React from 'react';
import ValidationMessage from '../ValidationMessage';

export const RadioField = ({ title, name, props, className, onBlur }) => {
  const error = <ValidationMessage>{props.errors[name]}</ValidationMessage>;

  const handleBlur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    props.handleBlur(event);
  };

  return (
    <div className={`form-group ${className}`}>
      <label>{title}</label>
      <div className="input-group">
        <label className="custom-control custom-radio custom-control-inline">
          <input
            autoFocus
            name={name}
            type="radio"
            value="true"
            onChange={props.handleChange}
            onBlur={handleBlur}
            checked={props.values[name] === 'true'}
            className="custom-control-input"
          />
          <span className="custom-control-label">Ja</span>
        </label>
        <label className="custom-control custom-radio custom-control-inline">
          <input
            name={name}
            type="radio"
            value="false"
            onChange={props.handleChange}
            onBlur={handleBlur}
            checked={props.values[name] === 'false'}
            className="custom-control-input"
          />
          <span className="custom-control-label">Nee</span>
        </label>
      </div>
      {props.touched[name] && props.errors[name] && error}
    </div>
  );
};
