import React from 'react';

export default ({ isLoading, isEnabled }) => (
  <div className="input-group-btn">
    <button
      className={isEnabled ? 'btn btn-success' : 'btn btn-secondary'}
      disabled={!isEnabled}
      type="submit"
    >
      <i className={isLoading ? 'fa fa-circle-o-notch fa-spin' : 'fa fa-check'} />
    </button>
  </div>
);
