import React from 'react';
import ValidationMessage from '../ValidationMessage';

export default ({
  title,
  name,
  handleBlur,
  text = false,
  className,
  errors,
  touched,
  autoFocus,
  setFieldValue,
  children
}) => {
  const inputClassName = errors[name] && touched[name] && 'is-invalid';
  const error = <ValidationMessage>{errors[name]}</ValidationMessage>;

  const handleChange = (event) => {
    const file = event.currentTarget.files[0];
    if (text) {
      const reader = new FileReader();
      reader.onload = () => setFieldValue(name, reader.result);
      reader.readAsText(file);	
    } else {
      setFieldValue(name, file);
    }
  };

  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={name}>{title}</label>
      <div className="input-group">
        {children}
        <input
          autoFocus={autoFocus}
          name={name}
          id={name}
          type="file"
          onChange={handleChange}
          onBlur={handleBlur}
          className={'form-control ' + inputClassName}
        />
      </div>
      {touched[name] && errors[name] && error}
    </div>
  );
};