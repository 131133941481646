import React, { Component, Fragment } from 'react';
import { TextField } from '../../shared/forms/TextField';
import moment from 'moment';
import SelectField from '../../shared/forms/SelectField';

export default class DurationFields extends Component {

  constructor(props) {
    super(props);
    this.options = this.getOptions();
  }

  getOptions() {
    const definition = this.props.definitions.duration;
    const value = this.props.values.duration;
    
    const options = { ...definition.options };
    const isCustomDuration = Object.keys(options).indexOf(value) < 0;
    if (isCustomDuration) {
      options[value] = definition.value({ duration: value });
    }
    
    return options;
  }

  applyTimeFormat = (value) => {
    const timeFormat = 'hh:mm';
    const timeMatch = new RegExp(
      timeFormat.replace(/(\w+)\W(\w+)/, '^\\s*($1)\\W*($2)').replace(/h|m/g, '\\d')
    );
    const timeReplace = '$1:$2'.replace(/\//g, timeFormat.match(/\W/));
    return value
      .replace(/(^|\W)(?=\d\W)/g, '$10')
      .replace(timeMatch, timeReplace)
      .replace(/(\W)+/g, '$1');
  };

  handleStartTimeChange = (event) => {
    if (event.target.value.length > this.props.values[event.target.name].length) {
      event.target.value = this.applyTimeFormat(event.target.value);
    }
    this.updateEndTime(event.target.value, this.props.values.duration);
    this.props.handleChange(event);
  };

  handleDurationChange = (event) => {
    this.updateEndTime(this.props.values.start_time, event.target.value);
    this.props.handleChange(event);    
  };

  updateEndTime = (start_time, duration) => {
    const end = moment(start_time, 'HH:mm').add(duration, 'hours');
    const formatted = end.isValid() ? end.format('HH:mm') : '';
    this.props.setFieldValue('end_time', formatted);
  };

  render() {
    return (
      <Fragment>
        <TextField
          className="col-4 col-sm-3 col-md-4"
          name="start_time"
          definitions={this.props.definitions}
          props={{ ...this.props, handleChange: this.handleStartTimeChange }}
        />

        <SelectField 
          {...this.props} 
          className="col-4 col-sm-3 col-md-4"
          handleChange={this.handleDurationChange}
          definitions={this.props.definitions}
          options={this.options}
          name="duration" 
        />          

        <TextField
          className="col-4 col-sm-3 col-md-4"
          name="end_time"
          disabled
          definitions={this.props.definitions}
          props={this.props}
        />
      </Fragment>
    );
  }
}
