import React, { Component } from 'react';
import API from '../../../shared/forms/API';
import { withRouter } from 'react-router';
import LoadingView from '../../../shared/LoadingView';
import ThemeForm from './ThemeForm';

class ThemeView extends Component {
  state = {
    values: null
  };

  componentDidMount() {
    const { campaignId, clientId } = this.props.match.params;
    new API()
      .fetch(`clients/${clientId}/campaigns/${campaignId}/settings.json`)
      .then((response) => response.json())
      .then((settings) => this.setSettings(settings));
  }

  setSettings(settings) {
    const values = {};
    for (let setting of settings) {
      values[setting.key] = setting.value;
    }
    this.setState({ values });
  }

  render() {
    return this.state.values ? (
      <ThemeForm values={this.state.values} {...this.props} />
    ) : (
      <LoadingView />
    );
  }
}

export default withRouter(ThemeView);
