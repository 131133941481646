import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import './index.css';

import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

import store from './store';
import App from './App';

import moment from 'moment';
import _ from 'moment/locale/nl.js'; // eslint-disable-line no-unused-vars
import momentDurationFormatSetup from 'moment-duration-format';
import * as Sentry from '@sentry/browser';
import version from './version.json';

Sentry.init({
  dsn: 'https://a3528912ab294811b242e3d0b3907d80@o279754.ingest.sentry.io/1502918',
  release: `donniebase@${version}`,
  environment: window.location.hostname.replace(/\./g, '-')
});

momentDurationFormatSetup(moment);
moment.locale('nl');

ReactDOM.render(
  <GoogleOAuthProvider clientId="363183668524-e2clh230gru1s9bfk85klde7rroqdou1.apps.googleusercontent.com">
    <Provider store={store}>
      <App />
    </Provider>
  </GoogleOAuthProvider>,
  document.getElementById('root')
);
