import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const DonatorsExportLink = ({ results, filters}) => {
  const hasResults = results && results.length > 0;
  const hasCampaignFilter = filters.client !== undefined && filters.campaign !== undefined;
  const className = hasCampaignFilter && hasResults ? '' : 'disabled';

  return (
    <Link to={`/clients/${filters.client}/campaigns/${filters.campaign}/export/`} className={'dropdown-item ' + className}>
      <i className="fa fa-download mr-1" /> Bestand exporteren
    </Link>
  );
};

const mapStateToProps = (state) => ({
  filters: state.donations.filters,
  results: state.donations.results
});

export default connect(mapStateToProps, null)(DonatorsExportLink);
