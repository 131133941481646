import createForm from '../../shared/forms';
import LayoutsFormLayout from './LayoutsFormLayout';
import getInitialValues from '../../shared/forms/getInitialValues';
import LayoutsDefinitions from './LayoutsDefinitions';
import { createYupShape } from '../../shared/forms/Validations';

export default createForm({

  resourceName: 'export_layout',
  form: LayoutsFormLayout,
  validationSchema: createYupShape(LayoutsDefinitions),

  submitPath: () => 'exports/layouts',
  redirectPath: () => 'layouts',
  updatePath: (props) => `exports/${props.match.params.id}/layout`,

  mapPropsToValues: ({ update }) => {
    const values = getInitialValues(LayoutsDefinitions, update);
    if (values.campaign) { 
      values.campaign = values.campaign.id;
    }
    values.line_ending = values.line_ending || LayoutsDefinitions.line_ending.initial;
    return values;
  },

  submitValues: (values) => {
    if (values.file_type !== 'csv') {
      values.line_ending = null;
      values.field_delimiter = null;
      values.field_enclosure = null;
    }
    return values;
  },

  handleFailure: (bag, data) => {
    const issues = data.layout_messages && Object.keys(data.layout_messages)
      .map(key => `${key}: ${data.layout_messages[key]}`);
    bag.setStatus({ type: 'danger', text: data.message, issues });
  }
});