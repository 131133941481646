import React from 'react';
import { connect } from 'react-redux';
import { filterAction, clearAction } from '../store/resources';
import { withRouter } from 'react-router-dom';
import RestrictPath from '../shared/user/RestrictPath';

const QuickLink = ({ clear, filter, filters, history, resourceName, children, accessPath = null }) => {
  const onClick = (event) => {
    event.preventDefault();
    clear();
    filter(filters);
    history.push(`/${resourceName}/`);
  };
  return (<RestrictPath path={accessPath || resourceName}>
    <a href="#filter" className="btn btn-light mr-2" onClick={onClick}>{children}</a>
  </RestrictPath>);
};

export default withRouter(connect(null, (dispatch, { resourceName }) => ({
  filter: (filters) => dispatch(filterAction(resourceName, filters)),
  clear: () => dispatch(clearAction(resourceName))
}))(QuickLink));