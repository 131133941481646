import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import Yup from 'yup';
import { filterAction, fetchAction } from '../../store/resources';
import { SelectField } from '../../shared/forms';
import LoadingView from '../../shared/LoadingView';

class CampaignFilter extends Component {
  getConfiguration() {
    if (this.configuration === undefined) {
      this.configuration = {
        render: this.renderForm.bind(this),
        onSubmit: this.onSubmit.bind(this),
        validationSchema: Yup.object().shape({
          client: Yup.number().required(),
          campaign: Yup.number()
        }),
        initialValues: {
          client: this.props.values.client || this.props.clients[0].id,
          campaign: this.props.values.campaign || ''
        },
        isInitialValid: true
      };
    }
    return this.configuration;
  }

  componentDidMount() {
    if (this.props.clients === null) {
      this.props.fetch();
    }
  }

  onClear(event) {
    event.preventDefault();
    this.props.filter({
      campaign: undefined,
      client: undefined
    });
    this.props.onSubmit(event);
  }

  onSubmit(values) {
    this.props.filter({ campaign: values.campaign, client: values.client });
    this.props.onSubmit();
  }

  flattenOptions(options, initial = {}) {
    return options.reduce((result, option) => {
      result[option.id] = option.name;
      return result;
    }, initial);
  }

  campaignsByClientId(clientId) {
    const client = this.props.clients.filter((client) => client.id === clientId * 1);
    return client.length === 1 ? client[0].campaigns : [];
  }

  renderForm(formProps) {
    const definitions = {
      client: {
        title: 'Klant',
        options: this.flattenOptions(this.props.clients)
      },
      campaign: {
        title: 'Campagne',
        options: this.flattenOptions(this.campaignsByClientId(formProps.values.client), { '': '' })
      }
    };
    return (
      <Form autoComplete="off" className="form">
        <SelectField autoFocus {...formProps} name="client" definitions={definitions} />
        <SelectField {...formProps} name="campaign" definitions={definitions} />

        <button className="btn btn-primary" disabled={formProps.isSubmitting || !formProps.isValid}>
          <i className="fa fa-check" />
        </button>
        <button className="btn btn-secondary float-right" onClick={this.onClear.bind(this)}>
          <i className="fa fa-trash" />
        </button>
      </Form>
    );
  }

  render() {
    return this.props.clients === null ? <LoadingView /> : <Formik {...this.getConfiguration()} />;
  }
}

const filterCampaignlessClients = (clients) => {
  return clients === null ? null : clients.filter((client) => client.campaigns.length > 0);
};

const mapStateToProps = (state, props) => ({
  values: state.donations.filters,
  clients: filterCampaignlessClients(state.clients.results)
});

const mapDispatchToProps = (dispatch, props) => ({
  filter: (filters) => dispatch(filterAction('donations', filters)),
  fetch: () => dispatch(fetchAction('clients', 500))
});

export default connect(mapStateToProps, mapDispatchToProps)(CampaignFilter);
