import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const ApplicantsUpdateButton = ({ selected }) => {
  const uuid = selected && selected.uuid;
  const props = {
    to: `/applicants/${uuid}/update/`,
    className: 'btn btn-primary ml-2 ' + (!uuid ? 'disabled' : '')
  };

  return (
    <Link {...props}>
      <i className="fa fa-pencil mr-1" /> Sollicitant wijzigen
    </Link>
  );
};

const mapStateToProps = (state) => ({
  selected: state.applicants.selected
});

export default connect(mapStateToProps, null)(ApplicantsUpdateButton);
