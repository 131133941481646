import React, { Fragment } from 'react';
import CampaignForm from './CampaignForm';
import { Link } from 'react-router-dom';
import withCampaign from './withCampaign';

const CampaignUpdatePageView = ({ campaign, client }) => (
  <Fragment>
    <nav className="navbar navbar-expand navbar-light bg-light">
      <div className="navbar-brand">
        Campagne {client.name} {campaign.name} wijzigen
      </div>
      <Link to="../theme/" className="btn btn-primary ml-auto">
        <i className="fa fa-paint-brush mr-1" />
        Thema wijzigen
      </Link>
    </nav>
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
          <div className="card">
            <div className="card-body">
              <CampaignForm update={campaign} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
);

export default withCampaign(CampaignUpdatePageView);
