import Yup from 'yup';

export default class HippoValidation {
  static cache = {};

  static BAD = 'Bad';
  static OK = 'Ok';
  static UNVERIFIED = 'Unverifiable';

  lastReason = null;

  isEmailFormat(email) {
    return Yup.string()
      .email()
      .required()
      .isValidSync(email);
  }

  clear() {
    this.lastReason = null;
  }

  fetch(email) {
    const response = HippoValidation.cache[email];
    if (response) {
      console.log(
        'HippoValidation ' +
          response.result +
          ' [ reason = ' +
          response.reason +
          ' , cached = true ]'
      );
      return Promise.resolve(response);
    }

    const url = 'https://api1.27hub.com/api/emh/a/v2?k=C52ED3BD&e=' + encodeURIComponent(email);
    return fetch(url)
      .then((response) => response.json())
      .then((response) => {
        HippoValidation.cache[email] = response;
        this.lastReason = response.reason;
        console.log('HippoValidation ' + response.result + ' [ reason = ' + response.reason + ' ]');
        return response;
      });
  }

  validate(email) {
    this.lastReason = null;
    if (!this.isEmailFormat(email)) {
      return Promise.resolve(HippoValidation.BAD);
    }
    return this.fetch(email).then((response) => {
      this.lastReason = response.result !== HippoValidation.OK ? '(' + response.reason + ')' : null;
      return response.result;
    });
  }

  static isAlreadyValidated(email) {
    if (!email || email === '') {
      return true;
    }

    const response = HippoValidation.cache[email];
    return (response && response.result !== HippoValidation.BAD) || !response;
  }
}
