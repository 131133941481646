import Yup from 'yup';
import XRegExp from 'xregexp';
import IBAN from 'iban';
import MessageBirdValidation from './MessageBirdValidation';
import { parseNumber } from 'libphonenumber-js';

const messages = {
  default: 'Incorrect formaat',
  noAdult: 'Minimale leeftijd is 18 jaar',
  no14YearsOld: 'Leeftijd is te laag',
  tooMuchMoney: 'Maximaal 250 euro',
  notRecent: 'Maximaal 60 dagen geleden',
  hippo: 'E-mail controle mislukt',
  futureDate: 'Datum ligt in het verleden',
  nearDate: 'Datum moet binnen 21 dagen zijn',
  messageBird: 'Nummer controle mislukt',
  phoneFormat: 'Ongeldig formaat'
};

const getAge = (birthDate) => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const parseDate = (value) => {
  const components = value.split('/');
  return new Date(components[2], components[1] - 1, components[0]);
};

const tests = {
  is14YearsOld(value) {
    if (!value) {
      return true;
    }

    return getAge(parseDate(value)) >= 14;
  },
  isAdult(value) {
    if (!value) {
      return true;
    }

    return getAge(parseDate(value)) >= 18;
  },

  isNotTooMuchMoney(value) {
    if (!value) {
      return true;
    }

    value = value.replace(',', '.') * 1;
    return value <= 250;
  },

  isRecentDate(value) {
    if (!value) {
      return true;
    }

    const twoMonthsAgo = new Date().getTime() - 60 * 24 * 60 * 60 * 1000;
    const current = parseDate(value).getTime();
    return current >= twoMonthsAgo;
  },

  isFutureDate(value) {
    if (!value) {
      return true;
    }

    const timeYesterday = new Date().getTime() - 24 * 60 * 60 * 1000;
    const timeValue = parseDate(value).getTime();
    return timeValue >= timeYesterday;
  },

  isNearDate(value) {
    if (!value) {
      return true;
    }

    const timeInTwentyOneDays = new Date().getTime() + 21 * 24 * 60 * 60 * 1000;
    const timeValue = parseDate(value).getTime();
    return timeValue < timeInTwentyOneDays;
  },

  isIBAN(value) {
    if (!value) {
      return true;
    }

    return IBAN.isValid(value);
  },

  isValidPhoneNumber(value) {
    if (!value) {
      return true;
    }

    return !!parseNumber(value, { defaultCountry: 'NL' }).phone;
  }
};

const templates = {
  date: Yup.string().matches(/^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/, messages.default),
  phone: Yup.string().test('phoneLib', messages.phoneFormat, tests.isValidPhoneNumber)
};

export const Validations = {
  number: Yup.string().matches(/^[0-9]+$/, messages.default),
  referenceNumber: Yup.string().matches(/^T?[0-9]+$/, messages.default),
  employeeCode: Yup.string().matches(/^[0-9]{6}$/, messages.default),
  phone: templates.phone.test(
    'messageBird',
    messages.messageBird,
    MessageBirdValidation.isAlreadyValidated
  ),
  phoneSimple: templates.phone,
  bsn: Yup.string().matches(/^[0-9]{8,9}$/, messages.default),
  name: Yup.string()
    .trim()
    .matches(XRegExp("^[\\pL '-]+$"), messages.default),
  hq: Yup.string(),
  recruitedByAlternative: Yup.string()
    .trim()
    .matches(XRegExp("^[0-9\\pL '-]+$"), messages.default),
  nameInitials: Yup.string()
    .uppercase()
    .matches(XRegExp('^[\\pL.]+$'), messages.default),
  namePrefix: Yup.string()
    .trim()
    .matches(XRegExp("^[\\pL '-]+$"), messages.default),
  zipCode: Yup.string()
    .min(3, messages.default)
    .matches(/^([0-9a-zA-Z]+)$/, messages.default),
  addressSuffix: Yup.string().matches(/^([0-9a-zA-Z- ]+)$/, messages.default),
  addressStreet: Yup.string(),
  addressCity: Yup.string().matches(XRegExp("^[\\pL '-]*$"), messages.default),
  email: Yup.string().email(),
  currency: Yup.string()
    .matches(/^[\d,.]+$/, messages.default)
    .test('currency', messages.tooMuchMoney, tests.isNotTooMuchMoney),
  iban: Yup.string().test('iban', messages.default, tests.isIBAN),
  countryCode: Yup.string()
    .min(2, messages.default)
    .max(2, messages.default),
  date: templates.date,
  time: Yup.string()
    .trim()
    .matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, messages.default),
  dateIs14YearsOld: templates.date.test('adult', messages.no14YearsOld, tests.is14YearsOld),
  dateAdult: templates.date.test('adult', messages.noAdult, tests.isAdult),
  dateRecent: templates.date.test('recent', messages.notRecent, tests.isRecentDate),
  dateNearFuture: templates.date
    .test('future', messages.futureDate, tests.isFutureDate)
    .test('near', messages.nearDate, tests.isNearDate)
};

export const createYupShape = (definitions) => {
  return Yup.object().shape(
    Object.keys(definitions).reduce((result, field) => {
      if (definitions[field].validation) {
        result[field] = definitions[field].validation;
      }
      return result;
    }, {})
  );
};
