import React from 'react';
import EmployeesForm from './form/EmployeesForm.js';

export default () => {
  return (
    <div>
      <nav className="navbar navbar-expand navbar-light bg-light">
        <div className="navbar-brand">Medewerkers</div>
      </nav>
      <div className="container-fluid my-4">
        <div className="card card-oversized">
          <div className="card-body">
            <h4 className="card-title mb-4">Medewerker toevoegen</h4>
            <EmployeesForm />
          </div>
        </div>
      </div>
    </div>
  );
};
