import React from 'react';
import Sortable from './Sortable';

export default (props) => {
  return (
    <div className="form">
      <Sortable {...props} />
    </div>
  );
};
