import React, { Component } from 'react';
import definitions from './form/EmployeesDefinitions';
import GridPageView from '../shared/GridPageView';
import { addRequestFilterModifier } from '../store/resources';

addRequestFilterModifier('employees', (filters) => ({
  employee_status: ['Active', 'Pause', 'FormerEmployee'],
  ...filters
}));

export default class EmployeesPageView extends Component {
  render() {
    return (
      <GridPageView
        {...this.props}
        definitions={definitions}
        resourceName="employees"
        resourceIdentifier="uuid"
        multipleName="Medewerkers"
        singleName="Medewerker"
      />
    );
  }
}
