import store from '.';

const initialState = {};

const actions = {
  set: 'PREFERENCES_SET'
};

export const setPreferenceAction = (key, value) => {
  const user = store.getState().user.email;
  return { type: actions.set, user, key, value };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.set:
      return {
        ...state,
        [action.user]: {
          ...state[action.user],
          [action.key]: action.value
        }
      };
    default:
      return state;
  }
};
