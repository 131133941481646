import React, { Component } from 'react';
import ShiftDefinitions from './form/ShiftsDefinitions';
import GridPageView from '../shared/GridPageView';

export default class ShiftsPageView extends Component {
  render() {
    return (
      <GridPageView
        {...this.props}
        definitions={ShiftDefinitions}
        resourceName="shifts"
        resourceIdentifier="uuid"
        multipleName="Werkbriefjes"
        singleName="Werkbriefje"
      />
    );
  }
}
