import React, { Component } from 'react';
import API from '../shared/forms/API';
import LoadingView from '../shared/LoadingView';

export default class DonatorSignatureView extends Component {
  state = {
    url: null
  };

  componentDidMount() {
    if (this.props.signature) {
      new API()
        .fetch('upload/signature/' + this.props.signature)
        .then((response) => response.json())
        .then((url) => this.setState({ url }));
    }
  }
  
  render() {
    if (!this.props.signature) {
      return 'Geen handtekening';
    }
    if (!this.state.url) {
      return <LoadingView/>;
    }

    return <img src={this.state.url} alt="Handtekening" className="signature" />;
  }
}
