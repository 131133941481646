import createForm from '../../shared/forms';
import EmployeesFormLayout from './EmployeesFormLayout';
import EmployeesDefinitions from './EmployeesDefinitions';
import Yup from 'yup';
import EmployeesRolesFormLayout from './EmployeesRolesFormLayout';
import { validationSchema, newValidationSchema } from './EmployeeValidations';

const resourceName = 'employee';
let employeeValidationSchema = Yup.object().shape({});

export const setEmployeeValidationSchema = (props) => {
  if (props.values.employee_status === 'New') {
    employeeValidationSchema = newValidationSchema;
  } else {
    employeeValidationSchema = validationSchema;
  }
};

const formConfiguration = {
  resourceName,

  validationSchema: () => employeeValidationSchema,

  submitPath: (props) => resourceName + 's',
  updatePath: (props) => `${resourceName}s/${props.match.params.employeeId}`,

  onSuccess: (bag) => {
    bag.props.history.push(`../`);
    bag.resetForm();
  },

  mapPropsToValues: (props) => {
    const initialValues = Object.keys(EmployeesDefinitions).reduce((initialValues, key) => {
      const initialValue = EmployeesDefinitions[key].initial;
      const updateValue = props.update && props.update[key];
      if (initialValue !== undefined) {
        initialValues[key] = updateValue || initialValue;
      }
      return initialValues;
    }, {});

    if (props.update) {
      initialValues.campaign = (props.update.campaign && props.update.campaign.id) || '';
      initialValues.roles = Object.values(props.update.roles);
      initialValues.recruited_by =
        (props.update.recruited_by && props.update.recruited_by.uuid) || '';
      initialValues.manager = (props.update.manager && props.update.manager.uuid) || '';
    }

    return {
      ...initialValues,
      country: 'NL',
      mobile: initialValues.mobile.replace(/ /g, ''),
      tel2: initialValues.tel2.replace(/ /g, '')
    };
  },

  submitValues: (values) => {
    const newValues = values;
    delete newValues.country;
    return newValues;
  }
};

export default createForm(Object.assign({ form: EmployeesFormLayout }, formConfiguration));

export const EmployeesRolesForm = createForm(
  Object.assign({ form: EmployeesRolesFormLayout }, formConfiguration)
);
