import React from 'react';
import RestrictPath from '../../shared/user/RestrictPath';
import { Link } from 'react-router-dom';

export default () => (
  <RestrictPath path="update-donations">
    <Link to="/donations/import/" className="dropdown-item">
      <i className="fa fa-upload mr-1" /> Wijzigingen importeren
    </Link>
  </RestrictPath>
);