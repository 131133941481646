import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSelectAction, selectAction } from '../store/resources.js';
import LoadingView from '../shared/LoadingView';
import { Link } from 'react-router-dom';
import SettingsExampleView from './SettingsExampleView.js';

class SettingsDetailPageView extends Component {
  componentDidMount() {
    this.props.clear();
    this.props.fetch(this.getIdentifier());
  }

  getIdentifier() {
    return this.props.match.params.type + '/' + this.props.match.params.key;
  }

  form() {
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">Instellingen</div>
          <Link className="btn btn-primary ml-auto" to="update">
            <i className="fa fa-trash mr-1" /> Instelling wijzigen
          </Link>
        </nav>
        <div className="container-fluid my-4">
          <div className="card card-oversized">
            <div className="card-body">
              <h4 className="card-title mb-4">
                Voorvertoning {this.props.selected.type} / {this.props.selected.key}
              </h4>
              <div className="pre-wrap">{this.props.selected.value}</div>
              <hr />
              <SettingsExampleView selected={this.props.selected} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.selected === null ? <LoadingView /> : this.form();
  }
}

const mapStateToProps = (state) => ({
  selected: state.settings.selected
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (uuid) => dispatch(fetchSelectAction('settings', uuid)),
  clear: () => dispatch(selectAction('settings', null))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsDetailPageView);
