import React, { Component } from 'react';
import API from '../shared/forms/API';
import { withRouter } from 'react-router-dom';

class DeleteButton extends Component {
  onClick = (event) => {
    event.preventDefault();

    new API()
      .fetch(this.props.path, 'DELETE')
      .then(this.onSuccess)
      .catch(this.onError);
  };

  onSuccess = (response) => {
    if (!response.ok) {
      response.json().then((json) => alert(json));
    } else {
      this.props.history.push(this.props.returnPath);
    }
  };

  onError = (error) => {
    alert('Kan niet verwijderen');
    console.error(error);
  };

  render() {
    return (
      <a
        className={`btn btn-danger ml-2 ${this.props.className || ''}`}
        href="#delete"
        onClick={this.onClick}
      >
        <i className="fa fa-trash mr-1" /> Verwijderen
      </a>
    );
  }
}

export default withRouter(DeleteButton);
