import React from 'react';
import download from 'downloadjs';
import { fetchAndDownloadExportLayout } from '../../donators/export/DonatorsExport';

export default ({ update, value }) => {
  const onClick = (event) => {
    event.preventDefault();

    if (value) {
      download(value, 'layout.csv', 'text/plain');
    } else if (update) {
      fetchAndDownloadExportLayout(update.id).catch(error => {
        console.error(error);
        alert(error);
      });
    }
  };

  const isEnabled = update || value;
  const className = isEnabled ? 'btn btn-primary' : 'btn btn-secondary';

  return (
    <div className="input-group-btn">
      <button disabled={!isEnabled} onClick={onClick} className={className}>
        <i className="fa fa-eye" />
      </button>
    </div>
  );
};