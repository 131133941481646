import ModalView from '../../../../shared/ModalView';
import React from 'react';

const PreviewModalViewTrigger = (props) => (
  <div className="input-group-btn">
    <button {...props} href="#modal" className="btn btn-primary">
      <i className="fa fa-eye" />
    </button>
  </div>
);

export default (props) =>
  props.type === 'file' &&
  props.value !== '' && (
    <ModalView
      {...props}
      trigger={PreviewModalViewTrigger}
      body={(props) => (
        <img class="modal-body" src={'/api/upload/static/' + props.value} alt="preview" />
      )}
    />
  );
