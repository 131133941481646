import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { PublicRoutes } from './routes/public';
import { PrivateRoutes } from './routes/private';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/public/" component={PublicRoutes} />
          <Route path="/" component={PrivateRoutes} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
