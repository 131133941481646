import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { GridView } from '../shared/tables';
import GridHeaderView from './GridHeaderView';
import { selectAction } from '../store/resources';

class GridPageView extends Component {
  constructor(props) {
    super(props);

    this.tableConfiguration = {
      definitions: props.definitions,
      name: props.multipleName,
      resourceName: props.resourceName,

      onClick:
        props.onClick ||
        ((row) => {
          this.props.select(row);
          this.props.history.push(
            '/' + props.resourceName + '/' + row[props.resourceIdentifier || 'id'] + '/'
          );
        })
    };
  }

  render() {
    return (
      <Fragment>
        <GridHeaderView {...this.props} configuration={this.tableConfiguration}>
          {this.props.children}
        </GridHeaderView>
        <div className="grid-container">
          <GridView configuration={this.tableConfiguration} />
        </div>
      </Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  select: (resource) => dispatch(selectAction(props.resourceName, resource))
});

const mapStateToProps = (state, props) => ({
  filters: state[props.resourceName].filters
});

export default connect(mapStateToProps, mapDispatchToProps)(GridPageView);
