import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchAndDownloadExport } from './DonatorsExport';

class DonatorsExportView extends Component {

  state = {
    error: null,
    layout: '',
    disabled: true,
    checked: false,
    post: false
  };

  constructor(props) {
    super(props);
    const date = new Date();
    this.last_create = Math.round(date.getTime() / 1000);
    this.date = date.toLocaleString();
  }

  componentDidMount() {
    if (this.props.exportLayouts.length === 0) {
      this.setState((state) => ({
        ...state,
        error: 'Geen export layout voor de geselecteerde campagne.'
      }));
    } else {
      this.setState((state) => ({
        ...state,
        layout: this.props.exportLayouts[0].id,
        disabled: false
      }));
    }
  }

  render() {
    const buttonText = this.isPostable() ? 'Exporteren' : 'Voorvertoning';
    const buttonColor = this.isPostable() ? 'success' : 'primary';
    return (
      <div className="container-fluid my-4">
        <div className="col-sm-6 offset-sm-3">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title mb-4">{this.props.client.name} {this.props.campaign.name}</h4>
              <div className="form">
                {this.state.error && <div className="alert alert-danger">{this.state.error}</div>}
                <div className="form-group">
                  <label>Uiterste aanmaakdatum</label>
                  <input disabled className="form-control" value={this.date} />
                </div>
                <div className="form-group">
                  <label id="layout">Export layout</label>
                  <select className="form-control" id="layout" name="layout" 
                    value={this.state.layout} onChange={this.onChange.bind(this)}
                  >
                    {this.props.exportLayouts.map((layout, index) => (
                      <option key={index} value={layout.id}>{layout.name}</option>
                    ))}
                  </select>
                </div>
                <hr />
                <div className="form-group">
                  <label className="custom-control custom-checkbox">
                    <input
                      autoFocus
                      type="checkbox"
                      name="checked"
                      onChange={this.onChange.bind(this)}
                      className="custom-control-input"
                      disabled={this.state.disabled}
                      checked={this.state.checked}
                    />
                    <span className="custom-control-label">Het bestand is gecontroleerd</span>
                  </label>
                  <label className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="post"
                      disabled={this.state.disabled}
                      onChange={this.onChange.bind(this)}
                      className="custom-control-input"
                      value="on"
                      checked={this.state.post}
                    />
                    <span className="custom-control-label">
                      Markeer donateurs als geëxporteerd
                    </span>
                  </label>
                </div>
                <a
                  className={`btn btn-${buttonColor} ${this.state.disabled ? 'disabled' : ''}`}
                  href="download"
                  onClick={this.onClick.bind(this)}
                >
                  <i className="fa mr-1 fa-download" /> {buttonText}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  isPostable() {
    return this.state.checked && this.state.post;
  }

  onClick(event) {
    event.preventDefault();

    this.setState((state) => ({
      ...state,
      disabled: true
    }));

    const filters = Object.assign(this.props.filters, {
      layout: this.state.layout,
      client: this.props.client.id,
      campaign: this.props.campaign.id,
      last_create: this.last_create
    });

    delete filters.offset;
    delete filters.limit;

    fetchAndDownloadExport(filters, this.isPostable())
      .then(() => this.setState((state) => ({ ...state, disabled: this.isPostable() })))
      .catch(error => this.setState((state) => ({ ...state, error: error, disabled: false })));
  }

  onChange(event) {
    const target = event.target;
    this.setState((state) => ({
      ...state,
      [target.name]: target.type === 'checkbox' ? target.checked : target.value
    }));
  }

}

const mapStateToProps = (state) => ({
  clients: state.clients.results
});

export default connect(mapStateToProps, null)(DonatorsExportView);
