import DonatorDefitions from '../../donators/form/DonatorDefinitions';
import Resources from '../../shared/forms/Resources';
import GenericFilter from '../../shared/tables/GenericFilter';
import moment from 'moment';
import { employeeDateFormat } from '../../employees/EmployeesState';
import SortOnlyFilter from '../../shared/tables/SortOnlyFilter';
import { Validations } from '../../shared/forms/Validations';

export default {
  creation_date: {
    title: 'Invoerdatum',
    column: { filter: SortOnlyFilter, sortable: true },
    value: (row) => moment(row.creation_date, employeeDateFormat).fromNow()
  },
  reference_number: {
    title: 'Volgnr.',
    placeholder: '0000',
    validation: Validations.number.required(),
    column: true
  },
  campaign: {
    title: 'Campagne',
    column: { filter: GenericFilter },
    value: (row) => row.campaign && Resources.campaign.value(row.campaign),
    resource: Resources.campaign
  },
  iban: DonatorDefitions.iban,
  birth_date: { ...DonatorDefitions.birth_date },
  period: DonatorDefitions.period,
  amount: DonatorDefitions.amount
};
