import React, { Component } from 'react';
import { Form } from 'formik';
import FormActions from '../../shared/forms/FormActions';
import { TextField } from '../../shared/forms/TextField';
import EmployeesPasswordDefinitions from '../password/EmployeesPasswordDefinitions';

export default class EmployeesRolesFormLayout extends Component {
  render() {
    return (
      <Form className="form" autoComplete="off">
        <div className="modal-body">
          {this.props.status && (
            <div className={`alert alert-${this.props.status.type}`}>{this.props.status.text}</div>
          )}

          <TextField
            autoFocus
            autoComplete="new-password"
            name="new_password"
            definitions={EmployeesPasswordDefinitions}
            props={this.props}
            type="password"
          />
        </div>
        <div className="modal-footer">
          <FormActions cancel={false} update={true} {...this.props} />
        </div>
      </Form>
    );
  }
}
