import React, { Component } from 'react';
import GridConfigurationContainer from './GridConfigurationContainer';
import Dropdown from '../Dropdown';

export default class GridConfigurationButton extends Component {
  renderButton() {
    return (
      <a className="btn btn-secondary dropdown-toggle" href="#configuration">
        <i className="fa fa-columns mr-1" />
      </a>
    );
  }

  render() {
    return (
      <Dropdown
        right
        className="ml-2"
        renderContent={() => <GridConfigurationContainer {...this.props} />}
        renderButton={this.renderButton}
      />
    );
  }
}
