import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingView from '../../shared/LoadingView';
import { fetchSelectAction } from '../../store/resources';

class CampaignWrapper extends Component {
  componentDidMount() {
    this.props.fetchSelect('clients', this.props.match.params.clientId);
  }

  render() {
    const WrappedComponent = this.props.wrap;
    return this.props.campaign === null ? <LoadingView /> : <WrappedComponent {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  const id = props.match.params.campaignId * 1;
  const campaign =
    state.clients.selected &&
    state.clients.selected.campaigns &&
    state.clients.selected.campaigns.filter((campaign) => campaign.id === id)[0];

  return {
    client: state.clients.selected,
    campaign: campaign
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchSelect: (resourceName, id) => {
    dispatch(fetchSelectAction(resourceName, id));
  }
});

const ConnectedCampaignWrapper = connect(mapStateToProps, mapDispatchToProps)(CampaignWrapper);
const withCampaign = (wrap) => (props) => <ConnectedCampaignWrapper wrap={wrap} {...props} />;

export default withCampaign;
