import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userAuthenticateAction, userLoginFailureClearAction } from '../store/user';
import Environment from '../shared/Environment';
import { GoogleLoginButton } from './GoogleButtons';


const errorMessages = {
  idpiframe_initialization_failed:	'Kan Google login niet initieren door een API fout.',
  popup_closed_by_user: 'Popup is gesloten voordat login werd afgerond.',
  access_denied: 'Gebruiker heeft geen toestemming gegeven tot het account.',
  immediate_failed: 'Kan niet automatisch de juiste gebruiker selecteren zonder consent.'
};

class LoginPageView extends Component {
  state = {
    errorMessage: null
  };

  onSuccess = (user) => {
    this.props.userAuthenticateAction(user);
  };

  onFailure = ({ error, error_description }) => {
    this.setState({ 
      errorMessage: errorMessages[error] || error + ': ' + error_description
    });
  };

  onRequest = () => {
    this.props.userLoginFailureClearAction();
    this.setState({
      errorMessage: null
    });
  }

  renderErrorMessage(errorMessage) {
    return errorMessage && <div className="alert alert-danger mb-4" role="alert">{errorMessage}</div>;
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand navbar-light bg-light">
          <div className="navbar-brand">&nbsp;</div>
        </nav>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-lg-4 offset-lg-4">
              <div className="card">
                <div className="card-body p-5">
                  <h4 className="card-title">Authenticatie vereist</h4>
                  <p className="mb-4">Log in met uw account van {Environment.TITLE}.</p>

                  {this.renderErrorMessage(this.state.errorMessage)}
                  {this.renderErrorMessage(this.props.errorMessage)}

                  <GoogleLoginButton onSuccess={this.onSuccess} onFailure={this.onFailure} onRequest={this.onRequest} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errorMessage: state.user && state.user.errorMessage,
});
const mapDispatchToProps = (dispatch) => ({
  userAuthenticateAction: (user) => dispatch(userAuthenticateAction(user)),
  userLoginFailureClearAction: () => dispatch(userLoginFailureClearAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageView);
