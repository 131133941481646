import getEmployeeStateTransitionPresets from './getEmployeeStateTransitionPresets';

export default {
  TITLE: 'Going Abroad',
  NAVIGATION_STYLE: {
    backgroundColor: '#1abc9c'
  },
  HQS_ACTIVE: ['Onbekend', 'Breda', 'Amsterdam', 'Dordrecht', 'Eindhoven', 'Haarlem', 'Hilversum', 'Maastricht', 'Nijmegen', 'Rotterdam', 'Den Haag', 'Tilburg', 'Utrecht'],
  HQS_INACTIVE: [],
  NAVIGATION_CLASS: '',
  APPLICANT_SUMMARY_FIELDS: ['campaign', 'email', 'mobile', 'citizenship', 'resume_url', 'number_of_workdays', 'place', 'hq'],
  APPLICANT_STATES: {
    New: 'Nieuw',
    'M&G planned': 'Videogesprek gepland',
    'Trial planned': 'Sollicitatie gepland',
    'GA_TRIAL_PERIOD': 'Proefperiode', // UNIQUE
    Exit: 'Ongekwalificeerd',
    Pause: 'Wachtlijst',
  },
  EMPLOYEE_STATES: {
    Active: 'Actief',
    FormerEmployee: 'Oud-medewerker'
  },
  NOTIFICATION_LABELS: {
    'trial.confirmation': 'Sollicitatie bevestiging',
    'mg.confirmation': 'Videogesprek bevestiging',
    'mg.reminder': 'Videogesprek herinnering',
    'trial.second_reminder': 'Sollicitatie 2de herinnering',
    'trial.reminder': 'Sollicitatie herinnering',
    'apply_confirmation': 'Welkomstmail',
    no_answer: 'Geen antwoord',
    'no_answer.email': 'Geen antwoord',
    no_show: 'Niet op komen dagen',
    'no_vacancies.email': 'Geen vacatures beschikbaar',
    'invalid_phone_number.email': 'Verkeerd telefoonnummer',
    'non_eu.email': 'Non-EU afwijzing',
  },
  EMPLOYEE_STATE_TRANSITION_PRESETS: getEmployeeStateTransitionPresets,
}
