import React from 'react';
import ValidationMessage from '../ValidationMessage';

export const TextField = ({
  title,
  name,
  props,
  children,
  disabled,
  placeholder,
  type = 'text',
  autoFocus,
  reference,
  className,
  autoComplete,
  definitions = { [name]: {} }
}) => {
  const inputClassName = props.errors[name] && props.touched[name] && 'is-invalid';
  const error = <ValidationMessage>{props.errors[name]}</ValidationMessage>;
  const computedTitle = title || definitions[name].title;

  const handleBlur = (event) => {
    if (definitions[name] && definitions[name].autocorrect) {
      event.target.value = definitions[name].autocorrect(event.target.value.trim(), props);
      props.handleChange(event);
    }
    props.handleBlur(event);
  };

  return (
    <div className={`form-group ${className}`}>
      <label htmlFor={name}>{computedTitle}</label>
      <div className="input-group">
        {children}
        <input
          ref={reference}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          name={name}
          id={name}
          disabled={disabled}
          type={type}
          placeholder={placeholder || definitions[name].placeholder}
          onFocus={(event) => event.target.select()}
          onChange={props.handleChange}
          onBlur={handleBlur}
          value={props.values[name] || ''}
          className={'form-control ' + inputClassName}
        />
      </div>
      {props.touched[name] && props.errors[name] && error}
    </div>
  );
};
