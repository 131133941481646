import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSelectAction, selectAction } from '../store/resources';
import EmployeesDefinitions from './form/EmployeesDefinitions';
import DescriptionListView from '../shared/DescriptionListView';
import EmployeesStateHistoryView from './transition/EmployeesStateHistoryView';
import EmployeesNotificationsView from './transition/EmployeesNotificationsView';
import CallsView from '../calls/CallsView';
import environment from "../shared/Environment";
import {Link} from "react-router-dom";

const resourceName = 'applicants';

class ApplicantsDetailView extends Component {
  onStateChange() {
    this.props.fetch(this.props.selected.uuid);
  }

  getName() {
    const nameComponents = [
      this.props.selected.firstname,
      this.props.selected.surname_prefix,
      this.props.selected.surname
    ];
    return nameComponents.filter((e) => e).join(' ') || 'Naamloos';
  }

  onClose = (event) => {
    event.preventDefault();
    this.props.unselect();
  };

  _render() {
    if (!this.props.selected.transitions) {
      this.props.fetch(this.props.selected.uuid);
    }

    return (
      <div className="split-detail">
        <div className="scrollable p-4">
          <a href="#close" onClick={this.onClose} className="gray pull-right ml-2 mt-1">
            <i className="fa fa-close" />
          </a>
          <Link to={`/applicants/${this.props.selected.uuid}/`} className="gray pull-right ml-2 mt-1">
            <i className="fa fa-window-maximize" />
          </Link>
          <h4 className="mb-3">{this.getName()}</h4>
          <DescriptionListView
            entity={this.props.selected}
            names={environment.APPLICANT_SUMMARY_FIELDS}
            titleClassName="col-sm-5"
            descriptionClassName="col-sm-7"
            definitions={EmployeesDefinitions}
          />

          {this.props.selected.comment && (
            <DescriptionListView
              entity={this.props.selected}
              names={['comment']}
              titleClassName="col-sm-12"
              descriptionClassName="col-sm-12"
              definitions={EmployeesDefinitions}
            />
          )}

          <EmployeesStateHistoryView
            employee={this.props.selected}
            onStateChange={this.onStateChange.bind(this)}
          />

          <div className="mt-4">
            <EmployeesNotificationsView employee={this.props.selected} />
          </div>

          <div className="mt-4">
            <CallsView callee="employee" identifier={this.props.selected.uuid} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return this.props.selected && this._render();
  }
}

const mapStateToProps = (state) => ({
  selected: state[resourceName].selected
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => {
    dispatch(fetchSelectAction(resourceName, id));
  },
  unselect: () => {
    dispatch(selectAction(resourceName, null));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantsDetailView);
